import { ChangeEvent, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Search as SearchIcon } from 'react-feather'
import {
  Box,
  Typography,
  Button,
  Card,
  CardContent,
  TextField,
  Divider,
  InputAdornment,
  SvgIcon,
} from '@mui/material'
import { getRTP } from 'stores/rtp/rtp-actions'

const Toolbar = ({ paginationInfo, setPaginationInfo }) => {
  const [searchKey, setSearchKey] = useState('')
  const dispatch = useDispatch()

  const search = async (parentName: string) => {
    const data = (await dispatch(
      getRTP(parentName, paginationInfo?.currentPage, paginationInfo?.pageSize)
    )) as any
    data && setPaginationInfo({ ...data, currentPage: paginationInfo?.currentPage })
  }

  return (
    <>
      <Box id="rtp-filter-bar">
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            flexWrap: 'wrap',
            m: -1,
          }}
        >
          <Typography
            sx={{
              ml: '20px',
              mb: '6px',
              borderBottom: '1px solid #ded9d9',
              width: '100%',
              paddingBottom: '5px',
              paddingTop: '6px',
            }}
            variant="h4"
            color="secondary.main"
            fontSize={'16px'}
            fontWeight={'600'}
          >
            RTP Records
          </Typography>

          <Divider />
        </Box>

        <Box sx={{ mt: 3 }}>
          <Card
            sx={{
              p: 0,
              boxShadow: 'none',
              borderRadius: 0,
            }}
          >
            <CardContent sx={{ p: '10px 10px !important', backgroundColor: 'background.default' }}>
              <Box
                sx={{
                  display: 'flex',
                  gap: '8px',
                  mb: '14px',
                  width: '100%',
                  flexDirection: 'column',
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    marginBottom: '6px',
                    alignItems: 'center',
                    gap: '10px',
                  }}
                >
                  <Typography
                    sx={{
                      color: '#4a494a',
                      fontWeight: '600',
                      fontSize: '14px',
                      marginRight: '20px',
                    }}
                  >
                    Parent Name:
                  </Typography>
                  <TextField
                    fullWidth
                    focused
                    value={searchKey}
                    placeholder="Search Parent Name"
                    variant="outlined"
                    size="small"
                    color="info"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SvgIcon fontSize="small" color="action">
                            <SearchIcon />
                          </SvgIcon>
                        </InputAdornment>
                      ),
                    }}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => setSearchKey(e.target.value)}
                    sx={{
                      width: '30%',
                      borderRadius: '3px',
                      backgroundColor: '#fff',
                      '&  .MuiInputBase-root': {
                        borderRadius: '3px',
                      },
                      '& input': {
                        fontSize: '14px',
                      },
                    }}
                  />
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                  <Button
                    variant="contained"
                    color="secondary"
                    sx={{
                      fontWeight: '500',
                      color: 'white',
                      fontSize: '14px',
                      borderRadius: '6px',
                      backgroundColor: 'secondary.main',
                      textTransform: 'none',
                      padding: '6px 14px',
                      width: 'fit-content',
                    }}
                    onClick={() => search(searchKey)}
                  >
                    Search
                  </Button>
                </Box>
              </Box>
            </CardContent>
          </Card>
        </Box>
      </Box>
    </>
  )
}

export default Toolbar
