import React, { ChangeEvent, FormEvent, Fragment, useEffect, useState } from 'react'
import {
  Typography,
  Box,
  Stack,
  TextField,
  SelectChangeEvent,
  Container,
  MenuItem,
} from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton'
import PageTitle from 'app/dashboard/components/PageTitle'
import BackButton from './BackButton'
import { createUser } from 'stores/user/user-actions'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'stores'

// interface IUserRole {
//   superadmin: ['admin'],
//   admin: ['subadmin'],
//   subadmin: ['manager']
//   manager: ['customer']
//   customer: []
// }

// const UserRole: IUserRole = {

//   superadmin: ['admin'],
//   admin: ['subadmin'],
//   subadmin: ['manager'],
//   manager: ['customer'],
//   customer: [],
// }

interface IUserRole {
  superadmin: ['admin']
  admin: ['master']
  master: ['distributor']
  distributor: ['subdistributor']
  subdistributor: ['store']
  store: ['customer']
  customer: []
}

const UserRole: IUserRole = {
  superadmin: ['admin'],
  admin: ['master'],
  master: ['distributor'],
  distributor: ['subdistributor'],
  subdistributor: ['store'],
  store: ['customer'],
  customer: [],
}

const NewUser = (): JSX.Element => {
  const [formData, setFormData] = useState({
    account: '',
    password: '',
    confirmPassword: '',
    role: '',
  })
  const { userInfo } = useSelector((state: RootState) => state.user)
  const [isAccValid, setIsAccValid] = useState<boolean>(true)
  const [isPassValid, setIsPassValid] = useState<boolean>(true)
  const [diffConfirmPass, setDiffConfirmPass] = useState<boolean>(false)
  const [options, setOptions] = useState<string[]>()
  const { isLoading } = useSelector((state: RootState) => state.ui)
  const dispatch = useDispatch()
  useEffect(() => {
    if (userInfo) {
      setOptions(UserRole[userInfo?.role])
    }
  }, [userInfo])

  const handleChange = (
    event: SelectChangeEvent | ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    fieldName: string
  ) => {
    setFormData({ ...formData, [fieldName]: event.target.value })
  }

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault()
    const { password, confirmPassword, account, role } = formData
    const re = new RegExp(/[\W]/g)
    const inValidAcc = re.test(account) || account.length > 13
    const inValidPass = re.test(password) || re.test(confirmPassword)

    if (inValidAcc) {
      setIsAccValid(false)
      return
    }

    if (inValidPass) {
      setIsPassValid(false)
      return
    }

    if (password !== confirmPassword) {
      setDiffConfirmPass(true)
      return
    }

    dispatch(
      createUser({
        nickname: account,
        account,
        password,
        role,
      })
    )
  }

  const handleOnFocus = () => {
    setIsAccValid(true)
    setIsPassValid(true)
  }

  return (
    <Fragment>
      <PageTitle pageTitle="Create Account | Galaxy GamesGroup" />
      <Container maxWidth={false} sx={{ paddingLeft: '10px !important' }}>
        <Box
          component="form"
          sx={{
            mb: 3,
            py: 2,
            '& .MuiFormHelperText-root': { marginLeft: '0px' },
          }}
          onSubmit={handleSubmit}
        >
          <Typography
            sx={{
              borderBottom: '1px solid #ded9d9',
              width: '100%',
              paddingBottom: '10px',
              paddingTop: '6px',
              marginBottom: '20px',
              display: 'flex',
              alignItems: 'center',
            }}
            variant="h4"
            color="secondary.main"
            fontSize={'18px'}
            fontWeight={'600'}
          >
            <BackButton iconOnly href="/app/users" /> Create Account
          </Typography>

          <Stack
            spacing={2}
            sx={{
              border: '2px solid #ccc',
              borderRadius: '4px',
              padding: '8px',
              '& .MuiOutlinedInput-notchedOutline': {
                borderColor: '#7F9DB9 !important',
                borderRadius: '4px',
              },
              '& .MuiFormControl-root': {
                flexDirection: 'row',
                gap: '10px',
              },
              '&  .MuiInputBase-root': {
                borderRadius: '3px',
                borderColor: 'red',
              },
              '& input': {
                width: '156px',
              },
              '& input, .MuiSelect-select': {
                background: '#fff',
                fontSize: '12px',
                height: '22px',
                padding: '0px 5px',
              },
              '& .MuiSelect-select': {
                width: '128px',
              },
              '& .MuiFormHelperText-root': {
                fontSize: '13px',
                fontFamily: 'sans-serif',
                letterSpacing: 'normal',
                lineHeight: 'unset',
              },
              '& .info-red .MuiFormHelperText-root': {
                color: '#ff0000',
              },
              '& .info-black .MuiFormHelperText-root': {
                color: '#000',
              },
            }}
          >
            <Box
              sx={{
                display: 'flex',
                gap: '12px',
              }}
            >
              <Typography
                sx={{
                  color: '#4a494a',
                  fontWeight: '600',
                  fontSize: '12px',
                  width: '14%',
                  textAlign: 'right',
                }}
              >
                Account:
              </Typography>
              <TextField
                variant="outlined"
                size="small"
                className="info-red"
                value={formData.account}
                error={!isAccValid}
                helperText="* Use 13 or less characters with letters, underscore & numbers."
                required
                onChange={(e) => handleChange(e, 'account')}
                onFocus={handleOnFocus}
              />
            </Box>
            <Box
              sx={{
                display: 'flex',
                gap: '12px',
              }}
            >
              <Typography
                sx={{
                  color: '#4a494a',
                  fontWeight: '600',
                  fontSize: '12px',
                  width: '14%',
                  textAlign: 'right',
                }}
              >
                Nickname
              </Typography>

              <TextField
                variant="outlined"
                value={formData.account}
                size="small"
                helperText="Will be same as the account if not fill it"
                className="info-black"
                inputProps={{ readOnly: true }}
                required
              />
            </Box>
            <Box
              sx={{
                display: 'flex',
                gap: '12px',
              }}
            >
              <Typography
                sx={{
                  color: '#4a494a',
                  fontWeight: '600',
                  fontSize: '12px',
                  width: '14%',
                  textAlign: 'right',
                }}
              >
                User Role
              </Typography>

              <TextField
                id="select"
                value={formData.role}
                select
                size="small"
                SelectProps={{ multiple: false }}
                onChange={(e) => handleChange(e, 'role')}
              >
                {options?.map((item, index) => (
                  <MenuItem key={index} value={item}>
                    {item}
                  </MenuItem>
                ))}
                {!options && <MenuItem value="user-role">User Role</MenuItem>}
              </TextField>
            </Box>
            <Box
              sx={{
                display: 'flex',
                gap: '12px',
              }}
            >
              <Typography
                sx={{
                  color: '#4a494a',
                  fontWeight: '600',
                  fontSize: '12px',
                  width: '14%',
                  textAlign: 'right',
                }}
              >
                Login Password:
              </Typography>

              <TextField
                type="password"
                variant="outlined"
                value={formData.password}
                size="small"
                error={!isPassValid}
                className="info-red"
                helperText="* Use letters,underscore & numbers."
                required
                onChange={(e) => handleChange(e, 'password')}
                onFocus={handleOnFocus}
              />
            </Box>
            <Box
              sx={{
                display: 'flex',
                gap: '12px',
              }}
            >
              <Typography
                sx={{
                  color: '#4a494a',
                  fontWeight: '600',
                  fontSize: '12px',
                  width: '14%',
                  textAlign: 'right',
                  whiteSpace: 'nowrap',
                }}
              >
                Confirm Password:
              </Typography>
              <TextField
                type="password"
                variant="outlined"
                value={formData.confirmPassword}
                size="small"
                className="info-red"
                error={diffConfirmPass}
                helperText={
                  diffConfirmPass
                    ? 'Confirm password do not match'
                    : '* Please re-enter the password'
                }
                required
                onChange={(e) => handleChange(e, 'confirmPassword')}
              />
            </Box>

            <LoadingButton
              variant="contained"
              type="submit"
              loading={isLoading?.usedFor === 'newUserReg' && isLoading.status}
              color="secondary"
              sx={{
                borderRadius: '4px',
                width: 'fit-content',
                marginLeft: 'calc(14% + 12px) !important',
                fontSize: 14,
                height: 30,
                textTransform: 'capitalize',
                padding: ' 0px 6px',
                backgroundColor: '#0382b7',
              }}
            >
              Save
            </LoadingButton>
          </Stack>
        </Box>
      </Container>
    </Fragment>
  )
}

export default NewUser
