import { ChangeEvent, useState } from 'react'
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'stores'
import { Search as SearchIcon } from 'react-feather'
import {
  Box,
  Typography,
  Button,
  Card,
  CardContent,
  Checkbox,
  FormControlLabel,
  TextField,
  Divider,
  InputAdornment,
  SvgIcon,
} from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { DemoContainer } from '@mui/x-date-pickers/internals/demo'
import ScaleContainer from 'app/dashboard/components/ScaleContainer'
import { getUdid } from 'stores/udid/udid-actions'

const Toolbar = () => {
  const firstDay = moment().startOf('week').format('YYYY-MM-DD')
  const lastDay = moment().endOf('week').format('YYYY-MM-DD')

  const [searchKey, setSearchKey] = useState('')
  const [startDate, setStartDate] = useState<string>(firstDay)
  const [endDate, setEndDate] = useState<string>(lastDay)
  const [type, setType] = useState<'added' | 'notadded'>('notadded')
  const { isLoading } = useSelector((state: RootState) => state.ui)
  const { userList = {} as any } = useSelector((state: RootState) => state.user)
  const dispatch = useDispatch()

  const searchByDate = (startDate: string, endDate: string, type: string) => {
    if ((startDate && endDate) || type) {
      dispatch(getUdid(startDate, endDate, type, searchKey))
    }
  }

  return (
    <>
      <DemoContainer components={['DatePicker']}>
        <Box id="udid-filter-bar">
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              flexWrap: 'wrap',
              m: -1,
            }}
          >
            <Typography
              sx={{
                ml: '20px',
                mb: '6px',
                borderBottom: '1px solid #ded9d9',
                width: '100%',
                paddingBottom: '5px',
                paddingTop: '6px',
              }}
              variant="h4"
              color="secondary.main"
              fontSize={'16px'}
              fontWeight={'600'}
            >
              UDID Records
            </Typography>

            <Divider />
          </Box>

          <Box sx={{ mt: 3 }}>
            <Card
              sx={{
                p: 0,
                boxShadow: 'none',
                borderRadius: 0,
              }}
            >
              <CardContent
                sx={{ p: '10px 10px !important', backgroundColor: 'background.default' }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    gap: '8px',
                    mb: '14px',
                    width: '100%',
                    flexDirection: 'column',
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      marginBottom: '6px',
                      alignItems: 'center',
                      gap: '10px',
                    }}
                  >
                    <Typography
                      sx={{
                        color: '#4a494a',
                        fontWeight: '600',
                        fontSize: '14px',
                        marginRight: '20px',
                      }}
                    >
                      UDID:
                    </Typography>
                    <TextField
                      fullWidth
                      focused
                      value={searchKey}
                      placeholder="Search UDID"
                      variant="outlined"
                      size="small"
                      color="info"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <SvgIcon fontSize="small" color="action">
                              <SearchIcon />
                            </SvgIcon>
                          </InputAdornment>
                        ),
                      }}
                      onChange={(e: ChangeEvent<HTMLInputElement>) => setSearchKey(e.target.value)}
                      sx={{
                        width: '20%',
                        borderRadius: '3px',
                        backgroundColor: '#fff',
                        '&  .MuiInputBase-root': {
                          borderRadius: '3px',
                        },
                        '& input': {
                          fontSize: '14px',
                        },
                      }}
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={type === 'added'}
                          onChange={(e) => setType(e.target.checked ? 'added' : 'notadded')}
                          name="checkedB"
                          color="primary"
                        />
                      }
                      label="Added"
                    />
                  </Box>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                    <Typography
                      sx={{
                        color: '#4a494a',
                        fontWeight: '600',
                        fontSize: '14px',
                        marginRight: '20px',
                      }}
                    >
                      Period:{' '}
                    </Typography>
                    <DatePicker
                      format="YYYY-MM-DD"
                      value={moment(firstDay) || ''}
                      onChange={(value) => setStartDate(moment(value).format('YYYY-MM-DD'))}
                      sx={{
                        '& input': {
                          padding: '8.5px 14px',
                          fontSize: '14px',
                        },
                        width: '20%',

                        backgroundColor: '#fff',
                        '& .MuiInputBase-root': {
                          borderRadius: '3px',
                        },
                        '& svg': {
                          width: '16px',
                          height: '16px',
                        },
                      }}
                    />

                    <Typography
                      variant="body1"
                      sx={{
                        fontSize: '1.15rem',
                        textAlign: 'center',
                        fontWeight: 'bold',
                        color: '#4a494a',
                      }}
                    >
                      ~
                    </Typography>
                    <DatePicker
                      format="YYYY-MM-DD"
                      value={moment(lastDay) || ''}
                      onChange={(value) => setEndDate(moment(value).format('YYYY-MM-DD'))}
                      sx={{
                        '& input': {
                          padding: '8.5px 14px',
                          fontSize: '14px',
                        },
                        width: '20%',

                        backgroundColor: '#fff',
                        '& .MuiInputBase-root': {
                          borderRadius: '3px',
                        },
                        '& svg': {
                          width: '16px',
                          height: '16px',
                        },
                      }}
                    />

                    <Button
                      variant="contained"
                      color="secondary"
                      sx={{
                        fontWeight: '500',
                        color: 'white',
                        fontSize: '14px',
                        borderRadius: '6px',
                        backgroundColor: 'secondary.main',
                        textTransform: 'none',
                        padding: '6px 14px',
                        width: 'fit-content',
                      }}
                      onClick={() => searchByDate(startDate!, endDate!, type!)}
                    >
                      Search
                    </Button>
                  </Box>
                </Box>
              </CardContent>
            </Card>
          </Box>
        </Box>
      </DemoContainer>
    </>
  )
}

export default Toolbar
