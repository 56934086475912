import api from 'config/apiConfig'
import { udidActions, UdidInterface  } from './udid-slice'
import { ServerError } from 'stores/auth/auth-actions'
import { Action, Dispatch } from 'redux'
import { AxiosError } from 'axios'
import { handleErrorResponse } from 'app/dashboard/utils/errorHandler'
import { uiActions } from '../ui/ui-slice'

export const getUdid =
  (startDate: string, endDate: string, type: string = 'notadded', searchKey?: string) =>
  async (dispatch: Dispatch<Action>): Promise<void> => {
    try {
      dispatch(uiActions.setLoading({ status: true, usedFor: 'udid' }))
      const { data } = await api.get<{data: UdidInterface[], paginationInfo: any}>(`/udid?type=${type}&startDate=${startDate}&endDate=${endDate}&searchKey=${searchKey}`)
      dispatch(udidActions.setUdid(data.data));
      return data.paginationInfo;
    } catch (error) {
      if (error) {
        const serverError = error as AxiosError<ServerError>
        handleErrorResponse(serverError, dispatch)
      }
    } finally {
      dispatch(uiActions.setLoading({ status: false, usedFor: 'udid' }))
    }
  }

export const createUdid =
  (udid: string, securityCode: string, macId?: string) => async (dispatch: Dispatch<Action>): Promise<void> => {
    try {
      dispatch(uiActions.setLoading({ status: true, usedFor: 'udid' }))
      const { data } = await api.post<UdidInterface>('/udid', { udid, securityCode, macId })
      dispatch(udidActions.pushUdid(data))
      dispatch(
        uiActions.showNotification({
          status: 200,
          title: 'success',
          msg: 'Udid successfully created!',
        })
      )
    } catch (error) {
      if (error) {
        const serverError = error as AxiosError<ServerError>
        handleErrorResponse(serverError, dispatch)
      }
    } finally {
      dispatch(uiActions.setLoading({ status: false, usedFor: 'udid' }))
      setTimeout(() => {
        dispatch(uiActions.hideNotification())
      }, 3000)
    }
  }