import React from 'react'
import { Card, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material'
import { styled } from '@mui/material/styles'
import { tableCellClasses } from '@mui/material/TableCell'
import TablePagination from 'app/web/components/TablePagination'
import ScaleContainer from 'app/dashboard/components/ScaleContainer'
import JpRecordProps from './data'
import { useSelector } from 'react-redux'
import { RootState } from 'stores'

const tableCellOptions: string[] = ['Account', 'Type', 'Score', 'Manager', 'Date']

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    fontWeight: 'bold',
    fontSize: 16,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    fontWeight: 600,
  },
}))

interface IJPDetails {
  jprecords: typeof JpRecordProps
  paginationInfo: any
  handleChangeRowsPerPage: (e: any) => void
  handlePageChange: (e: any, n: number) => void
}

const JpDetails: React.FC<IJPDetails> = ({
  jprecords,
  paginationInfo,
  handlePageChange,
  handleChangeRowsPerPage,
  ...rest
}) => {
  const { userList = {} as any } = useSelector((state: RootState) => state.user)
  const { isLoading } = useSelector((state: RootState) => state.ui)

  return (
    <Card {...rest} sx={{ borderRadius: '0px', boxShadow: 'none' }}>
      <ScaleContainer
        key={`${isLoading?.status}-${isLoading?.usedFor}-${userList.length}`}
        parentId="jp-details-table-container"
        childId="jp-details-table"
        sx={{
          '& table': {
            '& th, td': {
              fontSize: 14,
            },
          },
        }}
      >
        <Table sx={{ borderRadius: 0, border: '1px solid #ccc' }} id="jp-details-table">
          <TableHead sx={{ backgroundColor: '#fff', color: 'primary.dark' }}>
            <TableRow
              sx={{
                height: '31px',
                borderBottom: '1px solid #c7c7c7 !important',
              }}
            >
              {tableCellOptions.map((item, index) => (
                <StyledTableCell
                  sx={{
                    padding: 0,
                    backgroundColor: '#3398cc !important',
                    border: '1px solid #c7c7c7',
                  }}
                  key={index}
                >
                  {item}
                </StyledTableCell>
              ))}
            </TableRow>
          </TableHead>

          <TableBody>
            {jprecords.map((jp) => (
              <TableRow
                hover
                key={jp.id}
                sx={{
                  height: 'fit-content',
                  '& td': {
                    position: 'relative',
                    padding: '6px 0px',
                    color: '#000',
                    cursor: 'pointer',
                    fontSize: '13px !important',
                    border: '1px solid #c7c7c7',
                  },
                  '& td p': {
                    color: '#000',
                  },
                  '&:nth-of-type(even)': {
                    bgcolor: 'background.default',
                  },
                  '&:nth-of-type(odd)': {
                    bgcolor: 'background.paper',
                  },
                }}
              >
                <TableCell>{jp.account}</TableCell>

                <TableCell>{jp.type}</TableCell>

                <TableCell>{jp.score}</TableCell>

                <TableCell>{jp.manager}</TableCell>

                <TableCell>{jp.date}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </ScaleContainer>

      <TablePagination
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        handlePageChange={handlePageChange}
        paginationInfo={paginationInfo}
      />
    </Card>
  )
}

export default JpDetails
