import { Box, Card, List, ListItem, ListItemText, Typography } from '@mui/material'

const MaxHoldingManager = () => {
  return (
    <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
      <Typography
        variant="h5"
        sx={{ mt: 3, mb: 2 }}
        color="secondary.main"
        fontSize={'16px'}
        fontWeight={'600'}
      >
        Top Max Holders
      </Typography>
      <Card
        elevation={3}
        sx={{
          display: 'block',
          flex: 1,
          borderRadius: '4px',
          transitionDuration: '0.3s',
        }}
      >
        <List>
          <ListItem>
            {/* <ListItemAvatar>
              <Avatar
                alt="Person"
                src=""
                sx={{
                  width: 38,
                  height: 38,
                  backgroundColor: 'primary.main',
                }}
              ></Avatar>
            </ListItemAvatar> */}
            <ListItemText
              primary="Coming Soon"
              sx={{
                '& .MuiTypography-root': {
                  color: 'text.primary',
                },
                '& .MuiTypography-body1': {
                  fontWeight: 'bold',
                },
              }}
            />
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              {/* <PaidIcon sx={{ color: 'primary.main', mr: 1 }} /> */}
              <Typography variant="body2" color="text.primary" />
            </Box>
          </ListItem>
        </List>
      </Card>
    </Box>
  )
}

export default MaxHoldingManager
