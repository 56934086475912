import api from 'config/apiConfig'
import { dashboardActions } from 'stores/dashboard/dashboard-slice'
import { ServerError } from 'stores/auth/auth-actions'
import { Action, Dispatch } from 'redux'
import { AxiosError } from 'axios'
import { handleErrorResponse } from 'app/dashboard/utils/errorHandler'
import { uiActions } from '../ui/ui-slice'

export const getDashboardOverview = (
  dateOperator: string = 'WEEK',
  intervalDifference: number = 0
) => {
  return async (dispatch: Dispatch<Action>) => {
    try {
      const response = await api.get('/dashboard/me', {
        params: {
          dateOperator,
          intervalDifference,
        },
      })
      dispatch(dashboardActions.setDashboard(response.data.currentOverviewDashboard))
    } catch (error) {
      const err = error as AxiosError<ServerError>
      handleErrorResponse(err, dispatch)
    } finally {
      dispatch(uiActions.setLoading({ status: false, usedFor: 'dashboard' }))
    }
  }
}

export const getPreviousDashboardOverview = (
  dateOperator: string = 'WEEK',
  intervalDifference: number = 0
) => {
  return async (dispatch: Dispatch<Action>) => {
    try {
      const response = await api.get('/dashboard/me', {
        params: {
          dateOperator,
          intervalDifference,
        },
      })
      dispatch(dashboardActions.setDashboard(response.data.previousOverviewDashboard))
    } catch (error) {
      const err = error as AxiosError<ServerError>
      handleErrorResponse(err, dispatch)
    } finally {
      dispatch(uiActions.setLoading({ status: false, usedFor: 'dashboard' }))
    }
  }
}

export const getPlayerList = (startDate: string, endDate: string, limit: number = 5) => {
  return async (dispatch: Dispatch<Action>) => {
    try {
      const response = await api.get('/dashboard/player-stat', {
        params: {
          startDate,
          endDate,
          limit,
        },
      })
      dispatch(dashboardActions.setPlayerList(response.data))
    } catch (error) {
      const err = error as AxiosError<ServerError>
      handleErrorResponse(err, dispatch)
    } finally {
      dispatch(uiActions.setLoading({ status: false, usedFor: 'dashboard' }))
    }
  }
}

export const getManagerList = (startDate: string, endDate: string, limit: number = 5) => {
  return async (dispatch: Dispatch<Action>) => {
    try {
      const response = await api.get('/dashboard/admins', {
        params: {
          startDate,
          endDate,
          limit,
        },
      })
      dispatch(dashboardActions.setManagerList(response.data))
    } catch (error) {
      const err = error as AxiosError<ServerError>
      handleErrorResponse(err, dispatch)
    } finally {
      dispatch(uiActions.setLoading({ status: false, usedFor: 'dashboard' }))
    }
  }
}

export const getGameList = (startDate: string, endDate: string, limit: number = 5) => {
  return async (dispatch: Dispatch<Action>) => {
    try {
      const response = await api.get('/dashboard/game-stat', {
        params: {
          startDate,
          endDate,
          limit,
        },
      })
      dispatch(dashboardActions.setGameList(response.data))
    } catch (error) {
      const err = error as AxiosError<ServerError>
      handleErrorResponse(err, dispatch)
    } finally {
      dispatch(uiActions.setLoading({ status: false, usedFor: 'dashboard' }))
    }
  }
}

export const getPlayTimeStat = (
  startDate: string,
  endDate: string,
  dateOperator: string,
  gameId: object
) => {
  return async (dispatch: Dispatch<Action>) => {
    try {
      const response = await api.get('/dashboard/play-time', {
        params: {
          startDate,
          endDate,
          dateOperator,
          gameId
        },
      })
      dispatch(dashboardActions.setPlayTimeStat(response.data))
    } catch (error) {
      const err = error as AxiosError<ServerError>
      handleErrorResponse(err, dispatch)
    } finally {
      dispatch(uiActions.setLoading({ status: false, usedFor: 'dashboard' }))
    }
  }
}

export const getRechargeRedeemStat = (
  startDate: string,
  endDate: string,
  dateOperator: string = 'DAILY'
) => {
  return async (dispatch: Dispatch<Action>) => {
    try {
      const response = await api.get('/dashboard/recharge-redeem-stat', {
        params: {
          startDate,
          endDate,
          dateOperator,
        },
      })
      dispatch(dashboardActions.setRechargeRedeemStat(response.data))
    } catch (error) {
      const err = error as AxiosError<ServerError>
      handleErrorResponse(err, dispatch)
    } finally {
      dispatch(uiActions.setLoading({ status: false, usedFor: 'dashboard' }))
    }
  }
}
