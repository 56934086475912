import { configureStore } from '@reduxjs/toolkit'
import authReducer from './auth/auth-slice'
import uiReducer from './ui/ui-slice'
import userReducer from './user/user-slice'
import gameCategoryReducer from './game/game-category-slice'
import transactionReducer from './transaction/transaction-slice'
import gameReducer from './game/game-slice'
import distributorReducer from './distribution/distributor-slice'
import rtpReducer from './rtp/rtp-slice'
import contactReducer from './contact/contact-slice'
import jpReducer from './jp/jp-slice'
import gameRecordReducer from './game/game-record-slice'
import announcementReducer from './announcement/announcement-slice'
import dashboardReducer from './dashboard/dashboard-slice'
import udidReducer from './udid/udid-slice'
import versionReducer from './version/version-slice'
import burntReportReducer from './burntreport/burnt-report-slice'
import notificationReducer from './notification/notification-slice'
import issueReducer from './issues/issues-slice'

// //createSlice
// //powerful way to create reducer function

//merging multiple reducers made easier
const store = configureStore({
  reducer: {
    auth: authReducer,
    ui: uiReducer,
    user: userReducer,
    transaction: transactionReducer,
    category: gameCategoryReducer,
    gameList: gameReducer,
    distributor: distributorReducer,
    contact: contactReducer,
    jp: jpReducer,
    gameRecord: gameRecordReducer,
    announcement: announcementReducer,
    dashboard: dashboardReducer,
    udid: udidReducer,
    version: versionReducer,
    burntReport: burntReportReducer,
    notification: notificationReducer,
    rtp: rtpReducer,
    issue: issueReducer,
  },
})

export type RootState = ReturnType<typeof store.getState>

export default store
