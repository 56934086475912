import { Action, Dispatch } from 'redux'
import { AxiosError } from 'axios'
import api from 'config/apiConfig'
import { handleErrorResponse } from 'app/dashboard/utils/errorHandler'
import { uiActions } from '../ui/ui-slice'
import { burntReportActions, BurntReportInterface } from './burnt-report-slice'
import { ServerError } from 'stores/auth/auth-actions'

export const getBurntReportOverview = (gameId?: string, startDate?: string, endDate?: string) => {
  return async (dispatch: Dispatch<Action>) => {
    try {
      const response = await api.get<any>('/burnt-report/overview', {
        params: {
          gameId,
          startDate,
          endDate
        },
      })
      dispatch(burntReportActions.setBurntReportOverview(response.data))
    } catch (error) {
      const err = error as AxiosError<ServerError>
      handleErrorResponse(err, dispatch)
    } finally {
      dispatch(uiActions.setLoading({ status: false, usedFor: 'burntReport' }))
    }
  }
}

export const getBurntReportDetails =
  (pageNumber: number, rowsPerPage: number = 10, startDate?: string, endDate?: string) =>
  async (dispatch: Dispatch<Action>): Promise<void> => {
    try {
      dispatch(uiActions.setLoading({ status: true, usedFor: 'burntReport' }))
      const { data } = await api.get<{
        data: BurntReportInterface[]
        paginationInfo: any
      }>('/burnt-report/games', {
        params: {
          pageNumber,
          size: rowsPerPage,
          startDate,
          endDate,
        },
      })
      dispatch(burntReportActions.setBurntReportDetails(data.data))
      return data?.paginationInfo
    } catch (error) {
      if (error) {
        const serverError = error as AxiosError<ServerError>
        handleErrorResponse(serverError, dispatch)
      }
    } finally {
      dispatch(uiActions.setLoading({ status: false, usedFor: 'burntReport' }))
    }
  }
