import { useState, useEffect } from 'react'
import moment from 'moment'
import { Box, Container, Typography } from '@mui/material'
import { useSelector, useDispatch } from 'react-redux'
import Result from './Results'
import Toolbox from './Toolbar'
import { RootState } from 'stores'
import PageTitle from 'app/dashboard/components/PageTitle'
import ScaleContainer from 'app/dashboard/components/ScaleContainer'
import ExportBtn from 'app/dashboard/components/ExportBtn'
import { getUdid } from 'stores/udid/udid-actions'

const Index: React.FC = () => {
  const { udid } = useSelector((state: RootState) => state.udid)
  const { isLoading } = useSelector((state: RootState) => state.ui)
  const { userList = {} as any } = useSelector((state: RootState) => state.user)
  const dispatch = useDispatch()
  const [paginationInfo, setPaginationInfo] = useState({
    currentPage: 1,
    lastPage: false,
    pageSize: 10,
    totalPage: 0,
    totalRecord: 0,
  })

  const firstDay = moment().startOf('week').format('YYYY-MM-DD')
  const lastDay = moment().endOf('week').format('YYYY-MM-DD')

  const handlePageChange = (_event: any, newPage: number) => {
    setPaginationInfo((value) => ({
      ...value,
      currentPage: newPage,
    }))
  }

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPaginationInfo((value) => ({
      ...value,
      pageSize: +event.target.value,
    }))
  }

  useEffect(() => {
    const fetch = async () => {
      const data = (await dispatch(getUdid(firstDay, lastDay, 'notadded', ''))) as any
      data && setPaginationInfo(data)
    }

    fetch()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <PageTitle pageTitle="Manage UDID | Galaxy GamesGroup" />
      <Container maxWidth={false} sx={{ padding: '0px !important' }}>
        {/* <ScaleContainer
          key={`${isLoading?.status}--${isLoading?.usedFor}-${userList.length}`}
          parentId="manage-category-page-container"
          childId="manage-category-page-content"
        > */}
        <Box
          sx={{
            backgroundColor: 'background.default',
          }}
          id="manage-category-page-content"
        >
          <Box sx={{ border: '2px solid #ccc', borderRadius: '4px', padding: '10px' }}>
            <Toolbox />
          </Box>
          <Box
            sx={{
              border: '2px solid #ccc',
              marginTop: '10px',
              borderRadius: '4px',
              padding: '10px',
            }}
          >
            <Typography
              sx={{
                ml: '20px',
                mb: '6px',
                borderBottom: '1px solid #ded9d9',
                paddingBottom: '5px',
                paddingTop: '6px',
                justifyContent: 'space-between',
                display: 'flex',
                paddingRight: '10px',
              }}
              variant="h4"
              color="secondary.main"
              fontSize={'16px'}
              fontWeight={'600'}
            >
              UDID List
              <ExportBtn icon data={udid} title="Store-UUID" />
            </Typography>

            <Result
              udids={udid}
              paginationInfo={paginationInfo}
              handlePageChange={handlePageChange}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
            />

            <br />
          </Box>
        </Box>
        {/* </ScaleContainer> */}
      </Container>
    </>
  )
}

export default Index
