import { ReactNode, ReactElement } from 'react'
import { Box, Card, Avatar, Typography, CardContent } from '@mui/material'

export type CardStatsProps = {
  title: string
  stats: any
  icon: ReactNode
  subtitle?: string
  color?: string
  trendNumber?: ReactElement
  highlight?: boolean
}

const CardStats = ({
  title,
  stats,
  icon,
  subtitle,
  color,
  trendNumber,
  highlight,
}: CardStatsProps) => {
  return (
    <Card
      sx={{
        border: highlight ? '1px solid' : 'null',
        borderColor: highlight ? 'grey.800' : 'null',
        borderRadius: highlight ? 4 : '4px',
      }}
      elevation={highlight ? 3 : 1}
    >
      <CardContent>
        <Box
          sx={{
            display: 'flex',
            marginBottom: 2,
            alignItems: 'flex-start',
            justifyContent: 'space-between',
          }}
        >
          <Avatar
            sx={{
              boxShadow: 3,
              marginRight: 4,
              color: 'white',
              backgroundColor: `${color}.main`,
              width: highlight ? 60 : 40,
              height: highlight ? 60 : 40,
            }}
          >
            {icon}
          </Avatar>
        </Box>
        <Typography
          sx={{ fontWeight: 600, letterSpacing: '0.75px', fontSize: '15px' }}
          variant={highlight ? 'h4' : 'h5'}
        >
          {title}
        </Typography>
        <Box sx={{ marginTop: 0.3, display: 'flex', flexWrap: 'wrap', alignItems: 'flex-start' }}>
          {stats < 0 ? (
            <Typography
              variant="h4"
              sx={{ color: 'error.main', fontWeight: 'bold', fontSize: '14px' }}
            >
              {stats}
            </Typography>
          ) : (
            <Typography
              variant="h4"
              sx={{ color: 'success.main', fontWeight: 'bold', fontSize: '14px' }}
            >
              {stats}
            </Typography>
          )}
          <Typography
            component="sup"
            variant="caption"
            sx={{
              fontWeight: 600,
              ml: 2,
              pb: 0.5,
            }}
          >
            {trendNumber}
          </Typography>
        </Box>
        <Typography variant="subtitle1">{subtitle}</Typography>
      </CardContent>
    </Card>
  )
}

export default CardStats

CardStats.defaultProps = {
  color: 'primary',
}
