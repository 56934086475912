import { uiActions } from 'stores/ui/ui-slice'
import { Action, Dispatch } from 'redux'
import { AxiosError } from 'axios'
import { ServerError } from 'stores/auth/auth-actions'

export const handleErrorResponse = (
  errorRes: AxiosError<ServerError>,
  dispatch: Dispatch<Action>
): void => {
  if (errorRes && errorRes.response) {
    dispatch(
      uiActions.showNotification({
        status: errorRes.response.status,
        msg: errorRes.response.data.error,
        title: 'error',
      })
    )
  } else {
    dispatch(
      uiActions.showNotification({
        status: 502,
        msg: errorRes.message,
        title: 'error',
      })
    )
  }
}

// export default handleErrorResponse
