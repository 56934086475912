import api from 'config/apiConfig'
import { gameCategoryActions, CategoryInterface } from 'stores/game/game-category-slice'
import { ServerError } from 'stores/auth/auth-actions'
import { Action, Dispatch } from 'redux'
import { AxiosError } from 'axios'
import { handleErrorResponse } from 'app/dashboard/utils/errorHandler'
import { uiActions } from '../ui/ui-slice'

export const getCategory =
  (page: number, rowsPerPage?: number) =>
  async (dispatch: Dispatch<Action>): Promise<void> => {
    try {
      dispatch(uiActions.setLoading({ status: true, usedFor: 'game' }))
      const { data } = await api.get<{data: CategoryInterface[], paginationInfo: any}>(`/categories?page=${page}&size=${rowsPerPage}`)
      dispatch(gameCategoryActions.setCategory(data.data));
      return data.paginationInfo;
    } catch (error) {
      if (error) {
        const serverError = error as AxiosError<ServerError>
        handleErrorResponse(serverError, dispatch)
      }
    } finally {
      dispatch(uiActions.setLoading({ status: false, usedFor: 'game' }))
    }
  }

export const createCategory =
  (name: string) =>
  async (dispatch: Dispatch<Action>): Promise<void> => {
    try {
      dispatch(uiActions.setLoading({ status: true, usedFor: 'game' }))
      const { data } = await api.post<CategoryInterface>('/create/category', { name })
      dispatch(gameCategoryActions.pushCategory(data))
      dispatch(
        uiActions.showNotification({
          status: 200,
          title: 'success',
          msg: 'Category successfully created!',
        })
      )
    } catch (error) {
      if (error) {
        const serverError = error as AxiosError<ServerError>
        handleErrorResponse(serverError, dispatch)
      }
    } finally {
      dispatch(uiActions.setLoading({ status: false, usedFor: 'game' }))
      setTimeout(() => {
        dispatch(uiActions.hideNotification())
      }, 3000)
    }
  }

export const removeCategory =
  (categoryId: string) =>
  async (dispatch: Dispatch<Action>): Promise<void> => {
    try {
      await api.delete(`/delete/category/${categoryId}`)
      dispatch(gameCategoryActions.removeCategory(categoryId))
    } catch (error) {
      if (error) {
        const serverError = error as AxiosError<ServerError>
        handleErrorResponse(serverError, dispatch)
      }
    }
  }

export const updateCategory =
  (catInfo: { name: string; catId: string }) =>
  async (dispatch: Dispatch<Action>): Promise<void> => {
    try {
      dispatch(uiActions.setLoading({ status: true, usedFor: 'game' }))
      const { data } = await api.put<{ updateCategory: CategoryInterface }>(
        `/update/category/${catInfo.catId}`,
        { name: catInfo.name }
      )
      dispatch(gameCategoryActions.updateCategory(data.updateCategory))
      dispatch(
        uiActions.showNotification({
          status: 200,
          title: 'success',
          msg: 'Category successfully updated!',
        })
      )
    } catch (error) {
      if (error) {
        const serverError = error as AxiosError<ServerError>
        handleErrorResponse(serverError, dispatch)
      }
    } finally {
      dispatch(uiActions.setLoading({ status: false, usedFor: 'game' }))
      setTimeout(() => {
        dispatch(uiActions.hideNotification())
      }, 3000)
    }
  }
