import React, { Fragment, useEffect } from 'react'
import moment from 'moment'
import { Box, Container, Typography } from '@mui/material'
import PageTitle from 'app/dashboard/components/PageTitle'
// import Toolbar from './Toolbar'
import GameDetails from './GameDetails'
import ExportBtn from 'app/dashboard/components/ExportBtn'
import { getGameRecord, getRecordByKey } from 'stores/game/game-record-actions'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'stores'
import ScaleContainer from 'app/dashboard/components/ScaleContainer'
import FilterComponent from './FilterComponent'
import { useLocation } from 'react-router-dom'

interface IndexProps {
  customerId: any
}

const headers = [
  { label: 'id', key: 'id' },
  { label: 'Account', key: 'userNickName' },
  { label: 'Game Title', key: 'gameName' },
  { label: 'Played', key: 'playedCountTotal' },
  { label: 'Before Balance', key: 'beforeBalance' },
  { label: 'After Balance', key: 'currentBalance' },
  { label: 'Manager', key: 'manager' },
  { label: 'Duration', key: 'duration' },
  { label: 'Last Played', key: 'endAt' },
  { label: 'Total Win', key: 'winsCountTotal' },
  { label: 'Total Profit', key: 'profitTotal' },
]

export const GameDetailsForCustomer: React.FC<IndexProps> = ({ customerId }) => {
  const { recordList } = useSelector((state: RootState) => state.gameRecord)
  const dispatch = useDispatch()

  useEffect(() => {
    const fetch = async () => {
      dispatch(getRecordByKey(customerId, 1, 10))
    }

    fetch()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Fragment>
      <Container sx={{ minHeight: '85vh' }} maxWidth={false}>
        <Typography variant="h4" sx={{ mt: 1, mb: 3 }} color="textPrimary">
          Game Details
          <ExportBtn data={recordList} title="Game-Records" headers={headers} />
        </Typography>

        <GameDetails games={recordList} />
      </Container>
    </Fragment>
  )
}

const Index: React.FC = () => {
  const { recordList } = useSelector((state: RootState) => state.gameRecord)
  const { isLoading } = useSelector((state: RootState) => state.ui)
  const { userList = {} as any } = useSelector((state: RootState) => state.user)
  const today = moment().format('YYYY-MM-DD')
  const { state } = useLocation()
  const { id } = state || {}

  const [paginationInfo, setPaginationInfo] = React.useState({
    currentPage: 1,
    lastPage: false,
    pageSize: 10,
    totalPage: 0,
    totalRecord: 0,
  })

  const [accountName, setAccountName] = React.useState('')
  const [manager, setManager] = React.useState('')
  const [startDate, setStartDate] = React.useState(today)
  const [endDate, setEndDate] = React.useState(today)
  const [totalProfit, setTotalProfit] = React.useState('')
  const [gameIdOption, setGameIdOption] = React.useState({}) as any
  const [totalTimeInSeconds, setTotalTimeInSeconds] = React.useState('')
  const [searchText, setSearchText] = React.useState('')
  const dispatch = useDispatch()

  const handlePageChange = (_event: any, newPage: number) => {
    setPaginationInfo((value) => ({
      ...value,
      currentPage: newPage,
    }))
  }

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPaginationInfo((value) => ({
      ...value,
      pageSize: +event.target.value,
    }))
  }

  const handleSearch = (searchKey: string) => {
    setSearchText(searchKey)
    setPaginationInfo((value) => ({
      ...value,
      currentPage: 1,
    }))
  }

  const handleReset = () => {
    setAccountName('')
    setManager('')
    setStartDate(today)
    setEndDate(today)
    setTotalProfit('')
    setTotalTimeInSeconds('')
    setGameIdOption({})
    setSearchText('')
    setPaginationInfo((value) => ({
      ...value,
      currentPage: 1,
    }))
  }

  useEffect(() => {
    const fetch = async () => {
      const data = (await dispatch(
        getGameRecord(
          paginationInfo.currentPage,
          paginationInfo.pageSize,
          id || accountName,
          manager,
          totalProfit,
          totalTimeInSeconds,
          startDate,
          endDate,
          gameIdOption?.value || ''
        )
      )) as any
      data && setPaginationInfo(data)
    }

    fetch()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paginationInfo?.currentPage, paginationInfo?.pageSize, searchText])

  return (
    <Fragment>
      <PageTitle pageTitle="Game Records | Galaxy GamesGroup" />

      <Container sx={{ minHeight: '85vh', padding: '0px !important' }} maxWidth={false}>
        <ScaleContainer
          key={`${isLoading?.status}--${isLoading?.usedFor}-${userList.length}`}
          parentId="game-records-page-container"
          childId="game-records-page-content"
        >
          <Box id="game-records-page-content">
            <Box
              sx={{
                backgroundColor: 'background.default',
                border: '2px solid #ccc',
                borderRadius: '4px',
                padding: '12px',
              }}
            >
              {/* <Typography
                sx={{
                  ml: '10px',
                  pr: '10px',
                  mb: '10px',
                  borderBottom: '1px solid #ded9d9',
                  width: '100%',
                  paddingBottom: '5px',
                  paddingTop: '6px',
                }}
                variant="h4"
                color="secondary.main"
                fontSize={'16px'}
                fontWeight={'600'}
              >
                Game Records
              </Typography> */}

              <FilterComponent
                handlers={{
                  setAccountName,
                  setManager,
                  setStartDate,
                  setEndDate,
                  setTotalProfit,
                  setTotalTimeInSeconds,
                  setGameIdOption,
                  handleSearch,
                  handleReset,
                }}
                values={{
                  accountName,
                  startDate,
                  endDate,
                  totalProfit,
                  totalTimeInSeconds,
                  manager,
                  gameIdOption,
                }}
              />
            </Box>

            <Box
              sx={{
                backgroundColor: 'background.default',
                border: '2px solid #ccc',
                borderRadius: '4px',
                padding: '12px',
                marginTop: '14px',
              }}
            >
              <Typography
                sx={{
                  ml: '10px',
                  pr: '10px',
                  mb: '16px',
                  borderBottom: '1px solid #ded9d9',
                  width: '100%',
                  paddingBottom: '5px',
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
                variant="h4"
                color="secondary.main"
                fontSize={'16px'}
                fontWeight={'600'}
              >
                Game Details
                <ExportBtn icon data={recordList} title="Game-Records" headers={headers} />
              </Typography>

              <GameDetails
                games={recordList}
                handlePageChange={handlePageChange}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
                paginationInfo={paginationInfo}
              />

              <br />
            </Box>
          </Box>
        </ScaleContainer>
      </Container>
    </Fragment>
  )
}

export default Index
