import api from 'config/apiConfig'
import { contactMsgActions, ContactInterface } from 'stores/contact/contact-slice'
import { ServerError } from 'stores/auth/auth-actions'
import { Action, Dispatch } from 'redux'
import { AxiosError } from 'axios'
import { handleErrorResponse } from 'app/dashboard/utils/errorHandler'
import { uiActions } from '../ui/ui-slice'

export const getContactMessages =
  (pageNumber: number, rowsPerPage?: number) =>
  async (dispatch: Dispatch<Action>): Promise<void> => {
    try {
      dispatch(uiActions.setLoading({ status: true, usedFor: 'contact' }))
      const { data } = await api.get<{data: ContactInterface[], paginationInfo: any}>('/contact-messages', {
        params: {
          page: pageNumber,
          size: rowsPerPage
        },
      })
      dispatch(contactMsgActions.setContact(data.data));
      return data.paginationInfo;
    } catch (error) {
      if (error) {
        const serverError = error as AxiosError<ServerError>
        handleErrorResponse(serverError, dispatch)
      }
    } finally {
      dispatch(uiActions.setLoading({ status: false, usedFor: 'contact' }))
    }
  }

export const createContact =
  (contactInfo: ContactInterface) =>
  async (dispatch: Dispatch<Action>): Promise<void> => {
    try {
      dispatch(uiActions.setLoading({ status: true, usedFor: 'contact' }))
      await api.post<ContactInterface>('/create/contact-message', contactInfo)
      dispatch(uiActions.redirect({redirectTo: '/thankyou'}))
      dispatch(
        uiActions.showNotification({
          status: 200,
          title: 'success',
          msg: 'Message Sent!',
        })
      )
    } catch (error) {
      if (error) {
        const serverError = error as AxiosError<ServerError>
        handleErrorResponse(serverError, dispatch)
      }
    } finally {
      dispatch(uiActions.setLoading({ status: false, usedFor: 'contact' }))
      dispatch(uiActions.redirect({ redirectTo: null}))
      setTimeout(() => {
        dispatch(uiActions.hideNotification())
      }, 3000)
    }
  }

export const removeContact =
  (contactId: string) =>
  async (dispatch: Dispatch<Action>): Promise<void> => {
    try {
      await api.delete(`/delete/contact-message/${contactId}`)
      dispatch(contactMsgActions.removeCategory(contactId))
    } catch (error) {
      if (error) {
        const serverError = error as AxiosError<ServerError>
        handleErrorResponse(serverError, dispatch)
      }
    }
  }
