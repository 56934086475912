import { useEffect, useState } from 'react'
import {
  Box,
  Card,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import api from 'config/apiConfig'
import { styled } from '@mui/material/styles'
import { tableCellClasses } from '@mui/material/TableCell'
import moment from 'moment'
import TablePagination from 'app/web/components/TablePagination'
import ScaleContainer from 'app/dashboard/components/ScaleContainer'
import ModalContainer from 'app/dashboard/components/ModalContainer'

const tableCellOptions: string[] = [
  'S.N.',
  'Status',
  'Win/Lose',
  'Amount Won',
  'Bet Amount',
  'Before Balance',
  'Current Balance',
  'Recorded at',
]

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    fontWeight: 'bold',
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    fontWeight: 600,
  },
}))

interface IGameDetails {
  isOpen: boolean
  onClose: () => void
  details: any
}

const GameDetailsModal: React.FC<IGameDetails> = ({ isOpen, onClose, details, ...rest }) => {
  const [hasRender, setHasRender] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [gameDetails, setGameDetails] = useState([])
  const [paginationInfo, setPaginationInfo] = useState({
    currentPage: 1,
    lastPage: false,
    pageSize: 10,
    totalPage: 0,
    totalRecord: 0,
  })
  console.log(rest)

  const fetchDetails = async () => {
    setIsLoading(true)
    const data = await api.get<any>(`/reports/game-records/detail/${details?.id}`, {
      params: {
        pageNumber: paginationInfo.currentPage,
        rowsPerPage: paginationInfo.pageSize,
        gameId: details?.id,
      },
    })
    setGameDetails(data?.data?.data)
    setPaginationInfo(data?.data?.paginationInfo)
    setIsLoading(false)
  }

  useEffect(() => {
    fetchDetails()
  }, [details?.id, paginationInfo.pageSize, paginationInfo.currentPage])

  const handlePageChange = (_event: any, newPage: number) => {
    setPaginationInfo((value) => ({
      ...value,
      currentPage: newPage,
    }))
  }

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPaginationInfo((value) => ({
      ...value,
      pageSize: +event.target.value,
    }))
  }

  // const headers = [
  //   { label: 'id', key: 'id' },
  //   { label: 'Status', key: 'status' },
  //   { label: 'Win/Lose', key: 'playStatus' },
  //   { label: 'Before Balance', key: 'beforeBalance' },
  //   { label: 'Current Balance', key: 'currentBalance' },
  // ]

  return (
    <ModalContainer modalOpen={isOpen} handleModalClose={onClose}>
      <Card
        {...rest}
        sx={{
          borderRadius: '0px',
          boxShadow: 'none',
          padding: '0px',
          marginTop: '20px',
          overflow: 'auto',
          background: '#f5f5f5',
        }}
      >
        <ScaleContainer
          key={`game-details-modal`}
          forceRefresh={hasRender}
          parentId="game-summary-container"
          childId="game-summary"
          sx={{
            '& table': {
              '& th, td': {
                fontSize: 14,
              },
            },
          }}
        >
          <Box id="game-summary" sx={{ minWidth: 800 }}>
            {/* <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '10px' }}>
              <ExportBtn icon data={gameDetails} title="Game-Records" headers={headers} />
            </Box> */}
            <Box
              sx={{
                display: 'flex',
                gap: '10px',
                alignItems: 'center',
                marginBottom: '14px',
                flexWrap: 'wrap',
                '& span': {
                  color: '#4a494a',
                },
              }}
            >
              <Typography
                sx={{
                  color: '#2074b4',
                  fontWeight: '600',
                  fontSize: '13px',
                }}
              >
                Game Name: <span>{details?.gameName}</span>
              </Typography>
              <Typography
                sx={{
                  color: '#2074b4',
                  fontWeight: '600',
                  fontSize: '13px',
                }}
              >
                Account: <span>{details?.userNickName}</span>
              </Typography>
              <Typography
                sx={{
                  color: '#2074b4',
                  fontWeight: '600',
                  fontSize: '13px',
                }}
              >
                Session ID: <span>{details?.sessionID}</span>
              </Typography>
            </Box>
            <Table sx={{ borderRadius: 0, border: '1px solid #ccc' }}>
              <TableHead>
                <TableRow
                  sx={{
                    height: '40px',
                    borderBottom: '1px solid #c7c7c7 !important',
                    '& th': {
                      padding: '4px 4px',
                    },
                  }}
                >
                  {tableCellOptions.map((item, index) => (
                    <StyledTableCell
                      sx={{
                        padding: 0,
                        backgroundColor: '#3398cc !important',
                        border: '1px solid #c7c7c7',
                      }}
                      key={index}
                    >
                      {item}
                    </StyledTableCell>
                  ))}
                </TableRow>
              </TableHead>

              {isLoading ? (
                <TableBody>
                  <TableRow
                    sx={{
                      height: 'fit-content',
                      '& td': {
                        position: 'relative',
                        padding: '6px 0px',
                        fontSize: '13px !important',
                        color: '#000',
                        cursor: 'pointer',
                        border: '1px solid #c7c7c7',
                      },
                      '& td p': {
                        color: '#000',
                      },
                      '&:nth-of-type(even)': {
                        bgcolor: 'background.default',
                      },
                      '&:nth-of-type(odd)': {
                        bgcolor: 'background.paper',
                      },
                    }}
                  >
                    {tableCellOptions.map((item) => (
                      <TableCell key={item}>
                        {Array.from(new Array(5)).map((_, index) => (
                          <Skeleton key={index} height={72} animation="wave" />
                        ))}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableBody>
              ) : (
                <TableBody>
                  {gameDetails.map((game: any, index: number) => (
                    <Row
                      data={{ ...game, sn: index + 1 }}
                      key={game.id}
                      toggleForceRefresh={() => setHasRender(!hasRender)}
                    />
                  ))}
                </TableBody>
              )}
            </Table>
            <TablePagination
              handleChangeRowsPerPage={handleChangeRowsPerPage}
              handlePageChange={handlePageChange}
              paginationInfo={paginationInfo}
            />
          </Box>
        </ScaleContainer>
      </Card>
    </ModalContainer>
  )
}

const Row: React.FC<{ data: any; toggleForceRefresh: () => void }> = ({ data }) => {
  const { beforeBalance, sn, playStatus, status, currentBalance, createdAt, amountWon, amountBet } =
    data

  return (
    <>
      <TableRow
        hover
        sx={{
          background: '#fff',
          height: 'fit-content',
          '& td': {
            height: 'fit-content',
            border: '1px solid #c7c7c7',
            fontSize: '13px !important',
            padding: '6px 6px',
          },
        }}
      >
        <TableCell> {sn}</TableCell>

        <TableCell>{status}</TableCell>
        <TableCell
          sx={{
            color: playStatus === 'WIN' ? 'green' : playStatus === 'LOSE' ? 'red' : 'black',
            fontWeight: 600,
          }}
        >
          {playStatus}
        </TableCell>
        <TableCell>{parseFloat(amountWon).toFixed(2)}</TableCell>
        <TableCell>{parseFloat(amountBet).toFixed(2)}</TableCell>

        <TableCell>{parseFloat(beforeBalance).toFixed(2)}</TableCell>

        <TableCell>{parseFloat(currentBalance).toFixed(2)}</TableCell>

        <TableCell>{moment.utc(createdAt).local().format('HH:mm a')}</TableCell>
      </TableRow>
    </>
  )
}

export default GameDetailsModal
