import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface ITransactionDetailsState {
  _id: string
  customer: {
    customerId: string
    account: string
  }
  cashier: string
  recharge: number
  redeem: number
  remark: string
  before: number
  after: number
  createdAt: Date
  updatedAt: Date
  __v?: string
  totalRechargeCount: number
  totalRedeemedCount: number
}

export interface IisLoading {
  status: boolean
  usedFor:
    | 'transaction'
}

export interface ITransactionSummaryState {
  searchId(searchId: any): string | undefined
  customerAccount: string
  customerId: string
  totalRecharged: number
  totalRedeemed: number
}

export interface IReport {
  _id: null
  totalRecharged: number
  totalRedeemed: number
  totalIncome: number
  holding: number
  account: string
  startDate: Date
  endDate: Date
  role: string
}

interface IDateRange {
  startDate: string
  endDate: string
}

export interface ITransactionDetails {
  results: ITransactionDetailsState[]
  paginationInfo?: any
  handleChangeRowsPerPage: (e: any) => void
  handlePageChange: (e: any, n: number) => void
}

export interface ITransactionSummary {
  results: ITransactionSummaryState[]
  handleRowClick: (searchId: string) => void
  paginationInfo?: any
  handleChangeRowsPerPage: (e: any) => void
  handlePageChange: (e: any, n: number) => void
}

interface IState {
  transactionDetailData: ITransactionDetailsState[] | []
  transactionSummaryData: ITransactionSummaryState[] | []
  isLoading?: IisLoading
  reportData: IReport | null
  selectedCustomerId: string
  startDate?: string
  endDate?: string
  resetPagination: boolean
}

const initialState: IState = {
  transactionDetailData: [],
  transactionSummaryData: [],
  reportData: null,
  selectedCustomerId: '',
  resetPagination: false,
}

const transactionSlice = createSlice({
  name: 'transaction',
  initialState,
  reducers: {
    setTransactionSummary(state, action: PayloadAction<ITransactionSummaryState[]>) {
      state.transactionSummaryData = action.payload
    },
    setTransactionDetails(state, action: PayloadAction<ITransactionDetailsState[]>) {
      state.transactionDetailData = action.payload
    },
    setReport(state, action: PayloadAction<IReport>) {
      state.reportData = action.payload
      state.transactionDetailData = []
      state.transactionSummaryData = []
    },
    setDateRange(state, action: PayloadAction<IDateRange>) {
      state.startDate = action.payload.startDate
      state.endDate = action.payload.endDate
    },
    setSelectedCustomerId(state, action: PayloadAction<string>) {
      state.selectedCustomerId = action.payload
    },
    setResetPagination(state, action: PayloadAction<boolean>) {
      state.resetPagination = action.payload
    },
    setLoading(state, action: PayloadAction<IisLoading>) {
      state.isLoading = {
        status: action.payload.status,
        usedFor: action.payload.usedFor,
      }
    },
  },
})

export const transactionActions = transactionSlice.actions
export default transactionSlice.reducer
