import moment from 'moment'

export const previousWeekSunday = moment().subtract(1, 'weeks').startOf('week').format('YYYY-MM-DD')

export const previousWeekSaturday = moment().subtract(1, 'weeks').endOf('week').format('YYYY-MM-DD')

export const thisWeekSunday = moment().startOf('week').format('YYYY-MM-DD')

export const thisWeekSaturday = moment().endOf('week').format('YYYY-MM-DD')

export const today = moment().format('YYYY-MM-DD')

export const yesterday = moment().subtract(1, 'days').format('YYYY-MM-DD')
