import React, { Fragment } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from 'stores'
import { Box, Button, Typography } from '@mui/material'
import { Link } from 'react-router-dom'
import Searchbar from 'app/dashboard/components/Searchbar'
import { UserRoleEnum } from 'stores/auth/auth-slice'
import ExportBtn from 'app/dashboard/components/ExportBtn'

interface ToolbarProps {
  handleSubmit: (searchText: string) => void
  handleReset: () => void
}

const Toolbar: React.FC<ToolbarProps> = (props) => {
  const { userList = {} as any, userInfo } = useSelector((state: RootState) => state.user)
  // const { isLoading } = useSelector((state: RootState) => state.ui)

  return (
    <Fragment>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        {/* <ScaleContainer
          key={`${isLoading?.status}-${isLoading?.usedFor}-${userList?.length}`}
          parentId="user-toolbar-container"
          childId="user-toolbar-content"
          
        > */}
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography
            sx={{
              ml: '20px',
              mb: '6px',
              borderBottom: '1px solid #ded9d9',
              width: '100%',
              paddingBottom: '5px',
              paddingTop: '6px',
            }}
            variant="h4"
            color="secondary.main"
            fontSize={'16px'}
            fontWeight={'600'}
          >
            User Management
          </Typography>
        </Box>

        {/* </ScaleContainer> */}

        <Box
          sx={{
            mb: 1,
            display: 'flex',
            pr: 2,
            '@media (max-width:945px)': {
              flexDirection: 'column',
            },
          }}
        >
          <Searchbar handleReset={props.handleReset} handleSubmit={props.handleSubmit} />
          <Box
            sx={{
              display: 'flex',
              gap: '10px',
              alignItems: 'center',
              '@media(max-width: 945px)': {
                marginLeft: '13px',
                flexWrap: 'wrap',
              },
            }}
          >
            <Link to="/app/create/account">
              <Button
                variant="contained"
                sx={{
                  height: '100%',
                  fontWeight: '500',
                  color: 'white',
                  fontSize: '13px',
                  backgroundColor: '#722fee',
                  textTransform: 'none',
                  padding: '6px 12px',
                  borderRadius: '4px',
                }}
              >
                Create Account
              </Button>
            </Link>

            {[UserRoleEnum.SUPERADMIN].includes(userInfo?.role as UserRoleEnum) && (
              <Link to="/app/announcement">
                <Button
                  variant="contained"
                  sx={{
                    height: '100%',
                    fontWeight: '500',
                    textTransform: 'none',

                    backgroundColor: 'secondary.main',
                    padding: '6px 12px',
                    borderRadius: '4px',
                  }}
                >
                  Announcement
                </Button>
              </Link>
            )}
            <ExportBtn icon data={userList as any} title="user-manager" />
          </Box>
        </Box>
      </Box>
    </Fragment>
  )
}

export default Toolbar
