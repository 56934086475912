import React from 'react'
import { Button, Card, CardContent, TextField, SvgIcon, InputAdornment, Box } from '@mui/material'

import { Search as SearchIcon } from 'react-feather'
import { LoadingButton } from '@mui/lab'

const ManageSearchBar = ({ searchLabel }: any) => {
  return (
    <React.Fragment>
      <Card
        sx={{ p: 0, boxShadow: 'none', borderRadius: 0, ml: 1.5, flex: 1 }}
        id="search-bar-content"
      >
        <CardContent sx={{ p: '10px 0px !important', backgroundColor: 'background.default' }}>
          <Box
            sx={{
              display: 'flex',
              gap: '12px',
              maxWidth: { xs: '70%', md: '75%' },
            }}
          >
            <TextField
              placeholder={searchLabel}
              variant="outlined"
              size="small"
              // InputProps={{
              //   startAdornment: (
              //     <InputAdornment position="start">
              //       <SvgIcon fontSize="small" color="action">
              //         <SearchIcon />
              //       </SvgIcon>
              //     </InputAdornment>
              //   ),
              // }}
              sx={{
                width: '30%',
                borderRadius: '3px',
                backgroundColor: '#fff',
                '&  .MuiInputBase-root': {
                  borderRadius: '3px',
                },
                '& input': {
                  fontSize: '14px',
                },
              }}
              fullWidth
              focused
              required
            />

            <LoadingButton
              variant="contained"
              type="submit"
              // loading={isLoading?.status && isLoading?.usedFor === 'global'}
              sx={{
                fontWeight: '500',
                color: 'white',
                fontSize: '14px',
                borderRadius: '4px',
                backgroundColor: 'secondary.main',
                textTransform: 'none',
                padding: '6px 12px',
                height: 34,
              }}
            >
              Search
            </LoadingButton>
          </Box>
        </CardContent>
      </Card>
    </React.Fragment>
  )
}

export default ManageSearchBar
