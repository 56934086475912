import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface INotificationStore {
  id: string
  message: string
  extra?: string
  showButton?: boolean
  status?: boolean
  userId?: string
  managerId?: string
  customerId?: string
  seen: boolean
  createdAt: string
}

export interface INotiticationDetails {
  notification: INotificationStore[]
  paginationInfo?: any
  handleChangeRowsPerPage: (e: any) => void
  handlePageChange: (e: any, n: number) => void
}

export interface IState {
  notification: INotificationStore[]
  notificationCount: number
}

const initialState: IState = {
  notification: [],
  notificationCount: 0,
};

const notificationSlice = createSlice({
  name: "notification",
  initialState: initialState,
  reducers: {
    showNotifications(state, action: PayloadAction<INotificationStore[]>) {
      state.notification = action.payload;
    },
    patchNotification(state, action: PayloadAction<INotificationStore>) {
      const updatedNotification = action.payload;
      const existingNotificationIndex = state.notification.findIndex((n) => n.id === updatedNotification.id);
      if (existingNotificationIndex !== -1) {
        state.notification[existingNotificationIndex] = updatedNotification;
        state.notificationCount--;
      }
    },
    removeNotification(state, action: PayloadAction<string>) {
      const notificationId = action.payload;
      state.notification = state.notification.filter((n) => n.id !== notificationId);
      state.notificationCount--;
    },
    setNotificationCount(state, action: PayloadAction<number>) {
      state.notificationCount = action.payload;
    },
    removeAllNotifications(state) {
      state.notification = [];
      state.notificationCount = 0;
    },
  },
});

export const notificationActions = notificationSlice.actions;
export default notificationSlice.reducer;