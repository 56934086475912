import api from 'config/apiConfig'
import { uiActions } from 'stores/ui/ui-slice'
import { ServerError } from 'stores/auth/auth-actions'
import { Action, Dispatch } from 'redux'
import { AxiosError } from 'axios'
import { handleErrorResponse } from 'app/dashboard/utils/errorHandler'
import { IIssueState, issueActions } from './issues-slice'

interface IIssues {
  title: string
  description: string
}

interface IReply {
  message: string
}

export const resolveIssue =
  (issueId: number) =>
  async (dispatch: Dispatch<Action>): Promise<void> => {
    try {
      const { data } = await api.patch(`/issue-tickets/resolve/${issueId}`)
      dispatch(issueActions.updateIssue(data))
      dispatch(
        uiActions.showNotification({
          status: 200,
          title: 'success',
          msg: 'Issue Resolved successfully!',
        })
      )
    } catch (error) {
      if (error) {
        const serverError = error as AxiosError<ServerError>
        handleErrorResponse(serverError, dispatch)
      }
    }
  }

export const getMyIssues =
  (pageNumber: number, rowsPerPage: number = 10) =>
  async (dispatch: Dispatch<Action>): Promise<void> => {
    try {
      dispatch(uiActions.setLoading({ status: true, usedFor: 'issues' }))
      const { data } = await api.get<{ data: IIssueState[]; paginationInfo: any }>(
        `/issue-tickets/me?pageNumber=${pageNumber}&size=${rowsPerPage}`
      )
      dispatch(issueActions.setIssuesList(data.data))
      return data?.paginationInfo
    } catch (error) {
      if (error) {
        const serverError = error as AxiosError<ServerError>
        handleErrorResponse(serverError, dispatch)
      }
    } finally {
      dispatch(uiActions.setLoading({ status: false, usedFor: 'issues' }))
    }
  }

export const getAllIssues =
  (pageNumber: number, rowsPerPage: number = 10, raisedBy: string = '') =>
  async (dispatch: Dispatch<Action>): Promise<void> => {
    try {
      dispatch(uiActions.setLoading({ status: true, usedFor: 'issues' }))
      const { data } = await api.get<{ data: IIssueState[]; paginationInfo: any }>(
        `/issue-tickets?pageNumber=${pageNumber}&size=${rowsPerPage}&raisedBy=${raisedBy}`
      )
      dispatch(issueActions.setIssuesList(data.data))
      return data?.paginationInfo
    } catch (error) {
      if (error) {
        const serverError = error as AxiosError<ServerError>
        handleErrorResponse(serverError, dispatch)
      }
    } finally {
      dispatch(uiActions.setLoading({ status: false, usedFor: 'issues' }))
    }
  }

export const replyToIssue =
  (id: string, replyData: IReply) =>
  async (dispatch: Dispatch<Action>): Promise<void> => {
    try {
      const { data } = await api.put(`/issue-tickets/${id}/comment`, replyData)
      dispatch(
        uiActions.showNotification({
          status: 200,
          title: 'success',
          msg: 'Replied to issue successfully!',
        })
      )

      dispatch(issueActions.updateIssue(data))
    } catch (error) {
      if (error) {
        const serverError = error as AxiosError<ServerError>
        handleErrorResponse(serverError, dispatch)
      }
    } finally {
      setTimeout(() => {
        dispatch(uiActions.hideNotification())
      }, 6000)
    }
  }

export const createIssue =
  (issueData: IIssues) =>
  async (dispatch: Dispatch<Action>): Promise<void> => {
    try {
      dispatch(uiActions.setLoading({ status: true, usedFor: 'issues' }))
      const { data } = await api.post('/issue-tickets', issueData)
      dispatch(
        uiActions.showNotification({
          status: 200,
          title: 'success',
          msg: 'Report sent successfully!',
        })
      )

      dispatch(issueActions.updateIssuesList(data))
    } catch (error) {
      if (error) {
        const serverError = error as AxiosError<ServerError>
        handleErrorResponse(serverError, dispatch)
      }
    } finally {
      dispatch(uiActions.setLoading({ status: false, usedFor: 'issues' }))
      setTimeout(() => {
        dispatch(uiActions.hideNotification())
      }, 6000)
    }
  }
