import api from 'config/apiConfig'
import { rtpAction, RTPInterface } from './rtp-slice'
import { ServerError } from 'stores/auth/auth-actions'
import { Action, Dispatch } from 'redux'
import { AxiosError } from 'axios'
import { handleErrorResponse } from 'app/dashboard/utils/errorHandler'
import { uiActions } from 'stores/ui/ui-slice'

export const getRTP =
  (parentName: string, page: number, rowsPerPage?: number) =>
  async (dispatch: Dispatch<Action>): Promise<void> => {
    try {
      dispatch(uiActions.setLoading({ status: true, usedFor: 'rtp' }))
      const { data } = await api.get<{ data: RTPInterface[]; paginationInfo: any }>(
        `/reports/rtp?parentName=${parentName}&page=${page}&size=${rowsPerPage}`
      )
      dispatch(rtpAction.setRTP(data.data))
      return data.paginationInfo
    } catch (error) {
      if (error) {
        const serverError = error as AxiosError<ServerError>
        handleErrorResponse(serverError, dispatch)
      }
    } finally {
      dispatch(uiActions.setLoading({ status: false, usedFor: 'rtp' }))
    }
  }

// export const createDistributor =
//   (ditributorData: RTPInterface) =>
//   async (dispatch: Dispatch<Action>): Promise<void> => {
//     try {
//       dispatch(uiActions.setLoading({ status: true, usedFor: 'distributor' }))
//       const { data } = await api.post<RTPInterface>('/create/distributor', ditributorData)

//       dispatch(distributorAction.updateDistributorList(data))
//       dispatch(
//         uiActions.showNotification({
//           status: 200,
//           title: 'success',
//           msg: 'Distributor Request Sent!',
//         })
//       )
//     } catch (error) {
//       if (error) {
//         const serverError = error as AxiosError<ServerError>
//         handleErrorResponse(serverError, dispatch)
//       }
//     } finally {
//       dispatch(uiActions.setLoading({ status: false, usedFor: 'distributor' }))
//       setTimeout(() => {
//         dispatch(uiActions.hideNotification())
//       }, 3000)
//     }
//   }

export const getDistributors =
  (pageNumber: number, rowsPerPage?: number) =>
  async (dispatch: Dispatch<Action>): Promise<void> => {
    try {
      dispatch(uiActions.setLoading({ status: true, usedFor: 'rtp' }))
      const { data } = await api.get<{ data: RTPInterface[]; paginationInfo: any }>('/rtp', {
        params: {
          page: pageNumber,
          size: rowsPerPage,
        },
      })
      dispatch(rtpAction.setRTP(data.data))
      return data?.paginationInfo
    } catch (error) {
      if (error) {
        const serverError = error as AxiosError<ServerError>
        handleErrorResponse(serverError, dispatch)
      }
    } finally {
      dispatch(uiActions.setLoading({ status: false, usedFor: 'rtp' }))
    }
  }

// export const removeDistributor =
//   (distributorId: string) =>
//   async (dispatch: Dispatch<Action>): Promise<void> => {
//     try {
//       await api.delete(`/delete/distributor/${distributorId}`)
//       dispatch(distributorAction.removeDistributor(distributorId))
//     } catch (error) {
//       if (error) {
//         const serverError = error as AxiosError<ServerError>
//         handleErrorResponse(serverError, dispatch)
//       }
//     }
//   }
