import { Action, Dispatch } from 'redux'
import { AxiosError } from 'axios'
import api from 'config/apiConfig'
import { handleErrorResponse } from 'app/dashboard/utils/errorHandler'
import { uiActions } from '../ui/ui-slice'
import { notificationActions, INotificationStore } from './notification-slice'
import { ServerError } from 'stores/auth/auth-actions'

export const getAllNotifications = (pageNumber: number, rowsPerPage: number = 10) => {
  return async (dispatch: Dispatch<Action>): Promise<void> => {
    try {
      dispatch(uiActions.setLoading({ status: true, usedFor: 'notification' }))
      const { data } = await api.get<{ data: INotificationStore[]; paginationInfo: any }>(
        '/notifications/unseen',
        {
          params: {
            pageNumber,
            size: rowsPerPage,
          },
        }
      )
      dispatch(notificationActions.showNotifications(data.data))
      dispatch(notificationActions.setNotificationCount(data.paginationInfo.totalRecord))
      return data?.paginationInfo
    } catch (error) {
      if (error) {
        const serverError = error as AxiosError<ServerError>
        handleErrorResponse(serverError, dispatch)
      }
    } finally {
      dispatch(uiActions.setLoading({ status: false, usedFor: 'notification' }))
    }
  }
}

export const clearNotification = (id: string) => {
  return async (dispatch: Dispatch<Action>): Promise<void> => {
    try {
      dispatch(uiActions.setLoading({ status: true, usedFor: 'notification' }))
      const { data } = await api.patch<{ data: INotificationStore }>(`/notifications/mark/${id}`)
      dispatch(notificationActions.patchNotification(data.data))
      dispatch(notificationActions.removeNotification(id))
    } catch (error) {
      if (error) {
        const serverError = error as AxiosError<ServerError>
        handleErrorResponse(serverError, dispatch)
      }
    } finally {
      dispatch(uiActions.setLoading({ status: false, usedFor: 'notification' }))
    }
  }
}

export const clearAllNotifications = () => {
  return async (dispatch: Dispatch<Action>): Promise<void> => {
    try {
      dispatch(uiActions.setLoading({ status: true, usedFor: 'notification' }))
      const { data } = await api.get<{ data: INotificationStore }>('/notifications/mark/all')
      dispatch(notificationActions.patchNotification(data.data))
      dispatch(notificationActions.removeAllNotifications())
    } catch (error) {
      if (error) {
        const serverError = error as AxiosError<ServerError>
        handleErrorResponse(serverError, dispatch)
      }
    } finally {
      dispatch(uiActions.setLoading({ status: false, usedFor: 'notification' }))
    }
  }
}
