import React, { Fragment } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from 'stores'
import { Box, Button, Typography } from '@mui/material'
import { Add as AddIcon } from '@mui/icons-material'
import { Link } from 'react-router-dom'
import Searchbar from 'app/dashboard/components/Searchbar'
import ScaleContainer from 'app/dashboard/components/ScaleContainer'

interface GameToolBoxProps {
  handleSubmit: (searchText: string) => void
  handleReset: () => void
}

const GameToolBox: React.FC<GameToolBoxProps> = (props) => {
  const { userList = {} as any } = useSelector((state: RootState) => state.user)
  const { isLoading } = useSelector((state: RootState) => state.ui)

  return (
    <Fragment>
      <ScaleContainer
        key={`${isLoading?.status}-${isLoading?.usedFor}-${userList?.length}`}
        parentId="user-toolbar-container"
        childId="user-toolbar-content"
      >
        <Box
          id="user-toolbar-content"
          sx={{
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'space-between',
            flexWrap: 'wrap',
            mr: 2,
            pb: 1,
            borderBottom: '1px solid #ded9d9',
          }}
        >
          <Typography
            sx={{
              ml: '10px',
              paddingBottom: '5px',
              paddingTop: '6px',
            }}
            variant="h4"
            color="secondary.main"
            fontSize={'16px'}
            fontWeight={'600'}
          >
            Game Management
          </Typography>

          <Box sx={{ display: 'flex', gap: '10px' }}>
            <Link to="/app/add/game">
              <Button
                variant="contained"
                type="submit"
                color="secondary"
                sx={{
                  height: '100%',
                  fontWeight: '500',
                  color: 'white',
                  fontSize: '13px',
                  backgroundColor: '#722fee',
                  textTransform: 'none',
                  padding: '6px 12px',
                  borderRadius: '4px',
                }}
              >
                Create Game
              </Button>
            </Link>
          </Box>
        </Box>
      </ScaleContainer>
      <Box sx={{ mt: 1 }}>
        <Searchbar handleSubmit={props.handleSubmit} handleReset={props.handleReset} />
      </Box>
    </Fragment>
  )
}

export default GameToolBox
