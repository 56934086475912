import api from 'config/apiConfig'
import { ServerError } from 'stores/auth/auth-actions'
import { AxiosError } from 'axios'

export interface IResponse {
  uploadedImg: string[]
}
const handleFileUpload = async (
  images: any,
  setProgress: React.Dispatch<React.SetStateAction<number>>
): Promise<IResponse | string> => {
  try {
    const config = {
      onUploadProgress: (progressEvent: any) => {
        const percent = (progressEvent.loaded * 100) / progressEvent.total

        if (percent < 100) {
          setProgress(percent)
        }
      },
    }

    let response
    const formData = new FormData()
    if (images.length > 1) {
      for (let i = 0; i < images.length; i++) {
        formData.append('picture', images[i])
      }
      response = await api.put<IResponse>('upload/image', formData, config)
    } else {
      formData.append('picture', images)
      response = await api.patch<IResponse>('upload/featured-image?limit=1', formData, config)
    }
    return response.data
  } catch (error) {
    const serverError = error as AxiosError<ServerError>
    throw serverError
    // console.log(serverError.response?.data.error)
    // return (error as Error).message
  }
}

export default handleFileUpload
