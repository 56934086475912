import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface IInsideElements {
  winsCount: number
  playedCount: number
  amountWon: number
  betAmount: number
  status: string
  beforeBalance: number
  currentBalance: number
  createdAt: string
  profit: number
}

export interface IGameRecord {
  sn?: number
  _id?: string
  gameId: {
    title: string
  }
  user: {
    userId?: string
    account: string
  }
  duration: number
  id: number
  endAt: Date
  userNickName: string
  played: number
  wins: number
  gameEndTime: Date
  manager: string
  status: string
  amountWon: number
  createdAt: number
  betAmount: number
  beforeBalance: number
  currentBalance: number

  accountName: string
  playedCountTotal: number
  afterBalance: number
  startDate: string
  endDate: string
  winsCountTotal: number
  sessionId: string
  gameName: String
  totalTimeInSeconds: number
  profitTotal: number

  insideElements: IInsideElements[]
}

interface IState {
  recordList: IGameRecord[]
}

const initialState: IState = { recordList: [] }

const gameRecordSlice = createSlice({
  name: 'gameRecord',
  initialState,
  reducers: {
    setGameRecord(state, action: PayloadAction<IGameRecord[]>) {
      state.recordList = action.payload
    },
  },
})

export const gameRecordActions = gameRecordSlice.actions
export default gameRecordSlice.reducer
