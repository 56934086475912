import React, { Fragment } from 'react'
import { Box, Typography } from '@mui/material'
import ManageSearchBar from 'app/dashboard/components/ManageSearchBar'

const DistributorToolBox: React.FC = () => {
  return (
    <Fragment>
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          justifyContent: 'space-between',
          flexWrap: 'wrap',
          m: -1,
        }}
      >
        <Typography
          sx={{
            ml: '10px',
            paddingBottom: '5px',
            paddingTop: '6px',
            borderBottom: '1px solid #ded9d9',
            width: '100%',
          }}
          variant="h4"
          color="secondary.main"
          fontSize={'16px'}
          fontWeight={'600'}
        >
          Manage Stores & Distributors
        </Typography>
      </Box>
      <Box sx={{ mt: 3 }}>
        <ManageSearchBar searchLabel="Find by email, name or contact" />
      </Box>
    </Fragment>
  )
}

export default DistributorToolBox
