import React from 'react'
import { useTheme } from '@mui/material/styles'
import Box from '@mui/material/Box';
import useMediaQuery from '@mui/material/useMediaQuery'

const ScaleContainer = ({ children, parentId, childId, sx = {} , forceRefresh }: any) => {
  const theme = useTheme()
  const isSmScreen = useMediaQuery(theme.breakpoints.down('md'), { noSsr: true })

  const windowHasVScroll = () => {
    var hasVScroll = document.body.scrollHeight > document.body.clientHeight

    // @ts-ignore
    var cStyle = document.body.currentStyle || window.getComputedStyle(document.body, '')

    return (
      cStyle.overflow === 'visible' ||
      cStyle.overflowY === 'visible' ||
      (hasVScroll && cStyle.overflow === 'auto') ||
      (hasVScroll && cStyle.overflowY === 'auto')
    )
  }

  React.useEffect(() => {
    const scale = () => {
      setTimeout(() => {
        const parent = document.getElementById(parentId) as HTMLElement
        const child = document.getElementById(childId) as HTMLElement

        if (parent && child) {
          parent.removeAttribute('style')
          child.removeAttribute('style')

          const parentWidth = parent?.clientWidth + (windowHasVScroll() ? (isSmScreen ? 0 : 15) : 0)
          const parentHeight = parent?.clientHeight
          const childWidth = child?.clientWidth
          const childHeight = child?.clientHeight

          const scale = Math.min(parentWidth / childWidth, parentHeight / childHeight)

          parent.style.height = `${scale * child.clientHeight}px`
          parent.style.width = `100%`

          child.style.transform = `scale(${scale})`
          child.style.transformOrigin = `0 0`
        }
      }, 0)
    }

    window.addEventListener('resize', scale)

    scale()

    return () => {
      window.removeEventListener('resize', scale)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [forceRefresh])

  return (
    <Box sx={sx} id={parentId} style={{ overflow: 'hidden' }}>
      {children}
    </Box>
  )
} 

export default ScaleContainer
