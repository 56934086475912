import api from 'config/apiConfig'
import {
  transactionActions,
  ITransactionDetailsState,
  ITransactionSummaryState,
  IReport,
} from 'stores/transaction/transaction-slice'
import { uiActions } from 'stores/ui/ui-slice'
import { ServerError } from 'stores/auth/auth-actions'
import { Action, Dispatch } from 'redux'
import { AxiosError } from 'axios'
import { handleErrorResponse } from 'app/dashboard/utils/errorHandler'

export const getTransactionDetails =
  (
    userId: string = '',
    startDate: string,
    endDate: string,
    pageNumber?: number,
    rowsPerPage: number = 10
  ) =>
  async (dispatch: Dispatch<Action>): Promise<void> => {
    try {
      dispatch(uiActions.setLoading({ status: true, usedFor: 'transaction' }))
      const params = {
        searchKey: userId,
        startDate,
        endDate,
        pageNumber,
        size: rowsPerPage,
      }
      const { data } = await api.get<{ data: ITransactionDetailsState[]; paginationInfo: any }>(
        '/transactions',
        { params }
      )
      dispatch(transactionActions.setTransactionDetails(data.data))
      dispatch(transactionActions.setDateRange({ startDate, endDate }))
      return data?.paginationInfo
    } catch (error) {
      if (error) {
        const serverError = error as AxiosError<ServerError>
        handleErrorResponse(serverError, dispatch)
      }
    } finally {
      dispatch(uiActions.setLoading({ status: false, usedFor: 'transaction' }))
    }
  }

export const getTransactionDetailBySearch =
  (
    userId: string = '',
    startDate: string,
    endDate: string,
    pageNumber?: number,
    rowsPerPage: number = 10
  ) =>
  async (dispatch: Dispatch<Action>): Promise<void> => {
    try {
      dispatch(transactionActions.setLoading({ status: true, usedFor: 'transaction' }))
      const params = {
        searchKey: userId,
        startDate,
        endDate,
        pageNumber,
        size: rowsPerPage,
      }
      const { data } = await api.get<{ data: ITransactionDetailsState[]; paginationInfo: any }>(
        '/transactions',
        { params }
      )
      dispatch(transactionActions.setTransactionDetails(data.data))
      dispatch(transactionActions.setDateRange({ startDate, endDate }))

      return data?.paginationInfo
    } catch (error) {
      if (error) {
        const serverError = error as AxiosError<ServerError>
        handleErrorResponse(serverError, dispatch)
      }
    } finally {
      dispatch(transactionActions.setLoading({ status: false, usedFor: 'transaction' }))
    }
  }

export const getTransactionSummary =
  (
    userId: string = '',
    startDate: string,
    endDate: string,
    pageNumber: number = 1,
    rowsPerPage: number = 10
  ) =>
  async (dispatch: Dispatch<Action>): Promise<void> => {
    try {
      dispatch(uiActions.setLoading({ status: true, usedFor: 'transaction' }))
      const params = {
        customerName: userId,
        startDate,
        endDate,
        pageNumber,
        size: rowsPerPage,
      }
      const { data } = await api.get<{ data: ITransactionSummaryState[]; paginationInfo: any }>(
        '/transactions/record',
        { params }
      )
      dispatch(transactionActions.setTransactionSummary(data.data))
      dispatch(transactionActions.setDateRange({ startDate, endDate }))
      return data?.paginationInfo
    } catch (error) {
      if (error) {
        const serverError = error as AxiosError<ServerError>
        handleErrorResponse(serverError, dispatch)
      }
    } finally {
      dispatch(uiActions.setLoading({ status: false, usedFor: 'transaction' }))
    }
  }

export const clearTransaction =
  () =>
  async (dispatch: Dispatch<Action>): Promise<void> => {
    dispatch(transactionActions.setTransactionDetails([]))
    dispatch(transactionActions.setTransactionSummary([]))
  }

export const getReportsByDate =
  (startDate: string, endDate: string) =>
  async (dispatch: Dispatch<Action>): Promise<void> => {
    try {
      dispatch(uiActions.setLoading({ status: true, usedFor: 'transaction' }))
      const params = {
        startDate,
        endDate,
      }
      const { data } = await api.get<IReport>('/transactions-report', { params })
      dispatch(transactionActions.setReport(data))
      dispatch(transactionActions.setDateRange({ startDate, endDate }))
    } catch (error) {
      if (error) {
        const serverError = error as AxiosError<ServerError>
        handleErrorResponse(serverError, dispatch)
      }
    } finally {
      dispatch(uiActions.setLoading({ status: false, usedFor: 'transaction' }))
    }
  }

export const getSelectedCustomer =
  (userId: string) =>
  (dispatch: Dispatch<Action>): void => {
    dispatch(transactionActions.setSelectedCustomerId(userId))
  }

export const getResetPagination =
  () =>
  (dispatch: Dispatch<Action>): void => {
    dispatch(transactionActions.setResetPagination(true))
  }
