import { ReactNode } from 'react'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material'

interface ModalProps {
  title: string
  isOpen: boolean
  content?: string
  subContent?: any
  children?: ReactNode
  handleClose: () => void
  handleSave: () => void
  saveButtonTitle: string
  disableSaveBtn?: boolean
}

const ModalComponent = ({
  title,
  content,
  subContent,
  children,
  handleClose,
  handleSave,
  disableSaveBtn,
  saveButtonTitle,
  isOpen,
}: ModalProps) => (
  <Dialog
    open={isOpen}
    onClose={handleClose}
    fullWidth
    aria-labelledby="form-dialog-title"
    sx={{
      '& .MuiDialog-paper': {
        borderRadius: '12px',
        borderColor: 'rgba(0, 0, 0, 0.23)',
        borderStyle: 'solid',
        borderWidth: '1px',
      },
      '& .MuiDialog-paperWidthSm': {
        maxWidth: '500px',
      },
    }}
  >
    <DialogTitle
      id="form-dialog-title"
      sx={{
        padding: '20px',
        fontSize: '18px',
        fontWeight: 'bold',
        color: '#0382b7',
        borderBottom: '1px solid #efefef',
        marginBottom: '12px',
      }}
    >
      <span>{title}</span>
      {subContent && (
        <DialogContentText sx={{ color: '#8c8c8c', fontSize: 13 }}>{subContent}</DialogContentText>
      )}
    </DialogTitle>
    <DialogContent>
      {content && <DialogContentText sx={{ marginBottom: '1rem' }}>{content}</DialogContentText>}
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>{children}</Box>
    </DialogContent>
    <DialogActions sx={{ marginBottom: '1rem', p: '0px 16px' }}>
      <Button
        onClick={handleClose}
        color="primary"
        variant="contained"
        sx={{
          textTransform: 'none',
          backgroundColor: '#722fee',
          borderRadius: '6px',
          fontWeight: 600,
        }}
      >
        Close
      </Button>
      {handleSave && (
        <Button
          onClick={handleSave}
          color="success"
          disabled={disableSaveBtn}
          variant="contained"
          sx={{
            backgroundColor: '#0382b7',
            borderRadius: '6px',
            textTransform: 'none',
            fontWeight: 600,
          }}
        >
          {saveButtonTitle}
        </Button>
      )}
    </DialogActions>
  </Dialog>
)

export default ModalComponent
