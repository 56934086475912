import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface DistributorInterface {
  _id?: string
  fullName?: string
  email: string
  city?: string
  state?: string
  zip?: number
  phone: number
  department: string
  message: string
}

interface IDistributor {
  distributor: DistributorInterface[]
}

const initialState: IDistributor = { distributor: [] }

const distributorSlice = createSlice({
  name: 'distributor',
  initialState: initialState,
  reducers: {
    updateDistributorList(state, action: PayloadAction<DistributorInterface>) {
      state.distributor.push(action.payload)
    },
    setDistributor(state, action: PayloadAction<DistributorInterface[]>) {
      state.distributor = action.payload
    },
    removeDistributor(state, action: PayloadAction<string>) {
      state.distributor = state.distributor.filter((d) => d._id !== action.payload)
    },
  },
})

export const distributorAction = distributorSlice.actions
export default distributorSlice.reducer
