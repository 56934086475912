import { useState, useEffect } from 'react'
import moment from 'moment'
import {
  LineChart,
  Line,
  Legend,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from 'recharts'
import { Box, Typography, Paper, TextField } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'stores'
import { getRechargeRedeemStat } from 'stores/dashboard/dashboard-action'

const RechargeRedeemChart = () => {
  const [showAxisLegend, setShowAxisLegend] = useState(true)

  const startOfWeek = moment().startOf('week').format('YYYY-MM-DD')
  const endOfWeek = moment().endOf('week').format('YYYY-MM-DD')

  const [startDate, setStartDate] = useState(startOfWeek)
  const [endDate, setEndDate] = useState(endOfWeek)
  const theme = useTheme()

  const dispatch = useDispatch()

  const { rechargeRedeemStat } = useSelector((state: RootState) => state.dashboard)

  const formatTick = (tickItem: any) => {
    const daysOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
    const dayIndex = parseInt(tickItem) - 1

    return daysOfWeek[dayIndex]
  }

  useEffect(() => {
    dispatch(getRechargeRedeemStat(startDate, endDate))
  }, [dispatch, startDate, endDate])

  useEffect(() => {
    function handleResize() {
      if (window.innerWidth < 800 && showAxisLegend) {
        setShowAxisLegend(false)
      } else if (window.innerWidth >= 800 && !showAxisLegend) {
        setShowAxisLegend(true)
      }
    }
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [showAxisLegend])

  return (
    <Box
      sx={{ border: '2px solid #ccc', backgroundColor: 'background.default', borderRadius: '4px' }}
    >
      <Typography
        sx={{
          m: '10px 20px',
          borderBottom: '1px solid #ded9d9',
          paddingBottom: '5px',
          paddingTop: '6px',
        }}
        variant="h4"
        color="secondary.main"
        fontSize={'16px'}
        fontWeight={'600'}
      >
        Recharge & Redeem
      </Typography>
      <Paper
        sx={{
          py: 3,
          px: 2,
          borderRadius: 0,
          backgroundColor: 'background.default',
          boxShadow: 'none',
        }}
        elevation={3}
      >
        <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
          <TextField
            value={startDate}
            label="Start Date"
            type="date"
            variant="outlined"
            color="info"
            size="small"
            onChange={(e) => setStartDate(e.target.value)}
            focused
            sx={{
              width: '100%',
              mr: 1,
              borderRadius: '3px',
              backgroundColor: '#fff',
              '&  .MuiInputBase-root': {
                borderRadius: '3px',
              },
            }}
          />
          <TextField
            label="End Date"
            type="date"
            variant="outlined"
            color="info"
            size="small"
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
            focused
            sx={{
              width: '100%',
              borderRadius: '3px',
              backgroundColor: '#fff',
              '&  .MuiInputBase-root': {
                borderRadius: '3px',
              },
            }}
          />
        </Box>
        <ResponsiveContainer width="100%" height={310}>
          <LineChart width={500} height={300} data={rechargeRedeemStat as any}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis
              tickFormatter={formatTick}
              stroke={theme.palette.mode === 'dark' ? '#fff' : '#000'}
              dataKey="id"
            />
            {showAxisLegend && <YAxis stroke={theme.palette.mode === 'dark' ? '#fff' : '#000'} />}
            <Tooltip labelFormatter={() => ``} />
            <Legend />
            <Line
              type="monotone"
              dataKey="redeem"
              stroke="#8884d8"
              activeDot={{ r: 8 }}
              strokeWidth="4"
            />
            <Line
              type="monotone"
              dataKey="recharge"
              stroke="#82ca9d"
              activeDot={{ r: 8 }}
              strokeWidth="4"
            />
          </LineChart>
        </ResponsiveContainer>
      </Paper>
    </Box>
  )
}

export default RechargeRedeemChart
