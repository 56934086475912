//@ts-nocheck
import React, { Fragment } from 'react'
import { Box, Container } from '@mui/material'
import { useSelector } from 'react-redux'
import { RootState } from 'stores'
import ContactToolBox from './ContactToolBox'
import DisplayMessage from './DisplayMessage'
// import { getContactMessages } from 'stores/contact/contact-actions'
import PageTitle from 'app/dashboard/components/PageTitle'
import ScaleContainer from 'app/dashboard/components/ScaleContainer'

const Index: React.FC = () => {
  const { contactMessages } = useSelector((state: RootState) => state.contact)
  const { userList = {} as any } = useSelector((state: RootState) => state.user)
  const { isLoading } = useSelector((state: RootState) => state.ui)

  const [paginationInfo, setPaginationInfo] = React.useState({
    currentPage: 1,
    lastPage: false,
    pageSize: 10,
    totalPage: 0,
    totalRecord: 0,
  })

  // const dispatch = useDispatch()

  const handlePageChange = (_event: any, newPage: number) => {
    setPaginationInfo((value) => ({
      ...value,
      currentPage: newPage,
    }))
  }

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPaginationInfo((value) => ({
      ...value,
      pageSize: +event.target.value,
    }))
  }

  // useEffect(() => {
  //   const fetch = async () => {
  //     const data = (await dispatch(
  //       getContactMessages(paginationInfo?.currentPage, paginationInfo?.pageSize)
  //     )) as any
  //    data && setPaginationInfo(data)
  //   }

  //   fetch()
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [paginationInfo?.currentPage, paginationInfo?.pageSize])

  return (
    <Fragment>
      <PageTitle pageTitle="Contact Messages | Galaxy GamesGroup" />

      <Container maxWidth={false} sx={{ padding: '0px !important' }}>
        <Box
          sx={{
            border: '2px solid #ccc',
            borderRadius: '4px',
            backgroundColor: 'background.default',
          }}
        >
          <ContactToolBox contactMessages={contactMessages} />
        </Box>

        <ScaleContainer
          key={`${isLoading?.status}-${isLoading?.usedFor}-${userList.length}`}
          parentId="contact-tablebar-container"
          childId="contact-tablebar-content"
        >
          <Box
            id="contact-tablebar-content"
            sx={{
              transform: 'none !important',
              border: '2px solid #ccc',
              borderRadius: '4px',
              padding: '10px',
              marginTop: '10px',
              backgroundColor: '#f5f5f5',
            }}
          >
            {/* <Typography variant="h4" sx={{ mt: 1, mb: 3 }} color="textPrimary">
              Contact message
              <ExportBtn data={contactMessages} title="Contact-Message" />
            </Typography> */}

            <DisplayMessage
              contacts={contactMessages}
              handlePageChange={handlePageChange}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
              paginationInfo={paginationInfo}
            />
          </Box>
        </ScaleContainer>
      </Container>
    </Fragment>
  )
}

export default Index
