import React, { Fragment } from 'react'
import { Box, Typography } from '@mui/material'
import ManageSearchBar from 'app/dashboard/components/ManageSearchBar'
import ExportBtn from 'app/dashboard/components/ExportBtn'

const ContactToolBox: React.FC<any> = ({ contactMessages }) => {
  // const { userList = {} as any } = useSelector((state: RootState) => state.user)
  // const { isLoading } = useSelector((state: RootState) => state.ui)
  return (
    <Fragment>
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          justifyContent: 'space-between',
          flexWrap: 'wrap',
        }}
      >
        <Typography
          sx={{
            ml: '20px',
            mb: '20px',
            borderBottom: '1px solid #ded9d9',
            width: '100%',
            paddingBottom: '5px',
            paddingTop: '6px',
          }}
          variant="h4"
          color="secondary.main"
          fontSize={'16px'}
          fontWeight={'600'}
        >
          Manage Contact Message
        </Typography>
      </Box>

      <Box
        sx={{
          mb: 1,
          display: 'flex',
          justifyContent: 'space-between',
          pr: 2,
          alignItems: 'center',
        }}
      >
        <ManageSearchBar searchLabel="Find by email, name or contact" />
        <ExportBtn data={contactMessages as any} title="account-manager" />
      </Box>
    </Fragment>
  )
}

export default ContactToolBox
