import { useState, useEffect } from 'react'
import moment from 'moment'
import 'moment-timezone'
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts'
import { Box, Typography, Grid, Paper, TextField } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'stores'
import { getPlayTimeStat } from 'stores/dashboard/dashboard-action'
import SearchGameIdField from '../games/SearchGameIdField'

const RechargeRedeemChart = () => {
  const startOfWeek = moment().startOf('week').format('YYYY-MM-DD')
  const endOfWeek = moment().endOf('week').format('YYYY-MM-DD')

  const [startDate, setStartDate] = useState(startOfWeek)
  const [endDate, setEndDate] = useState(endOfWeek)
  const [dateOperator] = useState('DAILY')
  const [gameIdOption, setGameIdOption] = useState({} as any)

  const [showAxisLegend, setShowAxisLegend] = useState(true)
  const theme = useTheme()
  const dispatch = useDispatch()

  const { playTimeStat } = useSelector((state: RootState) => state.dashboard)

  useEffect(() => {
    dispatch(getPlayTimeStat(startDate, endDate, dateOperator, gameIdOption?.value || ''))
  }, [dispatch, startDate, endDate, dateOperator, gameIdOption])

  const formatTick = (tickItem: any) => {
    const daysOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
    const dayIndex = parseInt(tickItem) - 1

    return daysOfWeek[dayIndex]
  }

  useEffect(() => {
    function handleResize() {
      if (window.innerWidth < 800) {
        setShowAxisLegend(false)
      } else if (window.innerWidth >= 800) {
        setShowAxisLegend(true)
      }
    }
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [showAxisLegend])

  return (
    <Box
      sx={{ border: '2px solid #ccc', backgroundColor: 'background.default', borderRadius: '4px' }}
    >
      <Typography
        sx={{
          m: '10px 20px',
          borderBottom: '1px solid #ded9d9',
          paddingBottom: '5px',
          paddingTop: '6px',
        }}
        variant="h4"
        color="secondary.main"
        fontSize={'16px'}
        fontWeight={'600'}
      >
        Total Game Time Spent
      </Typography>
      <Paper
        sx={{
          py: 3,
          px: 2,
          borderRadius: 0,
          backgroundColor: 'background.default',
          boxShadow: 'none',
        }}
        elevation={3}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-evenly', mb: 3 }}>
          <Grid container spacing={1}>
            <Grid item xs={6} sm={4} md={4} lg={4}>
              <TextField
                value={startDate}
                label="Start Date"
                type="date"
                size="small"
                onChange={(e) => setStartDate(e.target.value)}
                focused
                sx={{
                  width: '100%',
                  borderRadius: '3px',
                  backgroundColor: '#fff',
                  '&  .MuiInputBase-root': {
                    borderRadius: '3px',
                  },
                }}
              />
            </Grid>
            <Grid item xs={6} sm={4} md={4} lg={4}>
              <TextField
                label="End Date"
                type="date"
                size="small"
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
                focused
                sx={{
                  width: '100%',
                  borderRadius: '3px',
                  backgroundColor: '#fff',
                  '&  .MuiInputBase-root': {
                    borderRadius: '3px',
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} sm={4} md={4} lg={4}>
              <SearchGameIdField onChange={setGameIdOption} value={gameIdOption} />
            </Grid>
          </Grid>
        </Box>
        <ResponsiveContainer width="100%" height={300}>
          <BarChart width={500} height={300} data={playTimeStat as any}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis
              stroke={theme.palette.mode === 'dark' ? '#fff' : '#000'}
              tickFormatter={formatTick}
              dataKey="id"
            />
            {showAxisLegend && <YAxis stroke={theme.palette.mode === 'dark' ? '#fff' : '#000'} />}
            <Tooltip labelFormatter={() => ``} />
            <Bar
              fill="#8884d8"
              maxBarSize={50}
              dataKey="totalTimeInSeconds"
              name="Total Time Spent"
              unit="s"
            />
          </BarChart>
        </ResponsiveContainer>
      </Paper>
    </Box>
  )
}

export default RechargeRedeemChart
