import { colors } from '@mui/material'
import { createTheme } from '@mui/material/styles'
import shadows from './shadows'
import typography from './typography'

export const theme = createTheme({
  palette: {
    mode: 'light',
    background: {
      default: '#f5f5f5',
      paper: colors.common.white,
    },
    primary: {
      main: '#163049',
      dark: '#0b66e4',
      light: '#fff',
    },
    secondary: {
      main: '#0382b7',
      light: '#326890',
    },

    text: {
      primary: '#4a494a',
      secondary: '#3398cc',
      disabled: '#636578',
    },

    divider: colors.blueGrey[900],
    info: {
      main: '#722fee',
      light: '#cccccc',
    },
  },

  components: {
    MuiCardContent: {
      styleOverrides: {
        root: {
          padding: '20px 25px',
          '&:last-child': {
            paddingBottom: '20px',
          },
        },
      },
    },

    MuiButton: {
      styleOverrides: {
        root: {
          height: '100%',
          padding: '8px 20px',
          borderRadius: '16px',
        },
      },
    },

    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: 'grey',
        },
      },
    },

    MuiTableCell: {
      styleOverrides: {
        root: {
          padding: '10px 20px',
          textAlign: 'center',
        },
      },
    },

    MuiTableRow: {
      styleOverrides: {
        root: {
          height: '72px',
        },
      },
    },

    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          height: '100%',
          borderRadius: '10px',
          border: 'none !important',

          '& .MuiOutlinedInput-notchedOutline': {
            borderWidth: '1px !important',
            borderColor: '#7F9DB9 !important',
          },
        },
      },
    },

    MuiChip: {
      styleOverrides: {
        root: {
          padding: '3.5px 3.5px',
          fontSize: '0.875rem',
          letterSpacing: '0.02857em',
        },
      },
    },

    MuiTable: {
      styleOverrides: {
        root: {
          borderRadius: '16px',
        },
      },
    },

    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: '16px',
        },
      },
    },

    MuiListItemIcon: {
      styleOverrides: {
        root: {
          minWidth: '0px',
          marginRight: '6px',
        },
      },
    },
  },

  shadows,

  typography,
})

export const darkTheme = createTheme({
  palette: {
    mode: 'dark',

    background: {
      default: '#162036',
      paper: '#0d1a2d',
    },

    secondary: {
      main: colors.green[500],
      light: '#D9D9D9',
    },

    info: {
      main: '#d8d8d8',
    },
  },

  components: {
    MuiCardContent: {
      styleOverrides: {
        root: {
          padding: '20px 25px',
          '&:last-child': {
            paddingBottom: '20px',
          },
        },
      },
    },

    MuiButton: {
      styleOverrides: {
        root: {
          height: '100%',
          padding: '8px 20px',
          borderRadius: '16px',
        },
      },
    },

    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: '#fff',
        },
      },
    },

    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          height: '100%',
          borderRadius: '10px',
          border: 'none !important',

          '& .MuiOutlinedInput-notchedOutline': {
            borderWidth: '1px !important',
            borderColor: 'grey !important',
          },
        },
      },
    },

    MuiTableCell: {
      styleOverrides: {
        root: {
          padding: '14px 20px',
          textAlign: 'center',
          borderColor: 'rgba(255, 255, 255, 0.12)',

          '&.MuiTableCell-head': {
            backgroundColor: '#111827 !important',
            color: '#D1D5DB !important',
          },
        },
      },
    },

    MuiTableRow: {
      styleOverrides: {
        root: {
          height: '72px',
        },
      },
    },

    MuiTable: {
      styleOverrides: {
        root: {
          borderRadius: '16px',
        },
      },
    },

    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: '16px',
        },
      },
    },

    MuiChip: {
      styleOverrides: {
        root: {
          padding: '3.5px 3.5px',
          fontSize: '0.875rem',
          letterSpacing: '0.02857em',
        },
      },
    },

    MuiFormHelperText: {
      styleOverrides: {
        root: {
          color: '#D1D5DB !important',
        },
      },
    },

    MuiListItemIcon: {
      styleOverrides: {
        root: {
          minWidth: '0px',
          marginRight: '6px',
        },
      },
    },
  },

  shadows,

  typography,
})
