import React from 'react'
import { Outlet } from 'react-router-dom'
import { makeStyles } from '@mui/styles'
import { useSelector } from 'react-redux'
import { Navigate } from 'react-router-dom'
import { RootState } from 'stores'
import Notification from 'app/dashboard/components/Notifications'
import ScrollToTop from '../components/ScrollToTop'

const useStyles = makeStyles({
  root: {
    // backgroundColor: theme.palette.background.paper,
    height: '100%',
    width: '100%',
  },
  wrapper: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
    // paddingTop: 64,
  },
  contentContainer: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
  },
  content: {
    flex: '1 1 auto',
    height: '100%',
    overflow: 'auto',
  },
})

const ClientLayout: React.FC = () => {
  const classes = useStyles()
  const { redirect, notification } = useSelector((state: RootState) => state.ui)

  return (
    <div className={classes.root}>
      {/* <TopBar onMobileNavOpen={() => setMobileNavOpen(true)} /> */}
      {redirect?.redirectTo && <Navigate to={`${redirect?.redirectTo}`} replace />}
      <div className={classes.wrapper}>
        <div className={classes.contentContainer}>
          <ScrollToTop>
            <div className={classes.content}>
              {notification && console.log('notification', notification.title)}
              {notification && notification.title === 'success' ? (
                <Notification notiType={notification.title} msg={notification.msg} />
              ) : (
                notification?.title === 'error' && (
                  <Notification notiType={notification.title} msg={notification.msg} />
                )
              )}
              <Outlet />
            </div>
          </ScrollToTop>
        </div>
      </div>
      {/* <Footer /> */}
      {/* <Copyright /> */}
    </div>
  )
}

export default ClientLayout
