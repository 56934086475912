import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface ContactInterface {
  _id?: string
  firstName?: string
  lastName?: string
  email: string
  phone: string
  message: string
}

interface IState {
  contactMessages: ContactInterface[]
}

const initialState: IState = {
  contactMessages: [],
}

const ContactSlice = createSlice({
  name: 'contact',
  initialState: initialState,
  reducers: {
    setContact(state, action: PayloadAction<ContactInterface[]>) {
      state.contactMessages = action.payload
    },
    removeCategory(state, action: PayloadAction<string>) {
      state.contactMessages = state.contactMessages.filter((msg) => msg._id !== action.payload)
    },
  },
})

export const contactMsgActions = ContactSlice.actions
export default ContactSlice.reducer
