import React, { FormEvent, Fragment, useState } from 'react'
import {
  Typography,
  Box,
  Stack,
  TextField,
  FormControl,
  Select,
  MenuItem,
  SelectChangeEvent,
  Container,
} from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton'
import PageTitle from 'app/dashboard/components/PageTitle'
import { resetUserPassword } from 'stores/user/user-actions'
import { RootState } from 'stores'
import { useDispatch, useSelector } from 'react-redux'

const options: string[] = [
  'China',
  'HST',
  'AKST',
  'PST',
  'MST',
  'CST',
  'EST',
  'HST SUM',
  'AKST SUM',
  'PST SUM',
  'MST SUM',
  'CST SUM',
  'EST SUM',
]

const Setting: React.FC = () => {
  const [formData, setFormData] = useState({
    oldPassword: '',
    newPassword: '',
    confirmPassword: '',
    timeZone: options[0],
  })
  const [diffConfirmPass, setDiffConfirmPass] = useState<boolean>(false)
  const [isPassValid, setIsPassValid] = useState<boolean>(true)
  const { isLoading } = useSelector((state: RootState) => state.ui)
  const { userInfo } = useSelector((state: RootState) => state.user)

  const dispatch = useDispatch()

  type formKey = 'oldPassword' | 'newPassword' | 'confirmPassword' | 'timeZone'

  const handleChange = (
    event: SelectChangeEvent | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    formKey: formKey
  ) => {
    if (formKey === 'timeZone') {
      setFormData({ ...formData, [formKey]: event.target.value })
    } else {
      setFormData({ ...formData, [formKey]: event.target.value })
    }
  }

  const handleOnFocus = () => {
    setIsPassValid(true)
    setDiffConfirmPass(false)
  }

  const handleSubmit = (event: FormEvent) => {
    event.preventDefault()
    const { oldPassword, newPassword, confirmPassword } = formData
    const re = new RegExp(/[\W]/g)
    const inValidPass = re.test(newPassword) || re.test(confirmPassword)

    if (inValidPass) {
      return setIsPassValid(false)
    }

    if (confirmPassword !== newPassword) {
      return setDiffConfirmPass(true)
    }

    dispatch(resetUserPassword(oldPassword, newPassword))
  }

  return (
    <Fragment>
      <PageTitle pageTitle="Setting | Galaxy GamesGroup" />

      <Container
        maxWidth={false}
        sx={{ paddingLeft: '10px !important', paddingTop: '16px !important' }}
      >
        <Typography
          variant="h4"
          mb={2}
          sx={{
            ml: '10px',

            mb: '20px',
            borderBottom: '1px solid #ded9d9',
            paddingBottom: '5px',
            paddingTop: '6px',
          }}
          color="secondary.main"
          fontSize={'16px'}
          fontWeight={'600'}
        >
          Settings Page
        </Typography>
        <Box component={'form'} onSubmit={handleSubmit}>
          <Stack
            spacing={2}
            sx={{
              border: '2px solid #ccc',
              borderRadius: '4px',
              padding: '8px',
              '& .MuiOutlinedInput-notchedOutline': {
                borderColor: '#7F9DB9 !important',
                borderRadius: '4px',
              },
              '& .MuiFormControl-root': {
                flexDirection: 'row',
                gap: '10px',
              },
              '&  .MuiInputBase-root': {
                borderRadius: '3px',
                maxHeight: 22,
              },
              '& input': {
                width: '156px',
              },
              '& input, .MuiSelect-select': {
                background: '#fff',
                fontSize: '12px',
                height: '22px',
                padding: '0px 5px',
              },
              '& .MuiSelect-select': {
                width: '128px',
              },
              '& .Mui-disabled input': {
                background: '#e5e5e5',
              },
              '& .MuiFormHelperText-root': {
                fontSize: '13px',
                fontFamily: 'sans-serif',
                letterSpacing: 'normal',
                lineHeight: 'unset',
                color: '#000',
              },
            }}
          >
            <Box
              sx={{
                display: 'flex',
                gap: '12px',
                alignItems: 'center',
              }}
            >
              <Typography
                sx={{
                  color: '#4a494a',
                  fontWeight: '600',
                  fontSize: '12px',
                  width: '14%',
                  textAlign: 'right',
                }}
              >
                Account:
              </Typography>
              <TextField
                variant="outlined"
                defaultValue={userInfo?.account}
                InputProps={{
                  readOnly: true,
                }}
                size="small"
                disabled
              />
            </Box>
            <Box
              sx={{
                display: 'flex',
                gap: '12px',
                alignItems: 'center',
              }}
            >
              <Typography
                sx={{
                  color: '#4a494a',
                  fontWeight: '600',
                  fontSize: '12px',
                  width: '14%',
                  textAlign: 'right',
                }}
              >
                Old Password:
              </Typography>
              <TextField
                type="password"
                value={formData.oldPassword}
                variant="outlined"
                size="small"
                onChange={(e) => handleChange(e, 'oldPassword')}
                required
              />
            </Box>
            <Box
              sx={{
                display: 'flex',
                gap: '12px',
                alignItems: 'center',
              }}
            >
              <Typography
                sx={{
                  color: '#4a494a',
                  fontWeight: '600',
                  fontSize: '12px',
                  width: '14%',
                  textAlign: 'right',
                }}
              >
                New Password:
              </Typography>
              <TextField
                type="password"
                value={formData.newPassword}
                variant="outlined"
                size="small"
                error={!isPassValid}
                helperText="Letters, underscore & numbers"
                onChange={(e) => handleChange(e, 'newPassword')}
                onFocus={handleOnFocus}
                required
              />
            </Box>
            <Box
              sx={{
                display: 'flex',
                gap: '12px',
                alignItems: 'center',
              }}
            >
              <Typography
                sx={{
                  color: '#4a494a',
                  fontWeight: '600',
                  fontSize: '12px',
                  width: '14%',
                  textAlign: 'right',
                }}
              >
                Confirm Password:
              </Typography>
              <TextField
                type="password"
                value={formData.confirmPassword}
                variant="outlined"
                error={diffConfirmPass}
                helperText={diffConfirmPass && 'Confirm password do not match'}
                size="small"
                onChange={(e) => handleChange(e, 'confirmPassword')}
                onFocus={handleOnFocus}
                required
              />
            </Box>
            <Box
              sx={{
                display: 'flex',
                gap: '12px',
                alignItems: 'center',
              }}
            >
              <Typography
                sx={{
                  color: '#4a494a',
                  fontWeight: '600',
                  fontSize: '12px',
                  width: '14%',
                  textAlign: 'right',
                }}
              >
                Time Zone:
              </Typography>
              <FormControl fullWidth size="small" sx={{ flex: 1 }}>
                {/* <InputLabel id="demo-simple-select-label">Time Zone</InputLabel> */}

                <Select
                  value={formData.timeZone}
                  // label="Time Zone"

                  onChange={(e) => handleChange(e, 'timeZone')}
                >
                  {options.map((menu, index) => (
                    <MenuItem key={index + 1} value={menu}>
                      {menu}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>

            <LoadingButton
              loading={isLoading?.usedFor === 'userActions' && isLoading.status}
              type="submit"
              variant="contained"
              sx={{
                borderRadius: '4px',
                width: 'fit-content',
                marginLeft: 'calc(14% + 12px) !important',
                fontSize: 14,
                height: 30,
                textTransform: 'capitalize',
                padding: ' 0px 6px',
                backgroundColor: '#0382b7',
              }}
              color="secondary"
            >
              Save
            </LoadingButton>
          </Stack>
        </Box>
      </Container>
    </Fragment>
  )
}

export default Setting
