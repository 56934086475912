import React, { Fragment, useEffect, useState } from 'react'
import { Box, Container, Typography } from '@mui/material'
import { useSelector, useDispatch } from 'react-redux'
import PageTitle from 'app/dashboard/components/PageTitle'
import TransactionSummary from './TransactionSummary'
import TransactionDetail from './TransactionDetails'
import FilterBar from 'app/dashboard/components/FilterBar'
import { RootState } from 'stores'
import {
  getTransactionDetails,
  getTransactionSummary,
  getSelectedCustomer,
} from 'stores/transaction/transaction-actions'
import { thisWeekSunday, thisWeekSaturday } from 'app/dashboard/utils/dateHandler'
import ExportBtn from 'app/dashboard/components/ExportBtn'
import ScaleContainer from 'app/dashboard/components/ScaleContainer'
import { useLocation } from 'react-router-dom'

const Index: React.FC = () => {
  const { transactionDetailData, transactionSummaryData, startDate, endDate, selectedCustomerId } =
    useSelector((state: RootState) => state.transaction)

  const dispatch = useDispatch()
  const firstRender = React.useRef(true)
  const { isLoading } = useSelector((state: RootState) => state.ui)
  const { userList = {} as any } = useSelector((state: RootState) => state.user)
  const { state } = useLocation()

  const { id } = state || {}

  const [summaryPageInfo, setSummaryPageInfo] = useState({
    currentPage: 1,
    lastPage: false,
    pageSize: 10,
    totalPage: 0,
    totalRecord: 0,
  })

  const handleSummaryPageChange = (_event: any, newPage: number) => {
    setSummaryPageInfo((value) => ({
      ...value,
      currentPage: newPage,
    }))
  }

  const handleSummaryChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSummaryPageInfo((value) => ({
      ...value,
      pageSize: +event.target.value,
    }))
  }

  const [paginationInfo, setPaginationInfo] = useState({
    currentPage: 1,
    lastPage: false,
    pageSize: 10,
    totalPage: 0,
    totalRecord: 0,
  })

  const handlePageChange = (_event: any, newPage: number) => {
    setPaginationInfo((value) => ({
      ...value,
      currentPage: newPage,
    }))
  }

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPaginationInfo((value) => ({
      ...value,
      pageSize: +event.target.value,
    }))
  }

  const startWeek = firstRender.current ? thisWeekSunday : (startDate as string)
  const endWeek = firstRender.current ? thisWeekSaturday : (endDate as string)

  const setSelectedUser = (id: string) => {
    dispatch(getSelectedCustomer(id))
  }

  const handleRowClick = async (searchId: any) => {
    setSelectedUser(searchId)
    const dataDetails = (await dispatch(
      getTransactionDetails(
        searchId,
        startWeek,
        endWeek,
        paginationInfo.currentPage,
        paginationInfo.pageSize
      )
    )) as any
    dataDetails && setPaginationInfo({ ...dataDetails, currentPage: 1, pageSize: 5 })
  }

  useEffect(() => {
    const fetchSummary = async () => {
      firstRender.current = false
      const dataSummary = (await dispatch(
        getTransactionSummary(
          id || selectedCustomerId,
          startWeek,
          endWeek,
          summaryPageInfo.currentPage,
          summaryPageInfo.pageSize
        )
      )) as any
      console.log(dataSummary)
      dataSummary && setSummaryPageInfo({ ...dataSummary, pageSize: 10 })
    }

    fetchSummary()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [summaryPageInfo.currentPage])

  useEffect(() => {
    const fetchDetails = async () => {
      firstRender.current = false
      const dataDetails = (await dispatch(
        getTransactionDetails(
          id || selectedCustomerId,
          startWeek,
          endWeek,
          paginationInfo.currentPage,
          paginationInfo.pageSize
        )
      )) as any
      dataDetails && setPaginationInfo({ ...dataDetails, pageSize: 10 })
    }
    fetchDetails()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paginationInfo.currentPage, paginationInfo.pageSize])
  return (
    <Fragment>
      <PageTitle pageTitle="Transaction Details | Galaxy GamesGroup" />
      <Container maxWidth={false} sx={{ padding: '0px !important' }}>
        <Box
          sx={{
            backgroundColor: 'background.default',
            border: '2px solid #ccc',
            borderRadius: '4px',
          }}
        >
          <FilterBar
            customerId={selectedCustomerId}
            handlePaginationReset={(paginationInfo) => {
              setSummaryPageInfo(paginationInfo)
              setPaginationInfo({
                currentPage: 1,
                lastPage: false,
                pageSize: 10,
                totalPage: 0,
                totalRecord: 0,
              })
            }}
          />
        </Box>
        <ScaleContainer
          key={`${isLoading?.status}--${isLoading?.usedFor}-${userList.length}`}
          parentId="transaction-summary-container"
          childId="transaction-summary-content"
        >
          <Box
            id="transaction-summary-content"
            sx={{
              backgroundColor: 'background.default',
              border: '2px solid #ccc',
              borderRadius: '4px',
              marginTop: '10px',
              transform: 'none !important',
              padding: '12px',
            }}
          >
            <Typography
              sx={{
                ml: '10px',
                mb: '20px',
                borderBottom: '1px solid #ded9d9',
                paddingBottom: '5px',
              }}
              variant="h4"
              color="secondary.main"
              fontSize={'16px'}
              fontWeight={'600'}
            >
              Transaction Summary
            </Typography>

            <TransactionSummary
              results={transactionSummaryData}
              handlePageChange={handleSummaryPageChange}
              handleChangeRowsPerPage={handleSummaryChangeRowsPerPage}
              paginationInfo={summaryPageInfo}
              handleRowClick={handleRowClick}
            />
          </Box>
        </ScaleContainer>
        <ScaleContainer
          parentId="transaction-details-container"
          childId="transaction-details-content"
        >
          <Box
            id="transaction-details-content"
            sx={{
              backgroundColor: 'background.default',
              border: '2px solid #ccc',
              borderRadius: '4px',
              transform: 'none !important',
              marginTop: '12px',
              padding: '12px',
            }}
          >
            <Typography
              sx={{
                ml: '10px',
                pr: '10px',
                mb: '20px',
                borderBottom: '1px solid #ded9d9',
                width: '100%',
                paddingBottom: '5px',
                display: 'flex',
                justifyContent: 'space-between',
              }}
              variant="h4"
              color="secondary.main"
              fontSize={'16px'}
              fontWeight={'600'}
            >
              Transaction Details
              <ExportBtn icon data={transactionDetailData} title="transactions" />
            </Typography>
            <TransactionDetail
              results={transactionDetailData}
              handlePageChange={handlePageChange}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
              paginationInfo={paginationInfo}
            />
          </Box>
        </ScaleContainer>
      </Container>
    </Fragment>
  )
}

export default Index
