//@ts-nocheck
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface ActionPayloadType {
  status: number
  title: 'error' | 'success' | 'pending'
  msg: string
}

interface IisLoading {
  status: boolean
  usedFor:
    | 'loginBtn'
    | 'userList'
    | 'newUserReg'
    | 'userActions'
    | 'transaction'
    | 'game'
    | 'gameByCategory'
    | 'distributor'
    | 'udid'
    | 'version'
    | 'contact'
    | 'jp'
    | 'global'
    | 'adminList'
    | 'dashboard'
    | 'burntReport'
    | 'notification'
    | 'rtp'
    | 'issues'
    | 'issueReply'
}

interface IRedirect {
  redirectTo: string | null
}
interface IisDarkMode {
  status: boolean
}

interface InitialStateType {
  notification: null | ActionPayloadType
  isLoading?: IisLoading
  redirect?: IRedirect
  isDarkMode?: IisDarkMode
}

const uiSlice = createSlice({
  name: 'ui',
  initialState: {
    notification: null,
    isDarkMode: !!JSON.parse(localStorage.getItem('isDarkMode')),
  } as InitialStateType,
  reducers: {
    showNotification(state, action: PayloadAction<null | ActionPayloadType>) {
      if (!action.payload) {
        state.notification = action.payload
      } else {
        state.notification = action.payload
      }
    },

    setLoading(state, action: PayloadAction<IisLoading>) {
      state.isLoading = {
        status: action.payload.status,
        usedFor: action.payload.usedFor,
      }
    },

    setDarkMode(state, action: PayloadAction<IisDarkMode>) {
      state.isDarkMode = action.payload
    },

    hideNotification(state) {
      state.notification = null
    },

    redirect(state, action: PayloadAction<IRedirect>) {
      state.redirect = action.payload
    },
  },
})

export const uiActions = uiSlice.actions
export default uiSlice.reducer
