import axios from 'axios'
// export const baseURL = process.env.REACT_APP_LOCAL_BACKEND

export const baseURL = process.env.REACT_APP_BACKEND
export const downloadGameBaseURL = process.env.DOWNLOAD_GAME_BACKEND

const api = axios.create({
  baseURL,
})

// export const downloadApi = axios.create({

//   // baseURL: 'https://file.starliners.xyz' // Here for some reason not working using downloadGameBaseURL so using hardcoded base url
// })

export const downloadAndroidFile = axios.create({
  // baseURL: 'https://starliners.nyc3.digitaloceanspaces.com/live/starliner.apk' // Here for some reason not working using downloadGameBaseURL so using hardcoded base url

   baseURL: 'https://file.galaxygames.club/api/v1/download/game/starliner.apk' // Here for some reason not working using downloadGameBaseURL so using hardcoded base url
})
export const downloadIosFile = axios.create({
  baseURL: 'itms-services://?action=download-manifest&url=https://file.galaxygames.club/Assets/Ios/manifest.plist'

  // baseURL: 'itms-services://?action=download-manifest&url=https://starliners.nyc3.digitaloceanspaces.com/live/manifest.plist' 


  // baseURL: 'https://file.starliners.xyz' // Here for some reason not working using downloadGameBaseURL so using hardcoded base url
})

export default api
