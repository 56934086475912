import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useLocation, Navigate } from 'react-router-dom'
import { getLoggedUserInfo } from 'stores/user/user-actions'
import { RootState } from 'stores/index'

const RequireAdminAccess: React.FC<{ children: JSX.Element }> = ({ children }) => {
  const { token, role } = useSelector((state: RootState) => state.auth)
  const isAdmin = token && role !== 'customer' ? true : false
  const location = useLocation()
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getLoggedUserInfo())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (!isAdmin) {
    return <Navigate to="/404" state={{ from: location }} />
  }

  return children
}

export default RequireAdminAccess
