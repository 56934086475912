import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface CategoryInterface {
  _id: string
  name: string
  createdAt: Date
}

interface IState {
  gameCategory: CategoryInterface[]
}

const initialState: IState = { gameCategory: [] }

const CategorySlice = createSlice({
  name: 'category',
  initialState: initialState,
  reducers: {
    setCategory(state, action: PayloadAction<CategoryInterface[]>) {
      state.gameCategory = action.payload
    },
    pushCategory(state, action: PayloadAction<CategoryInterface>) {
      state.gameCategory.push(action.payload)
    },
    removeCategory(state, action: PayloadAction<string>) {
      state.gameCategory = state.gameCategory.filter((cat) => cat._id !== action.payload)
    },
    updateCategory(state, action: PayloadAction<CategoryInterface>) {
      state.gameCategory = state.gameCategory.map((category) => {
        if (category._id === action.payload._id) {
          return action.payload
        }
        return category
      })
    },
  },
})

export const gameCategoryActions = CategorySlice.actions
export default CategorySlice.reducer
