import { Fragment, useEffect, useState } from 'react'
import { Box, Container, Typography } from '@mui/material'
import { useSelector, useDispatch } from 'react-redux'
import PageTitle from 'app/dashboard/components/PageTitle'
import { RootState } from 'stores'
import { BurntReportInterface } from 'stores/burntreport/burnt-report-slice'
import moment from 'moment'
import {
  getBurntReportDetails,
  getBurntReportOverview,
} from 'stores/burntreport/burnt-report-action'
import ExportBtn from 'app/dashboard/components/ExportBtn'
import ScaleContainer from 'app/dashboard/components/ScaleContainer'
import ReportDetails from './ReportDetails'
import ReportSearch from './ReportSearch'
import GameSearch from './GameSearch'
import ReportOverview from './ReportOverview'

const Index: React.FC = () => {
  const dispatch = useDispatch()
  const { isLoading } = useSelector((state: RootState) => state.ui)
  const { userList = {} as any } = useSelector((state: RootState) => state.user)
  const { burntReportDetails } = useSelector((state: RootState) => state.burntReport)
  const today = moment().format('YYYY-MM-DD')

  const [startDate, setStartDate] = useState(today)
  const [endDate, setEndDate] = useState(today)
  const [startOverviewDate, setStartOverviewDate] = useState(today)
  const [endOverviewDate, setEndOverviewDate] = useState(today)
  const [gameIdOption, setGameIdOption] = useState({}) as any
  const [searchText, setSearchText] = useState('')

  const [paginationInfo, setPaginationInfo] = useState({
    currentPage: 1,
    lastPage: false,
    pageSize: 10,
    totalPage: 0,
    totalRecord: 0,
  })

  const handlePageChange = (_event: any, newPage: number) => {
    setPaginationInfo((value) => ({
      ...value,
      currentPage: newPage,
    }))
  }

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPaginationInfo((value) => ({
      ...value,
      pageSize: +event.target.value,
    }))
  }

  const handleOverviewSearch = (searchKey: string) => {
    setSearchText(searchKey)
    setPaginationInfo((value) => ({
      ...value,
      currentPage: 1,
    }))
  }

  const handleOverviewReset = () => {
    setGameIdOption({})
    setSearchText('')
    setStartOverviewDate(today)
    setEndOverviewDate(today)
    setPaginationInfo((value) => ({
      ...value,
      currentPage: 1,
    }))
  }

  const handleSearch = (searchKey: string) => {
    setSearchText(searchKey)
    setPaginationInfo((value) => ({
      ...value,
      currentPage: 1,
    }))
  }

  const handleReset = () => {
    setStartDate(today)
    setEndDate(today)
    setSearchText('')
    setPaginationInfo((value) => ({
      ...value,
      currentPage: 1,
    }))
  }

  useEffect(() => {
    const fetch = async () => {
      const data = (await dispatch(
        getBurntReportDetails(
          paginationInfo?.currentPage,
          paginationInfo?.pageSize,
          startDate,
          endDate
        )
      )) as any
      data && setPaginationInfo(data)
    }
    fetch()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paginationInfo?.currentPage, paginationInfo?.pageSize, searchText])

  useEffect(() => {
    const fetch = async () => {
      await dispatch(
        getBurntReportOverview(gameIdOption?.value || '', startOverviewDate, endOverviewDate)
      )
    }
    fetch()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, searchText])

  return (
    <Fragment>
      <PageTitle pageTitle="Burnt Down Records | Galaxy GamesGroup" />
      <Container maxWidth={false} sx={{ paddingLeft: '0px !important' }}>
        <ScaleContainer
          key={`${isLoading?.status}-${isLoading?.usedFor}-${userList.length}`}
          parentId="record-overview-container"
          childId="record-overview-content"
        >
          <Box
            id="record-overview-content"
            sx={{
              border: '2px solid #ccc',
              padding: '12px',
              borderRadius: '4px',
              backgroundColor: 'background.default',
              pb: 3,
            }}
          >
            <Typography
              variant="h4"
              color="secondary.main"
              fontSize={'16px'}
              fontWeight={'600'}
              sx={{ borderBottom: '1px solid #ded9d9', mb: '10px', paddingBottom: '10px' }}
            >
              Burnt Down Overview
            </Typography>
            <GameSearch
              handlers={{
                setGameIdOption,
                handleOverviewSearch,
                handleOverviewReset,
                setStartOverviewDate,
                setEndOverviewDate,
              }}
              values={{ gameIdOption, endOverviewDate, startOverviewDate }}
            />
            <ReportOverview />
          </Box>
        </ScaleContainer>
        {/* <ScaleContainer parentId="record-page-container" childId="record-page-content"> */}
        <Box
          id="record-page-content"
          sx={{
            border: '2px solid #ccc',
            padding: '12px',
            borderRadius: '4px',
            mt: 2,
            backgroundColor: 'background.default',
            pb: 3,
          }}
        >
          <Typography
            variant="h4"
            color="secondary.main"
            fontSize={'16px'}
            fontWeight={'600'}
            sx={{
              borderBottom: '1px solid #ded9d9',
              mb: '20px',
              paddingBottom: '10px',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            Burnt Down Details
            <ExportBtn data={userList} title="burntdown" />
          </Typography>
          <ReportSearch
            handlers={{
              setStartDate,
              setEndDate,
              handleSearch,
              handleReset,
            }}
            values={{
              startDate,
              endDate,
            }}
          />
          <ReportDetails
            results={burntReportDetails as BurntReportInterface[]}
            paginationInfo={paginationInfo}
            handlePageChange={handlePageChange}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </Box>
        {/* </ScaleContainer> */}
      </Container>
    </Fragment>
  )
}

export default Index
