export * from './paths';

export const STATUSES = {
    active: 'active',
    inActive: 'inactive'
}

export const UDID_TYPES = {
    true: 'true',
    false: 'false',
}

export const APP_FILE_NAMES = {
    android: 'starliner.apk',
    ios: 'starliner.ipa'
}
