import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import api from 'config/apiConfig'
import moment from 'moment'
import 'moment-timezone'

// //createSlice
// //powerful way to create reducer function

// export enum UserRoleEnum {
//   CUSTOMER = 'customer',
//   MANAGER = 'manager',
//   SUBADMIN = 'subadmin',
//   ADMIN = 'admin',
//   SUPERADMIN = 'superadmin',
// }

export enum UserRoleEnum {
  CUSTOMER = 'customer',
  MANAGER = 'store',
  VENDOR = 'subdistributor',
  DISTRIBUTOR = 'distributor',
  MASTER = 'master',
  ADMIN = 'admin',
  SUPERADMIN = 'superadmin',
}

export interface ILoginAction {
  token: string | null
  userId: string | null
  role: UserRoleEnum | null
  tz: string | null
}

const initialState: ILoginAction = JSON.parse(localStorage.getItem('auth') as string) || {
  token: null,
  userId: null,
  role: null,
  tz: moment.tz.guess(),
}

//setting bearer token to request header
api.defaults.headers.common['Authorization'] = `Bearer ${initialState.token}`
//setting timezone
api.defaults.headers.common['tz'] = initialState.tz || moment.tz.guess()

api.defaults.headers.common['buildVersion'] = 'blue'

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    login(state, action: PayloadAction<ILoginAction>) {
      state.token = action.payload.token
      state.userId = action.payload.userId
      state.role = action.payload.role
    },
    // register() {},
    logout(state) {
      state.role = null
      state.userId = null
      state.role = null
    },
  },
})

export const authActions = authSlice.actions
export default authSlice.reducer
