import React from 'react'
import { ChangeEvent, Fragment, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'stores'
import {
  Box,
  Typography,
  Button,
  Card,
  CardContent,
  TextField,
  Stack,
  Switch,
  Chip,
} from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { DemoContainer } from '@mui/x-date-pickers/internals/demo'
import {
  getTransactionSummary,
  getReportsByDate,
  clearTransaction,
  getTransactionDetails,
} from 'stores/transaction/transaction-actions'
import {
  previousWeekSunday,
  previousWeekSaturday,
  thisWeekSunday,
  thisWeekSaturday,
  today,
  yesterday,
} from 'app/dashboard/utils/dateHandler'
import ScaleContainer from './ScaleContainer'
import moment from 'moment'

const label = { inputProps: { 'aria-label': 'Toggle Switch  ' } }

const FilterBar: React.FC<{
  barHeading?: string
  customerId?: string
  handlePaginationReset?: any
}> = ({ barHeading = 'Transaction Records', handlePaginationReset }) => {
  const [searchKey, setSearchKey] = useState<string>('')
  const [startDate, setStartDate] = useState<string>(thisWeekSunday)
  const [searchShortcut, setSearchShortcut] = React.useState('')
  const [endDate, setEndDate] = useState<string>(thisWeekSaturday)
  const [displaySwitch] = useState(barHeading.toLowerCase() === 'reports' ? true : false)
  const { isLoading } = useSelector((state: RootState) => state.ui)
  const { userList = {} as any } = useSelector((state: RootState) => state.user)
  const dispatch = useDispatch()

  const handleReset = () => {
    setSearchKey('')
    setStartDate(thisWeekSunday)
    setEndDate(thisWeekSaturday)
    setSearchShortcut('')
    if (barHeading === 'Transaction Records') {
      dispatch(clearTransaction())
      dispatch(getTransactionSummary('', thisWeekSunday, thisWeekSaturday))
      dispatch(getTransactionDetails('', thisWeekSunday, thisWeekSaturday, 1))
    }
    if (barHeading === 'Reports') {
      dispatch(getReportsByDate(thisWeekSunday, thisWeekSaturday))
    }
  }

  const handleToggleSwitch = (e: ChangeEvent<HTMLInputElement>) => {
    console.log('Current Value of the Switch', e.target.checked)
  }

  const searchByDate = async (
    searchKey: string,
    startDate: string,
    endDate: string,
    searchShortcut?: string
  ) => {
    setSearchShortcut(searchShortcut || '')

    if (startDate && endDate) {
      if (barHeading === 'Transaction Records') {
        dispatch(clearTransaction())
        const dataDetails = await dispatch(getTransactionSummary(searchKey, startDate, endDate))
        handlePaginationReset && handlePaginationReset(dataDetails)
      } else if (barHeading === 'Reports') {
        dispatch(getReportsByDate(startDate, endDate))
      }
    } else if (searchKey) {
      if (barHeading === 'Transaction Records') {
        dispatch(clearTransaction())
        const dataDetails = await dispatch(
          getTransactionSummary(searchKey, thisWeekSunday, thisWeekSaturday)
        )
        handlePaginationReset && handlePaginationReset(dataDetails)
      }
    }
  }

  return (
    <Fragment>
      <DemoContainer components={['DatePicker']}>
        <ScaleContainer
          key={`${isLoading?.status}--${isLoading?.usedFor}-${userList.length}`}
          parentId="filter-bar-container"
          childId="filter-bar-content"
        >
          <Box id="filter-bar-content">
            <Box>
              <Card
                sx={{
                  borderRadius: '0px',
                  boxShadow: 0,
                  padding: '0px',
                  backgroundColor: 'background.default',
                }}
              >
                <CardContent sx={{ padding: '20px 12px 11px 12px !important' }}>
                  <Box
                    sx={{
                      display: 'flex',
                      gap: '4px',
                      mb: '11px',
                      width: '100%',
                      flexDirection: 'column',
                    }}
                  >
                    {barHeading !== 'Reports' && (
                      <Box
                        sx={{
                          display: 'flex',
                          marginBottom: '6px',
                          alignItems: 'center',
                          gap: '10px',
                        }}
                      >
                        <Typography sx={{ color: '#4a494a', fontWeight: '600', fontSize: '14px' }}>
                          Customer:{' '}
                        </Typography>
                        <TextField
                          // value={searchKey}
                          defaultValue={searchKey}
                          placeholder="ID, Account or Nickname"
                          variant="outlined"
                          size="small"
                          // InputProps={{
                          //   startAdornment: (
                          //     <InputAdornment position="start">
                          //       <SvgIcon fontSize="small" color="action">
                          //         <SearchIcon />
                          //       </SvgIcon>
                          //     </InputAdornment>
                          //   ),
                          // }}
                          onChange={(e) => setSearchKey(e.currentTarget.value)}
                          sx={{
                            width: '20%',
                            borderRadius: '3px',
                            backgroundColor: '#fff',
                            '&  .MuiInputBase-root': {
                              borderRadius: '3px',
                            },
                            '& input': {
                              fontSize: '14px',
                            },
                          }}
                          required
                          fullWidth
                          focused
                        />
                      </Box>
                    )}
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                      <Typography
                        sx={{
                          color: '#4a494a',
                          fontWeight: '600',
                          fontSize: '14px',
                          marginRight: '20px',
                        }}
                      >
                        Period:{' '}
                      </Typography>
                      <DatePicker
                        format="YYYY-MM-DD"
                        value={moment(startDate) || ''}
                        onChange={(value) => setStartDate(moment(value).format('YYYY-MM-DD'))}
                        sx={{
                          '& input': {
                            padding: '8.5px 14px',
                            fontSize: '14px',
                          },
                          width: '20%',

                          backgroundColor: '#fff',
                          '& .MuiInputBase-root': {
                            borderRadius: '3px',
                          },
                          '& svg': {
                            width: '16px',
                            height: '16px',
                          },
                        }}
                      />
                      {/* <TextField
                        value={startDate || ''}
                        type="date"
                        variant="outlined"
                        size="small"
                        onChange={(e) => {
                          setStartDate(e.currentTarget.value)
                        }}
                        sx={{
                          width: '20%',
                          borderRadius: '3px',
                          backgroundColor: '#fff',
                          '&  .MuiInputBase-root': {
                            borderRadius: '3px',
                          },
                          '& input': {
                            fontSize: '14px',
                          },
                        }}
                        fullWidth
                        focused
                      /> */}

                      <Typography
                        variant="body1"
                        sx={{
                          fontSize: '1.15rem',
                          textAlign: 'center',
                          fontWeight: 'bold',
                          color: '#4a494a',
                        }}
                      >
                        ~
                      </Typography>

                      <DatePicker
                        format="YYYY-MM-DD"
                        value={moment(endDate) || ''}
                        onChange={(value) => setEndDate(moment(value).format('YYYY-MM-DD'))}
                        sx={{
                          '& input': {
                            padding: '8.5px 14px',
                            fontSize: '14px',
                          },
                          width: '20%',

                          backgroundColor: '#fff',
                          '& .MuiInputBase-root': {
                            borderRadius: '3px',
                          },
                          '& svg': {
                            width: '16px',
                            height: '16px',
                          },
                        }}
                      />
                      {/* <TextField
                        type="date"
                        variant="outlined"
                        size="small"
                        value={endDate || ''}
                        onChange={(e) => setEndDate(e.currentTarget.value)}
                        sx={{
                          width: '20%',
                          borderRadius: '3px',
                          backgroundColor: '#fff',
                          '& input': {
                            fontSize: '14px',
                          },
                          '&  .MuiInputBase-root': {
                            borderRadius: '3px',
                          },
                        }}
                        fullWidth
                        focused
                      /> */}

                      <Button
                        variant="contained"
                        color="secondary"
                        sx={{
                          fontWeight: '500',
                          color: 'white',
                          fontSize: '14px',
                          borderRadius: '4px',
                          backgroundColor: 'secondary.main',
                          textTransform: 'none',
                          padding: '6px 12px',
                          height: 34,
                        }}
                        onClick={() => searchByDate(searchKey, startDate!, endDate!)}
                      >
                        Search
                      </Button>

                      <Button
                        variant="contained"
                        color="info"
                        sx={{
                          fontWeight: '500',
                          color: 'white',
                          fontSize: '14px',
                          borderRadius: '4px',
                          textTransform: 'none',
                          padding: '6px 12px',
                          height: 34,
                        }}
                        onClick={() => {
                          handleReset()
                        }}
                      >
                        Reset
                      </Button>
                    </Box>
                  </Box>

                  {displaySwitch && barHeading !== 'Reports' && (
                    <Stack direction="row" sx={{ alignItems: 'center', mb: 3 }}>
                      <Typography variant="body1" sx={{ fontSize: '1.15rem', mr: { md: '17px' } }}>
                        Display zero data account:
                      </Typography>

                      <Switch {...label} onChange={(e) => handleToggleSwitch(e)} />
                    </Stack>
                  )}

                  <Stack
                    direction={{ xs: 'row' }}
                    sx={{
                      display: 'flex',
                      gap: '5px',
                      marginLeft: '80px',
                      justifyContent: { lg: 'flex-start' },
                      maxWidth: { xs: '100%', sm: '100%' },
                      '& .MuiChip-root': {
                        fontWeight: '500',
                        color: 'white',
                        fontSize: '14px',
                        borderRadius: '4px',
                        backgroundColor: 'secondary.main',
                        textTransform: 'none',
                        padding: '6px 12px',
                        height: '34px',
                        '&:hover': {
                          backgroundColor: '#0382b7 !important',
                          opacity: 0.8,
                        },
                        '& .MuiChip-label': {
                          paddingLeft: '0px',
                          paddingRight: '0px',
                        },
                      },
                    }}
                  >
                    <Chip
                      label="Today"
                      onClick={() => searchByDate(searchKey!, today, today, 'today')}
                      color={searchShortcut === 'today' ? 'primary' : 'default'}
                      variant={searchShortcut === 'today' ? 'filled' : 'outlined'}
                    />

                    <Chip
                      label="Yesterday"
                      onClick={() => searchByDate(searchKey!, yesterday, yesterday, 'yesterday')}
                      color={searchShortcut === 'yesterday' ? 'primary' : 'default'}
                      variant={searchShortcut === 'yesterday' ? 'filled' : 'outlined'}
                    />

                    <Chip
                      label="This week"
                      onClick={() =>
                        searchByDate(searchKey!, thisWeekSunday, thisWeekSaturday, 'current-week')
                      }
                      color={searchShortcut === 'current-week' ? 'primary' : 'default'}
                      variant={searchShortcut === 'current-week' ? 'filled' : 'outlined'}
                    />

                    <Chip
                      label="Last week"
                      onClick={() =>
                        searchByDate(
                          searchKey!,
                          previousWeekSunday,
                          previousWeekSaturday,
                          'last-week'
                        )
                      }
                      color={searchShortcut === 'last-week' ? 'primary' : 'default'}
                      variant={searchShortcut === 'last-week' ? 'filled' : 'outlined'}
                    />
                  </Stack>
                </CardContent>
              </Card>
            </Box>
          </Box>
        </ScaleContainer>
      </DemoContainer>
    </Fragment>
  )
}

export default FilterBar
