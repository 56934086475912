import * as React from 'react'
import Grid from '@mui/material/Grid'
import Container from '@mui/material/Container'
import Typography from '@mui/material/Typography'
import Stack from '@mui/material/Stack'
import TextField from '@mui/material/TextField'
import FormControl from '@mui/material/FormControl'
import FormControlLabel from '@mui/material/FormControlLabel'
import RadioGroup from '@mui/material/RadioGroup'
import Box from '@mui/material/Box'
import Radio from '@mui/material/Radio'
import PageTitle from 'app/dashboard/components/PageTitle'
import LoadingButton from '@mui/lab/LoadingButton'
import { useSelector, useDispatch } from 'react-redux'
import { RootState } from 'stores'
import { adjustJpScore } from 'stores/jp/jp-actions'

export default function JpSettings(): JSX.Element {
  const { userInfo } = useSelector((state: RootState) => state.user)
  const { jpScore } = useSelector((state: RootState) => state.jp)
  const { isLoading } = useSelector((state: RootState) => state.ui)
  const [formData, setFormData] = React.useState({
    accumulatedGrandJP: 1500,
    accumulatedMajorJP: 500,
    accumulatedMinorJP: 100,
    accumulatedMiniJP: 20,
  })
  const dispatch = useDispatch()

  // React.useEffect(() => {
  //   dispatch(getJpScore())
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [])

  React.useEffect(() => {
    if (jpScore) {
      setFormData({
        ...formData,
        accumulatedGrandJP: jpScore.accumulatedGrandJP,
        accumulatedMajorJP: jpScore.accumulatedMajorJP,
        accumulatedMinorJP: jpScore.accumulatedMinorJP,
        accumulatedMiniJP: jpScore.accumulatedMiniJP,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jpScore])

  const handleCheck = (e: React.ChangeEvent<HTMLInputElement>) => {
    console.log(e.target.defaultValue)
  }

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault()
    dispatch(adjustJpScore(formData))
  }

  return (
    <React.Fragment>
      <PageTitle pageTitle="JP Settings" />

      <Container
        maxWidth={false}
        sx={{
          mb: 1,
          border: '2px solid #ccc',
          borderRadius: '4px',
          backgroundColor: 'background.default',
          padding: '12px',
        }}
      >
        <Typography
          sx={{
            ml: '10px',
            mb: '20px',
            borderBottom: '1px solid #ded9d9',
            paddingBottom: '5px',
            paddingTop: '6px',
            display: 'flex',
            justifyContent: 'space-between',
          }}
          variant="h4"
          color="secondary.main"
          fontSize={'16px'}
          fontWeight={'600'}
        >
          JP Settings
        </Typography>

        <Stack component="form" autoComplete="off" onSubmit={handleSubmit}>
          <Stack
            spacing={2}
            sx={{
              border: '2px solid #ccc',
              borderRadius: '4px',
              padding: '8px',
              '& .MuiOutlinedInput-notchedOutline': {
                borderColor: '#7F9DB9 !important',
                borderRadius: '4px',
              },
              '& .MuiFormControl-root': {
                flexDirection: 'row',
                gap: '10px',
                width: '84%',
              },
              '&  .MuiInputBase-root': {
                borderRadius: '3px',
                borderColor: 'red',
              },
              '& input': {
                width: '156px',
              },
              '& input, .MuiSelect-select': {
                background: '#fff',
                fontSize: '12px',
                height: '22px',
                padding: '0px 5px',
              },
              '& .MuiInputBase-root': {
                background: '#fff',
                maxHeight: '22px',
              },
              '& .Mui-disabled input': {
                background: '#e5e5e5',
              },
              '& .MuiSelect-select': {
                width: '128px',
              },
              '& .MuiFormHelperText-root': {
                fontSize: '13px',
                fontFamily: 'sans-serif',
                letterSpacing: 'normal',
                color: '#000 !important',
                lineHeight: 'unset',
                marginLeft: '0px',
                marginRight: '0px',
              },
            }}
          >
            <Box
              sx={{
                display: 'flex',
                gap: '12px',
              }}
            >
              <Typography
                sx={{
                  color: '#4a494a',
                  fontWeight: '600',
                  fontSize: '12px',
                  width: '16%',
                  textAlign: 'right',
                }}
              >
                Account:
              </Typography>
              <TextField
                disabled
                InputProps={{
                  readOnly: true,
                }}
                value={userInfo?.account || ''}
                variant="outlined"
                size="small"
                helperText="Tips: Once the parameters are edited, the accumulated amount on the Jackpot will be recalculated"
                color="info"
                focused
              />
            </Box>
            <Box
              sx={{
                display: 'flex',
                gap: '12px',
              }}
            >
              <Typography
                sx={{
                  color: '#4a494a',
                  fontWeight: '600',
                  fontSize: '12px',
                  width: '16%',
                  textAlign: 'right',
                }}
              >
                Accumulated Grand JP:
              </Typography>

              <TextField
                type="number"
                variant="outlined"
                value={jpScore.accumulatedGrandJP || 0}
                InputProps={{
                  readOnly: true,
                }}
                size="small"
                disabled
                color="info"
                focused
              />
            </Box>
            <Box
              sx={{
                display: 'flex',
                gap: '12px',
              }}
            >
              <Typography
                sx={{
                  color: '#4a494a',
                  fontWeight: '600',
                  fontSize: '12px',
                  width: '16%',
                  textAlign: 'right',
                }}
              >
                Accumulated Major JP:
              </Typography>

              <TextField
                type="number"
                variant="outlined"
                size="small"
                InputProps={{
                  readOnly: true,
                }}
                value={jpScore.accumulatedMajorJP || 0}
                disabled
                color="info"
                focused
              />
            </Box>

            <Box
              sx={{
                display: 'flex',
                gap: '12px',
              }}
            >
              <Typography
                sx={{
                  color: '#4a494a',
                  fontWeight: '600',
                  fontSize: '12px',
                  width: '16%',
                  textAlign: 'right',
                }}
              >
                Accumulated Minor JP:
              </Typography>
              <TextField
                type="number"
                variant="outlined"
                size="small"
                InputProps={{
                  readOnly: true,
                }}
                value={jpScore.accumulatedMinorJP || 0}
                disabled
                color="info"
                focused
              />
            </Box>
            <Box
              sx={{
                display: 'flex',
                gap: '12px',
              }}
            >
              <Typography
                sx={{
                  color: '#4a494a',
                  fontWeight: '600',
                  fontSize: '12px',
                  width: '16%',
                  textAlign: 'right',
                }}
              >
                Accumulated Mini JP:
              </Typography>

              <TextField
                type="number"
                variant="outlined"
                size="small"
                InputProps={{
                  readOnly: true,
                }}
                value={jpScore.accumulatedMiniJP || 0}
                disabled
                color="info"
                focused
              />
            </Box>

            <Box
              sx={{
                display: 'flex',
                gap: '12px',
              }}
            >
              <Typography
                sx={{
                  color: '#4a494a',
                  fontWeight: '600',
                  fontSize: '12px',
                  width: '16%',
                  textAlign: 'right',
                }}
              >
                Grand Score:
              </Typography>
              <TextField
                type="number"
                variant="outlined"
                size="small"
                value={formData.accumulatedGrandJP || 1500}
                helperText="Range 1500 to 5000"
                inputProps={{
                  min: 1500,
                  max: 5000,
                }}
                required
                color="info"
                focused
                onChange={(e) =>
                  setFormData({ ...formData, accumulatedGrandJP: +e.currentTarget.value })
                }
              />
            </Box>

            <Box
              sx={{
                display: 'flex',
                gap: '12px',
              }}
            >
              <Typography
                sx={{
                  color: '#4a494a',
                  fontWeight: '600',
                  fontSize: '12px',
                  width: '16%',
                  textAlign: 'right',
                }}
              >
                Major Score:
              </Typography>
              <TextField
                type="number"
                variant="outlined"
                size="small"
                value={formData.accumulatedMajorJP || 500}
                helperText="Range 500 to 2500"
                inputProps={{
                  min: 500,
                  max: 2500,
                }}
                required
                color="info"
                focused
                onChange={(e) =>
                  setFormData({ ...formData, accumulatedMajorJP: +e.currentTarget.value })
                }
              />
            </Box>

            <Box
              sx={{
                display: 'flex',
                gap: '12px',
              }}
            >
              <Typography
                sx={{
                  color: '#4a494a',
                  fontWeight: '600',
                  fontSize: '12px',
                  width: '16%',
                  textAlign: 'right',
                }}
              >
                Minor Score:
              </Typography>
              <TextField
                type="number"
                variant="outlined"
                size="small"
                value={formData.accumulatedMinorJP || 100}
                helperText="Range 100 to 200"
                inputProps={{
                  min: 100,
                  max: 200,
                }}
                required
                color="info"
                focused
                onChange={(e) =>
                  setFormData({ ...formData, accumulatedMinorJP: +e.currentTarget.value })
                }
              />
            </Box>
            <Box
              sx={{
                display: 'flex',
                gap: '12px',
              }}
            >
              <Typography
                sx={{
                  color: '#4a494a',
                  fontWeight: '600',
                  fontSize: '12px',
                  width: '16%',
                  textAlign: 'right',
                }}
              >
                Mini Score:
              </Typography>

              <TextField
                type="number"
                variant="outlined"
                size="small"
                value={formData.accumulatedMiniJP || 20}
                helperText="Range 20 to 50"
                inputProps={{
                  min: 20,
                  max: 50,
                }}
                required
                color="info"
                focused
                onChange={(e) =>
                  setFormData({ ...formData, accumulatedMiniJP: +e.currentTarget.value })
                }
              />
            </Box>
            <Box
              sx={{
                display: 'flex',
                gap: '12px',
              }}
            >
              <Typography
                sx={{
                  color: '#4a494a',
                  fontWeight: '600',
                  fontSize: '12px',
                  width: '16%',
                  textAlign: 'right',
                }}
              >
                MiniShare Num:
              </Typography>
              <TextField
                type="number"
                variant="outlined"
                size="small"
                defaultValue={1}
                color="info"
                focused
                helperText="Range 1 to 6"
                inputProps={{
                  min: 1,
                  max: 6,
                }}
              />
            </Box>
            <Box
              sx={{
                display: 'flex',
                gap: '12px',
              }}
            >
              <Typography
                sx={{
                  color: '#4a494a',
                  fontWeight: '600',
                  fontSize: '12px',
                  width: '16%',
                  textAlign: 'right',
                }}
              >
                MaxShare Num:
              </Typography>

              <TextField
                type="number"
                variant="outlined"
                size="small"
                defaultValue={7}
                color="info"
                focused
                helperText="Range 7 to 10"
                inputProps={{
                  min: 7,
                  max: 10,
                }}
              />
            </Box>
            <Box
              sx={{
                display: 'flex',
                gap: '12px',
                alignItems: 'center',
                '& .MuiButtonBase-root': {
                  paddingTop: '0px',
                  paddingBottom: '0px',
                  paddingRight: '2px',
                },
                '& .MuiTypography-root': {
                  color: '#4a494a',
                  fontWeight: '600',
                  fontSize: '12px',
                },
              }}
            >
              <Typography
                sx={{
                  color: '#4a494a',
                  fontWeight: '600',
                  fontSize: '12px',
                  width: '16%',
                  textAlign: 'right',
                }}
              >
                Dollar Open Status:
              </Typography>

              <FormControl>
                <RadioGroup row onChange={handleCheck}>
                  <FormControlLabel
                    value="open"
                    sx={{ color: 'text.primary' }}
                    control={<Radio />}
                    label="Open"
                  />

                  <FormControlLabel
                    value="close"
                    sx={{ color: 'text.primary' }}
                    control={<Radio />}
                    label="Close"
                  />
                </RadioGroup>
              </FormControl>
            </Box>
            <LoadingButton
              loading={isLoading?.usedFor === 'jp' && isLoading.status}
              variant="contained"
              sx={{
                borderRadius: '4px',
                width: 'fit-content',
                marginLeft: 'calc(14% + 12px) !important',
                fontSize: 14,
                height: 30,
                textTransform: 'capitalize',
                padding: ' 0px 6px',
                backgroundColor: '#0382b7',
              }}
              type="submit"
              color="secondary"
            >
              Save
            </LoadingButton>
          </Stack>
        </Stack>
      </Container>
    </React.Fragment>
  )
}
