import * as React from 'react'
import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'
import { ExcelExportIcon } from './SvgIcons'
import { CSVLink } from 'react-csv'
import { v4 as uuid } from 'uuid'
import { Button } from '@mui/material'

interface ExportBtnProps {
  data: Array<any>
  title: string
  headers?: { label: string; key: string }[]
  icon?: boolean
}

const ExportBtn: React.FC<ExportBtnProps> = ({ data, title, headers, icon }) => {
  return (
    <CSVLink
      data={data.length ? data : []}
      headers={headers && headers}
      filename={`${title}-${uuid()}.csv`}
      style={{ height: 'fit-content' }}
    >
      <Tooltip title="Export as CSV" arrow>
        {icon ? (
          <ExcelExportIcon />
        ) : (
          <Button
            variant="contained"
            sx={{
              height: '100%',
              fontWeight: '500',
              textTransform: 'none',
              padding: '6px 12px',
              borderRadius: '4px',
              backgroundColor: 'secondary.main',
            }}
          >
            Export
          </Button>
        )}
      </Tooltip>
    </CSVLink>
  )
}

export default ExportBtn
