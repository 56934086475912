import { FC, ElementType } from 'react'
import { NavLink as RouterLink, matchPath, useLocation } from 'react-router-dom'
import { Button, ListItem } from '@mui/material'

interface NavItemProps {
  href: string
  title: string
  icon: ElementType
  onClick?: any
}

const NavItem: FC<NavItemProps> = ({ href, icon: Icon, title, ...rest }) => {
  const location = useLocation()

  const active = href
    ? !!matchPath(
        {
          path: href,
          end: false,
        },
        location.pathname
      )
    : false

  return (
    <ListItem
      disableGutters
      sx={{
        display: 'flex',
        py: 0,
      }}
      {...rest}
    >
      <Button
        component={RouterLink}
        sx={{
          color: 'text.primary',
          justifyContent: 'flex-start',
          letterSpacing: 0,
          borderRadius: 0,
          fontWeight: 600,
          textTransform: 'none',
          width: '100%',
          padding: '0px 10px',
          borderBottom: '1px solid #ccc',
          height: 40,
          '&:hover': {
            backgroundColor: 'text.secondary',
          },
          ...(active && {
            color: 'primary.light',
            backgroundColor: '#3398cc !important',
          }),

          '& svg': {
            mr: 1,
          },
        }}
        to={href}
      >
        {Icon && <Icon size="20" />}
        <span style={{ margin: '3.5px 0 0' }}>{title}</span>
      </Button>
    </ListItem>
  )
}

export default NavItem
