import React, { FormEvent, Fragment, useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Box, Container, Typography, Stack, TextField } from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton'
import BackButton from 'app/dashboard/components/BackButton'
import PageTitle from 'app/dashboard/components/PageTitle'
import { createCategory, updateCategory } from 'stores/game/game-category-actions'
import { RootState } from 'stores'
import { useParams } from 'react-router-dom'

export interface IActionType {
  actionType?: 'add' | 'edit'
}

const CreateCategory: React.FC<IActionType> = ({ actionType = 'add' }) => {
  const [category, setCategory] = useState<string>()
  const { gameCategory } = useSelector((state: RootState) => state.category)
  const { isLoading } = useSelector((state: RootState) => state.ui)
  const dispatch = useDispatch()
  const { id } = useParams()

  useEffect(() => {
    if (gameCategory.length && actionType === 'edit' && id?.length) {
      const [categoryName] = gameCategory.filter((category) => category._id === id)
      setCategory(categoryName.name)
    }
  }, [actionType, gameCategory, id])

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault()
    if (category) {
      if (actionType === 'edit' && id) {
        dispatch(updateCategory({ name: category, catId: id }))
      } else {
        dispatch(createCategory(category))
      }
      setTimeout(() => {
        setCategory('')
      }, 1000)
    }
  }

  return (
    <Fragment>
      <PageTitle
        pageTitle={`${
          actionType === 'add' ? 'Create Category' : 'Edit Category'
        } | Galaxy GamesGroup`}
      />
      <Container maxWidth={false}>
        <BackButton href="/app/manage/category" />

        <Box
          component="form"
          sx={{
            mx: 'auto',
            my: { xs: 3, md: 8 },
            p: 3,
            borderRadius:'16px',
            maxWidth: '400px',
            backgroundColor: 'background.paper',
            boxShadow: 24,
            '& .MuiFormControl-root': {
              my: 2,
            },
            '& .MuiOutlinedInput-root': {
              borderRadius: '16px',
              border: 'none !important',

              '& .MuiOutlinedInput-notchedOutline': {
                borderWidth: '1px !important',
                borderColor: 'grey !important',
              },
            },
          }}
          onSubmit={handleSubmit}
        >
          <Typography variant="h4" mb={2} color="textPrimary">
            {actionType === 'edit' ? 'Update the Category' : 'Create New Category'}
          </Typography>
          <Stack spacing={2}>
            <TextField
              variant="outlined"
              label="Category Name"
              size="small"
              value={category || ''}
              onChange={(e) => setCategory(e.currentTarget.value)}
              required
              color="info"
              focused
            />

            <LoadingButton
              loading={isLoading?.usedFor === 'game' && isLoading.status}
              type="submit"
              variant="contained"
              color="secondary"
              sx={{ '&.MuiLoadingButton-root ': { mt: 4 }, color: 'white', fontWeight: 'bold' }}
              fullWidth
            >
              Save
            </LoadingButton>
          </Stack>
        </Box>
      </Container>
    </Fragment>
  )
}

export default CreateCategory
