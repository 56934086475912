import { useEffect, useState } from 'react'
import { Typography, Grid, Box, FormControl, Select, MenuItem } from '@mui/material'
import { KeyboardArrowUp, KeyboardArrowDown } from '@mui/icons-material'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'stores'
import {
  MonetizationOn,
  EvStation,
  Redeem,
  AccountBalance,
  Percent,
  TransferWithinAStation,
} from '@mui/icons-material'
import CardStats from 'app/dashboard/components/card-statistics'
import { getDashboardOverview } from 'stores/dashboard/dashboard-action'

enum DateFilters {
  WEEK = 'WEEK',
  L3MONTH = '3MONTH',
  MONTH = 'MONTH',
  L6MONTH = '6MONTH',
  YEAR = 'YEAR',
}

const Overview = () => {
  const dispatch = useDispatch()
  const [defaultSelectDate, setDefaultSelectDate] = useState<DateFilters | string>(DateFilters.WEEK)
  const { currentOverviewDashboard } = useSelector((state: RootState) => state.dashboard)
  const { previousOverviewDashboard } = useSelector((state: RootState) => state.dashboard)

  const trendNumberCalculation = (current: number, previous: number) => {
    if (current > previous) {
      return (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <KeyboardArrowUp sx={{ color: 'success.main' }} />
          <Typography variant="body2" color="success.main">
            + {current - previous}%
          </Typography>
        </Box>
      )
    } else if (current < previous) {
      return (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <KeyboardArrowDown sx={{ color: 'error.main' }} />
          <Typography variant="body2" color="error.main">
            - {current - previous}%
          </Typography>
        </Box>
      )
    } else {
      return (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography variant="body2" color="text.secondary">
            0%
          </Typography>
        </Box>
      )
    }
  }

  const changeSubtitle = (date: DateFilters | string) => {
    if (date === DateFilters.WEEK) {
      return 'Since last week'
    } else if (date === DateFilters.MONTH) {
      return 'Since last month'
    } else if (date === DateFilters.L3MONTH) {
      return 'Since last quarter'
    } else if (date === DateFilters.L6MONTH) {
      return 'Since last half'
    } else if (date === DateFilters.YEAR) {
      return 'Since last year'
    }
    return ''
  }

  useEffect(() => {
    if (defaultSelectDate === DateFilters.WEEK) {
      dispatch(getDashboardOverview('WEEK'))
    } else if (defaultSelectDate === DateFilters.MONTH) {
      dispatch(getDashboardOverview('MONTH'))
    } else if (defaultSelectDate === DateFilters.YEAR) {
      dispatch(getDashboardOverview('YEAR'))
    } else if (defaultSelectDate === DateFilters.L3MONTH) {
      dispatch(getDashboardOverview('MONTH', 3))
    } else if (defaultSelectDate === DateFilters.L6MONTH) {
      dispatch(getDashboardOverview('MONTH', 6))
    }
  }, [defaultSelectDate, dispatch])

  return (
    <Box
      sx={{
        border: '2px solid #ccc',
        padding: '12px',
        borderRadius: '4px',
        backgroundColor: 'background.default',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'baseline',
          borderBottom: '1px solid #ded9d9',
          paddingBottom: '10px',
          paddingTop: '6px',
          mb: '20px',
        }}
      >
        <Typography
          sx={{
            ml: '20px',

            width: '100%',
          }}
          variant="h4"
          color="secondary.main"
          fontSize={'16px'}
          fontWeight={'600'}
        >
          Overview
        </Typography>

        <FormControl sx={{ minWidth: '200px', ml: 5 }}>
          <Select
            displayEmpty
            value={defaultSelectDate}
            onChange={(e) => setDefaultSelectDate(e.target.value)}
            sx={{
              borderRadius: '4px',
              backgroundColor: '#fff',
              '& .MuiSelect-select': {
                padding: '8px 12px',
              },
            }}
          >
            <MenuItem value={DateFilters.WEEK}>This week</MenuItem>
            <MenuItem value={DateFilters.MONTH}>This month</MenuItem>
            <MenuItem value={DateFilters.L3MONTH}>Last quarter</MenuItem>
            <MenuItem value={DateFilters.L6MONTH}>Last half</MenuItem>
            <MenuItem value={DateFilters.YEAR}>This year</MenuItem>
          </Select>
        </FormControl>
      </Box>

      <Grid
        container
        spacing={1}
        sx={{ mb: 1, marginLeft: '0px', width: '100%' }}
        key={defaultSelectDate}
      >
        <Grid item md={4} lg={2}>
          <CardStats
            title="Total Revenue"
            stats={currentOverviewDashboard?.totalRevenue}
            icon={<MonetizationOn />}
            subtitle={changeSubtitle(defaultSelectDate)}
            color="success"
            trendNumber={trendNumberCalculation(
              currentOverviewDashboard?.totalRevenue,
              previousOverviewDashboard?.totalRevenue
            )}
          />
        </Grid>
        <Grid item md={4} lg={2}>
          <CardStats
            title="Total Recharge"
            stats={currentOverviewDashboard?.totalRecharge}
            icon={<EvStation />}
            subtitle={changeSubtitle(defaultSelectDate)}
            color="success"
            trendNumber={trendNumberCalculation(
              currentOverviewDashboard?.totalRecharge,
              previousOverviewDashboard?.totalRecharge
            )}
          />
        </Grid>
        <Grid item md={4} lg={2}>
          <CardStats
            title="Total Redeem"
            stats={currentOverviewDashboard?.totalRedeem}
            icon={<Redeem />}
            subtitle={changeSubtitle(defaultSelectDate)}
            color="warning"
            trendNumber={trendNumberCalculation(
              currentOverviewDashboard?.totalRedeem,
              previousOverviewDashboard?.totalRedeem
            )}
          />
        </Grid>
        <Grid item md={4} lg={2}>
          <CardStats
            title="Total Holdings"
            stats={currentOverviewDashboard?.totalHoldings}
            icon={<AccountBalance />}
            subtitle={changeSubtitle(defaultSelectDate)}
            color="error"
            trendNumber={trendNumberCalculation(
              currentOverviewDashboard?.totalHoldings,
              previousOverviewDashboard?.totalHoldings
            )}
          />
        </Grid>
        <Grid item md={4} lg={2}>
          <CardStats
            title="Held Percent"
            stats={currentOverviewDashboard?.holdingPercentage}
            icon={<Percent />}
            subtitle={changeSubtitle(defaultSelectDate)}
            color="warning"
            trendNumber={trendNumberCalculation(
              currentOverviewDashboard?.holdingPercentage,
              previousOverviewDashboard?.holdingPercentage
            )}
          />
        </Grid>
        <Grid item md={4} lg={2}>
          <CardStats
            title="Total Players"
            stats={currentOverviewDashboard?.totalPlayers}
            icon={<TransferWithinAStation />}
            subtitle={changeSubtitle(defaultSelectDate)}
            color="success"
            trendNumber={trendNumberCalculation(
              currentOverviewDashboard?.totalPlayers,
              previousOverviewDashboard?.totalPlayers
            )}
          />
        </Grid>
      </Grid>
    </Box>
  )
}

export default Overview
