//@ts-nocheck
import React from 'react'
import {
  Button,
  Card,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Skeleton,
} from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'
import { styled } from '@mui/material/styles'
import { tableCellClasses } from '@mui/material/TableCell'
import { DistributorInterface } from 'stores/distribution/distributor-slice'
import { removeDistributor } from 'stores/distribution/distributor-actions'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'stores'
import TablePagination from 'app/web/components/TablePagination'
import ScaleContainer from 'app/dashboard/components/ScaleContainer'

const tableCellOptions: string[] = ['Email', 'City', 'Phone', 'Message', 'Department', 'Actions']

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    fontWeight: 'bold',
    fontSize: 16,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    fontWeight: 600,
  },
}))

interface IDistributor {
  distributors: DistributorInterface[]
  handleChangeRowsPerPage: (e: any) => void
  handlePageChange: (e: any, n: number) => void
  paginationInfo: any
}

const Result: React.FC<IDistributor> = ({
  distributors,
  paginationInfo,
  handlePageChange,
  handleChangeRowsPerPage,
  ...rest
}) => {
  const { userList = {} as any } = useSelector((state: RootState) => state.user)
  const { isLoading } = useSelector((state: RootState) => state.ui)
  const dispatch = useDispatch()

  const handleDeleteDistributor = (distributorId: string) => {
    const confirm = window.confirm('Are you sure you want to delete?')
    if (!confirm) {
      return
    }
    dispatch(removeDistributor(distributorId))
  }

  return (
    <Card {...rest} sx={{ my: 2, mb: 3, borderRadius: '0px', boxShadow: 'none' }}>
      <ScaleContainer
        key={`${isLoading?.status}-${isLoading?.usedFor}-${userList.length}`}
        parentId="manage-distributor-table-container"
        childId="manage-distributor-table"
        sx={{
          '& table': {
            '& th, td': {
              fontSize: 13,
            },
          },
        }}
      >
        <Table id="manage-distributor-table">
          <TableHead>
            <TableRow
              sx={{
                height: '31px',
                borderBottom: '1px solid #c7c7c7 !important',
              }}
            >
              {tableCellOptions.map((item, index) => (
                <StyledTableCell
                  sx={{
                    padding: 0,
                    backgroundColor: '#3398cc !important',
                    border: '1px solid #c7c7c7',
                  }}
                  key={index}
                  align={item === 'Actions' ? 'center' : 'inherit'}
                >
                  {item}
                </StyledTableCell>
              ))}
            </TableRow>
          </TableHead>

          {isLoading?.status && isLoading.usedFor === 'contact' ? (
            <TableBody>
              <TableRow>
                {tableCellOptions.map((item) => (
                  <TableCell key={item}>
                    {Array.from(new Array(5)).map((_, index) => (
                      <Skeleton key={index} height={72} animation="wave" />
                    ))}
                  </TableCell>
                ))}
              </TableRow>
            </TableBody>
          ) : (
            <TableBody>
              {distributors.map((distributor: DistributorInterface) => (
                <TableRow
                  hover
                  key={distributor._id}
                  sx={{
                    height: 'fit-content',
                    '& td': {
                      position: 'relative',
                      padding: '6px',
                      color: '#000',
                      cursor: 'pointer',
                      border: '1px solid #c7c7c7',
                    },
                    '& td p': {
                      color: '#000',
                    },
                    '&:nth-of-type(even)': {
                      bgcolor: 'background.default',
                    },
                    '&:nth-of-type(odd)': {
                      bgcolor: 'background.paper',
                    },
                  }}
                >
                  <TableCell>{distributor.email}</TableCell>

                  <TableCell>{distributor.city}</TableCell>

                  <TableCell>{distributor.phone}</TableCell>

                  <TableCell width={300}>{distributor.message}</TableCell>

                  <TableCell>{distributor.department}</TableCell>

                  <TableCell size="small" width={100}>
                    <Stack
                      direction="row"
                      spacing={2}
                      sx={{
                        justifyContent: 'flex-end',
                      }}
                    >
                      <Button
                        variant="outlined"
                        color="error"
                        startIcon={<DeleteIcon />}
                        size="small"
                        onClick={() => handleDeleteDistributor(distributor._id!)}
                      >
                        Delete
                      </Button>
                    </Stack>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          )}
        </Table>
      </ScaleContainer>

      <TablePagination
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        handlePageChange={handlePageChange}
        paginationInfo={paginationInfo}
      />
    </Card>
  )
}

export default Result
