import {
  Avatar,
  Box,
  Card,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from '@mui/material'
import { useSelector } from 'react-redux'
import { RootState } from 'stores'
import getInitials from 'app/dashboard/utils/getInitials'

const ActivePlayerManager = () => {
  const { totalActivePlayerManager } = useSelector((state: RootState) => state.dashboard)
  return (
    <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
      <Typography
        variant="h5"
        sx={{ mt: 3, mb: 2 }}
        color="secondary.main"
        fontSize={'16px'}
        fontWeight={'600'}
      >
        Top Active Player Managers
      </Typography>
      <Card
        elevation={3}
        sx={{
          display: 'block',
          flex: 1,
          borderRadius: '4px',
          transitionDuration: '0.3s',
        }}
      >
        <List>
          {totalActivePlayerManager?.map((manager) => (
            <ListItem key={manager.managerId}>
              <ListItemAvatar>
                <Avatar
                  alt="Person"
                  src=""
                  sx={{
                    width: 38,
                    height: 38,
                    backgroundColor: 'primary.main',
                  }}
                >
                  {getInitials(manager.managerName)}
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={manager.managerName}
                sx={{
                  '& .MuiTypography-root': {
                    color: 'text.primary',
                  },
                  '& .MuiTypography-body1': {
                    fontWeight: 'bold',
                  },
                }}
              />
              <Typography variant="body2" color="text.primary">
                {manager.totalCount}
              </Typography>
            </ListItem>
          ))}
        </List>
      </Card>
    </Box>
  )
}

export default ActivePlayerManager
