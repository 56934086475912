import React, { Fragment } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from 'stores'
import { Box, Button, Typography } from '@mui/material'
import { Link } from 'react-router-dom'
import ExportBtn from 'app/dashboard/components/ExportBtn'
import IssueSearch from './IssueSearch'
import { UserRoleEnum } from 'stores/auth/auth-slice'

interface ToolbarProps {
  handleSubmit: (searchText: string) => void
}

const Toolbar: React.FC<ToolbarProps> = (props) => {
  const { issuesList = {} as any } = useSelector((state: RootState) => state.issue)
  const { userInfo } = useSelector((state: RootState) => state.user)
  const isSuperAdmin = [UserRoleEnum.SUPERADMIN].includes(userInfo?.role as UserRoleEnum)

  return (
    <Fragment>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography
            sx={{
              ml: '20px',
              mb: '6px',
              borderBottom: '1px solid #ded9d9',
              width: '100%',
              paddingBottom: '5px',
              paddingTop: '6px',
            }}
            variant="h4"
            color="secondary.main"
            fontSize={'16px'}
            fontWeight={'600'}
          >
            Issue Reports
          </Typography>
        </Box>

        <Box
          sx={{
            mb: 1,
            display: 'flex',
            pr: 2,
            '@media (max-width:945px)': {
              flexDirection: 'column',
            },
          }}
        >
          {isSuperAdmin && <IssueSearch handleSubmit={props.handleSubmit} />}
          <Box
            sx={{
              display: 'flex',
              marginLeft: 'auto',
              gap: '10px',
              alignItems: 'center',
              '@media(max-width: 945px)': {
                marginLeft: '13px',
                flexWrap: 'wrap',
              },
            }}
          >
            {!isSuperAdmin && (
              <Link to="/app/issues/create">
                <Button
                  variant="contained"
                  sx={{
                    height: '100%',
                    fontWeight: '500',
                    color: 'white',
                    fontSize: '13px',
                    backgroundColor: '#722fee',
                    textTransform: 'none',
                    padding: '6px 12px',
                    borderRadius: '4px',
                  }}
                >
                  Create Report
                </Button>
              </Link>
            )}

            <ExportBtn icon data={issuesList as any} title="issue-manager" />
          </Box>
        </Box>
      </Box>
    </Fragment>
  )
}

export default Toolbar
