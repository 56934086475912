import { FC } from 'react'
import { Box, Container } from '@mui/material'
import PageTitle from 'app/dashboard/components/PageTitle'
import SettingsPage from './SettingsPage'

const Settings: FC = () => {
  return (
    <>
      <PageTitle pageTitle="Settings | Galaxy GamesGroup" />
      {/* <BackButton href="/app/dashboard" /> */}

      <SettingsPage />
    </>
  )
}

export default Settings
