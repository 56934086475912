import React, { FormEvent, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Typography, Stack, TextField, Container, Box } from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton'
import { IUserState } from 'stores/user/user-slice'
import { RootState } from 'stores/index'
import { resetCustomerPassword } from 'stores/user/user-actions'

interface ResetPasswordProps {
  customer: IUserState | undefined
  handleModalClose?: () => void
}

const ResetPassword = ({ customer, handleModalClose }: ResetPasswordProps): JSX.Element => {
  const [formData, setFormData] = useState({
    newPassword: '',
    confirmPassword: '',
  })

  const [diffConfirmPass, setDiffConfirmPass] = useState<boolean>(false)
  const [isPassValid, setIsPassValid] = useState<boolean>(true)
  const [passwordError, setPasswordError] = useState('')
  const { isLoading } = useSelector((state: RootState) => state.ui)
  const dispatch = useDispatch()

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    formKey: 'newPassword' | 'confirmPassword'
  ) => {
    setFormData({ ...formData, [formKey]: event.target.value })
  }

  const handleClick = (e: FormEvent) => {
    e.preventDefault()
    const { newPassword, confirmPassword } = formData
    const re = new RegExp(/[\W]/g)
    const inValidPass = re.test(newPassword) || re.test(confirmPassword) || newPassword.length === 0

    if (inValidPass) {
      setPasswordError('Letters, underscore & numbers')
      return setIsPassValid(false)
    }

    if (newPassword.length < 7) {
      setPasswordError('Password must be greater than 7')
      return setIsPassValid(false)
    }

    if (confirmPassword !== newPassword) {
      return setDiffConfirmPass(true)
    }

    dispatch(resetCustomerPassword(newPassword, customer?._id, handleModalClose))
  }

  const handleOnFocus = () => {
    setIsPassValid(true)
    setDiffConfirmPass(false)
  }

  return (
    <Container
      maxWidth={false}
      sx={{
        boxShadow: 'none',
      }}
    >
      <Typography
        sx={{
          borderBottom: '1px solid #ded9d9',
          width: '100%',
          paddingBottom: '10px',
          paddingTop: '6px',
          marginBottom: '20px',
        }}
        variant="h4"
        color="secondary.main"
        fontSize={'18px'}
        fontWeight={'600'}
      >
        Reset Password
      </Typography>
      <Stack spacing={3}>
        <Box
          sx={{
            display: 'flex',
            gap: '5px',
            flexDirection: 'column',
          }}
        >
          <Typography sx={{ color: '#4a494a', fontWeight: '600', fontSize: '14px' }}>
            ID:
          </Typography>
          <TextField
            variant="outlined"
            size="small"
            defaultValue={customer?._id}
            InputProps={{
              readOnly: true,
            }}
            sx={{
              backgroundColor: '#fff',
              '&  .MuiInputBase-root': {
                borderRadius: '3px',
              },
            }}
            focused
          />
        </Box>
        <Box
          sx={{
            display: 'flex',
            gap: '5px',
            flexDirection: 'column',
          }}
        >
          <Typography sx={{ color: '#4a494a', fontWeight: '600', fontSize: '14px' }}>
            Account:
          </Typography>
          <TextField
            variant="outlined"
            size="small"
            defaultValue={customer?.account}
            InputProps={{
              readOnly: true,
            }}
            focused
            sx={{
              backgroundColor: '#fff',
              '&  .MuiInputBase-root': {
                borderRadius: '3px',
              },
            }}
          />
        </Box>

        <Box
          sx={{
            display: 'flex',
            gap: '5px',
            flexDirection: 'column',
          }}
        >
          <Typography sx={{ color: '#4a494a', fontWeight: '600', fontSize: '14px' }}>
            New Password:
          </Typography>
          <TextField
            type="password"
            variant="outlined"
            error={!isPassValid}
            helperText={!isPassValid && passwordError}
            size="small"
            onChange={(e) => handleChange(e, 'newPassword')}
            onFocus={handleOnFocus}
            required
            sx={{
              backgroundColor: '#fff',
              '&  .MuiInputBase-root': {
                borderRadius: '3px',
              },
            }}
          />
        </Box>
        <Box
          sx={{
            display: 'flex',
            gap: '5px',
            flexDirection: 'column',
          }}
        >
          <Typography sx={{ color: '#4a494a', fontWeight: '600', fontSize: '14px' }}>
            Confirm Password:
          </Typography>
          <TextField
            type="password"
            variant="outlined"
            error={diffConfirmPass}
            helperText={diffConfirmPass && 'Confirm password do not match'}
            size="small"
            onChange={(e) => handleChange(e, 'confirmPassword')}
            onFocus={handleOnFocus}
            required
            sx={{
              backgroundColor: '#fff',
              '&  .MuiInputBase-root': {
                borderRadius: '3px',
              },
            }}
          />
        </Box>
        <LoadingButton
          loading={isLoading?.usedFor === 'userActions' && isLoading.status}
          variant="contained"
          onClick={handleClick}
          sx={{
            borderRadius: '6px',
            display: 'flex',
            marginLeft: 'auto !important',
            marginRight: 'auto !important',
            width: '200px',
          }}
        >
          Reset
        </LoadingButton>
      </Stack>
    </Container>
  )
}

export default ResetPassword
