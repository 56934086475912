// @ts-nocheck
import { useState, useEffect, Fragment } from 'react'
import { Autocomplete, Box, CircularProgress, TextField, Typography } from '@mui/material'
import { debounce } from 'utils'
import api from 'config/apiConfig'

const SearchGamgeIdField = ({ value, onChange }) => {
  const [open, setOpen] = useState(false)
  const [options, setOptions] = useState([])
  const [loading, setLoading] = useState(false)

  const fetch = async (value = '') => {
    if (!value) {
      setOptions([])
      return
    }
    setLoading(true)
    const { data } = await api.get(`/games?searchKey=${value}`)
    const options = data.data.map((item) => ({
      title: item.title,
      value: item._id,
    }))

    setOptions(options)
    setLoading(false)
  }

  useEffect(() => {
    fetch()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Autocomplete
      disablePortal
      open={open}
      onOpen={() => {
        setOpen(true)
      }}
      onClose={() => {
        setOpen(false)
      }}
      freeSolo
      value={value}
      getOptionLabel={(option) => option?.title || ''}
      // isOptionEqualToValue={(option, value) => option?.value === value?.value}
      options={options}
      noOptionsText={false}
      loading={loading}
      renderInput={(params) => (
        <Box
          sx={{
            display: 'flex',
            gap: '0px',
            alignItems: 'center',
            flexWrap: 'wrap',
          }}
        >
          <Typography sx={{ color: '#4a494a', fontWeight: '600', fontSize: '13px', flex: 1 }}>
            Game:
          </Typography>
          <TextField
            {...params}
            focused
            placeholder="Game Id / Name"
            sx={{
              flex: '5 !important',
              backgroundColor: '#fff',
              '&  .MuiInputBase-root': {
                borderRadius: '3px',
              },
              '& input': {
                fontSize: '14px',
              },
            }}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <Fragment>
                  {loading ? <CircularProgress color="inherit" size={20} /> : null}
                  {/* {params.InputProps.endAdornment} */}
                </Fragment>
              ),
            }}
          />
        </Box>
      )}
      onChange={(_, value) => {
        onChange(value)
      }}
      onInputChange={(e) => {
        if (e?.target?.value.length > 3) {
          debounce(() => fetch(e.target.value), 500)()
        }
      }}
      sx={{
        width: { lg: '100%', xs: '100%' },
        height: '100%',
        '& .MuiOutlinedInput-root': {
          padding: '1px 8px',
        },
      }}
    />
  )
}

export default SearchGamgeIdField
