import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface BurntReportInterface {
  _id: string;
  gameId: string;
  gameName: string;
  burntAmount: number;
  burntPercentage: number;
}

export interface IBurntReportOverViewState {
  totalRedeem: number;
  currentAmount: number;
  totalRevenue: number;
  totalBurntAmount: number;
  totalRecharge: number;
}

export interface IBurnReportDetails {
  results: BurntReportInterface[];
  paginationInfo?: any;
  handleChangeRowsPerPage: (e: any) => void;
  handlePageChange: (e: any, n: number) => void;
}

export interface IState {
  burntReportOverview: IBurntReportOverViewState,
  burntReportDetails: BurntReportInterface[]
}

const initialState: IState = { 
  burntReportOverview: {
    totalRedeem: 0,
    currentAmount: 0,
    totalRevenue: 0,
    totalBurntAmount: 0,
    totalRecharge: 0,
  },
  burntReportDetails: [],
}

const burntReportSlice = createSlice({
  name: 'burntReport',
  initialState: initialState,
  reducers: {
    setBurntReportOverview(state, action: PayloadAction<IBurntReportOverViewState>) {
      state.burntReportOverview = action.payload;
    },
    setBurntReportDetails(state, action: PayloadAction<BurntReportInterface[]>) {
      state.burntReportDetails = action.payload;
    },
  },
});

export const burntReportActions = burntReportSlice.actions;
export default burntReportSlice.reducer;
