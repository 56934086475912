//@ts-nocheck
import React, { Fragment } from 'react'
import { Box, Container, Typography } from '@mui/material'
import { useSelector } from 'react-redux'
import Result from './Results'
import { RootState } from 'stores'
// import { getDistributors } from 'stores/distribution/distributor-actions'
import DistributorToolBox from './DistributorToolBox'
import PageTitle from 'app/dashboard/components/PageTitle'
import ExportBtn from 'app/dashboard/components/ExportBtn'
import ScaleContainer from 'app/dashboard/components/ScaleContainer'

const Index: React.FC = () => {
  const { distributor } = useSelector((state: RootState) => state.distributor)
  const { isLoading } = useSelector((state: RootState) => state.ui)
  const { userList = {} as any } = useSelector((state: RootState) => state.user)
  // const dispatch = useDispatch()
  const [paginationInfo, setPaginationInfo] = React.useState({
    currentPage: 1,
    lastPage: false,
    pageSize: 10,
    totalPage: 0,
    totalRecord: 0,
  })

  const handlePageChange = (_event: any, newPage: number) => {
    setPaginationInfo((value) => ({
      ...value,
      currentPage: newPage,
    }))
  }

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPaginationInfo((value) => ({
      ...value,
      pageSize: +event.target.value,
    }))
  }

  // useEffect(() => {
  //   const fetch = async () => {
  //     const data = (await dispatch(
  //       getDistributors(paginationInfo?.currentPage, paginationInfo?.pageSize)
  //     )) as any
  //    data && setPaginationInfo(data)
  //   }

  //   fetch()
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [paginationInfo?.currentPage, paginationInfo?.pageSize])

  return (
    <Fragment>
      <PageTitle pageTitle="Manage Distributors | Galaxy GamesGroup" />

      <Container maxWidth={false} sx={{ padding: '0px !important' }}>
        <ScaleContainer
          key={`${isLoading?.status}--${isLoading?.usedFor}-${userList.length}`}
          parentId="manage-category-page-container"
          childId="manage-category-page-content"
        >
          <Box
            sx={{
              minWidth: 1200,
            }}
            id="manage-category-page-content"
          >
            <Box
              sx={{
                py: 2,
                backgroundColor: 'background.default',
                border: '2px solid #ccc',
                borderRadius: '4px',
                padding: '12px',
              }}
            >
              <DistributorToolBox />
            </Box>

            <Box
              sx={{
                backgroundColor: 'background.default',
                border: '2px solid #ccc',
                borderRadius: '4px',
                padding: '12px',
                mt: 2,
              }}
            >
              <Typography
                sx={{
                  ml: '10px',
                  paddingBottom: '5px',
                  borderBottom: '1px solid #ded9d9',
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  mb: 2,
                }}
                variant="h4"
                color="secondary.main"
                fontSize={'16px'}
                fontWeight={'600'}
              >
                Stores & Distributors
                <ExportBtn icon data={distributor} title="Store-Distributor" />
              </Typography>

              <Result
                distributors={distributor}
                handlePageChange={handlePageChange}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
                paginationInfo={paginationInfo}
              />

              <br />
            </Box>
          </Box>
        </ScaleContainer>
      </Container>
    </Fragment>
  )
}

export default Index
