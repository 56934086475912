import { useEffect, useState } from 'react'
import moment from 'moment'
import { Grid, Box, Typography, TextField } from '@mui/material'
import { useDispatch } from 'react-redux'
import ActivePlayerManager from './ActivePlayerManager'
import MaxHoldManagers from './MaxHoldManagers'
import TotalPlayerManager from './TotalPlayerManager'
import { getManagerList } from 'stores/dashboard/dashboard-action'

const AdminTable = () => {
  const dispatch = useDispatch()
  const startDayWeek = moment().startOf('week').format('YYYY-MM-DD')
  const endDayWeek = moment().endOf('week').format('YYYY-MM-DD')

  const [startDate, setStartDate] = useState(startDayWeek)
  const [endDate, setEndDate] = useState(endDayWeek)

  useEffect(() => {
    dispatch(getManagerList(startDate, endDate))
  }, [dispatch, startDate, endDate])

  return (
    <Grid
      item
      xs={12}
      sm={12}
      md={12}
      lg={12}
      sx={{
        backgroundColor: 'background.default',
        border: '2px solid #ccc',
        borderRadius: '4px',
        mt: 2,
        pt: '10px !important',
        pr: 1.5,
        pb: 2,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'center',
          borderBottom: '1px solid #ded9d9',
          pb: 1,
        }}
      >
        <Typography
          sx={{
            width: '100%',
          }}
          variant="h4"
          color="secondary.main"
          fontSize={'16px'}
          fontWeight={'600'}
        >
          Manager Wise Data
        </Typography>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            ml: 3,
            flexDirection: {
              xs: 'column',
              lg: 'row',
            },
          }}
        >
          <TextField
            id="date"
            type="date"
            defaultValue={startDate}
            InputLabelProps={{
              shrink: true,
            }}
            onChange={(e) => setStartDate(e.target.value)}
            sx={{
              width: '100%',
              borderRadius: '3px',
              mr: 1,

              backgroundColor: '#fff',
              '&  .MuiInputBase-root': {
                borderRadius: '3px',
              },
              '& input': {
                padding: '8px 12px',
              },
            }}
          />
          <TextField
            sx={{
              width: '100%',
              borderRadius: '3px',

              backgroundColor: '#fff',
              '&  .MuiInputBase-root': {
                borderRadius: '3px',
              },
              '& input': {
                padding: '8px 12px',
              },
            }}
            id="date"
            type="date"
            defaultValue={endDate}
            InputLabelProps={{
              shrink: true,
            }}
            onChange={(e) => setEndDate(e.target.value)}
          />
        </Box>
      </Box>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12} md={4} lg={4}>
          <ActivePlayerManager />
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4}>
          <TotalPlayerManager />
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4}>
          <MaxHoldManagers />
        </Grid>
      </Grid>
    </Grid>
  )
}

export default AdminTable
