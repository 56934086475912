import React, { FormEvent, Fragment, useEffect, useState } from 'react'
import {
  Typography,
  Box,
  Stack,
  TextField,
  FormControl,
  SelectChangeEvent,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
} from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton'
import { RootState } from 'stores'
import {
  createAnnouncement,
  getAnnouncement,
  updateAnnouncement,
} from 'stores/announcement/announcement-actions'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { IActionType } from 'app/dashboard/pages/category/AddNew'
import BackButton from 'app/dashboard/components/BackButton'

enum formEnum {
  mainTitle = 'mainTitle',
  subTitle = 'subTitle',
  mainContent = 'mainContent',
  footer = 'footer',
  openState = 'openState',
}

const AddNew: React.FC<IActionType> = ({ actionType = 'add' }) => {
  const [formData, setFormData] = useState<{
    mainTitle: string
    subTitle: string
    mainContent: string
    footer: string
    openState: 'open' | 'close'
  }>({
    mainTitle: '',
    subTitle: '',
    mainContent: '',
    footer: '',
    openState: 'open',
  })
  const { gameAnnouncement } = useSelector((state: RootState) => state.announcement)
  const { isLoading } = useSelector((state: RootState) => state.ui)
  const dispatch = useDispatch()
  const { id } = useParams()

  useEffect(() => {
    if (!gameAnnouncement.length) {
      dispatch(getAnnouncement())
    }

    if (gameAnnouncement.length && actionType === 'edit' && id) {
      const [targetAnnouncement] = gameAnnouncement.filter((a) => a._id === id)
      setFormData({
        mainTitle: targetAnnouncement.mainTitle,
        subTitle: targetAnnouncement.subTitle,
        mainContent: targetAnnouncement.mainContent,
        footer: targetAnnouncement.footer as string,
        openState: targetAnnouncement.openState,
      })
    }
  }, [actionType, dispatch, gameAnnouncement, id])

  const handleChange = (
    event: SelectChangeEvent | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    formKey: formEnum
  ) => {
    setFormData({ ...formData, [formKey]: (event.target as HTMLInputElement).value })
  }

  const handleSubmit = (event: FormEvent) => {
    event.preventDefault()

    if (actionType === 'edit') {
      dispatch(updateAnnouncement(formData, id as string))
    } else {
      dispatch(createAnnouncement(formData))
    }

    setTimeout(() => {
      setFormData({
        mainTitle: '',
        subTitle: '',
        mainContent: '',
        footer: '',
        openState: 'open',
      })
    }, 1000)
  }

  return (
    <Fragment>
      <Box
        component="form"
        sx={{
          mb: 3,
          py: 2,
          '& .MuiFormHelperText-root': { color: 'rgba(0, 0, 0, 0.55)', marginLeft: '0px' },
        }}
        onSubmit={handleSubmit}
      >
        <Typography
          sx={{
            borderBottom: '1px solid #ded9d9',
            width: '100%',
            paddingBottom: '10px',
            paddingTop: '6px',
            marginBottom: '20px',
            display: 'flex',
            alignItems: 'center',
          }}
          variant="h4"
          color="secondary.main"
          fontSize={'18px'}
          fontWeight={'600'}
        >
          <BackButton iconOnly href="/app/announcement" />{' '}
          {actionType === 'edit' ? 'Update Announcement' : 'Create New Announcement'}
        </Typography>

        <Stack
          spacing={2}
          sx={{
            border: '2px solid #ccc',
            borderRadius: '4px',
            padding: '8px',
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: '#7F9DB9 !important',
              borderRadius: '4px',
            },
            '& .MuiFormControl-root': {
              flexDirection: 'row',
              gap: '10px',
            },
            '&  .MuiInputBase-root': {
              borderRadius: '3px',
              borderColor: 'red',
            },
            '& input': {
              width: '156px',
            },
            '& input, .MuiSelect-select': {
              background: '#fff',
              fontSize: '12px',
              height: '22px',
              padding: '0px 5px',
            },
            '& .MuiSelect-select': {
              width: '128px',
            },
          }}
        >
          <Box
            sx={{
              display: 'flex',
              gap: '12px',
            }}
          >
            <Typography
              sx={{
                color: '#4a494a',
                fontWeight: '600',
                fontSize: '12px',
                width: '14%',
                textAlign: 'right',
              }}
            >
              Main Title
            </Typography>
            <TextField
              variant="outlined"
              size="small"
              value={formData.mainTitle}
              onChange={(e) => handleChange(e, formEnum.mainTitle)}
              required
              color="info"
              focused
            />
          </Box>
          <Box
            sx={{
              display: 'flex',
              gap: '12px',
            }}
          >
            <Typography
              sx={{
                color: '#4a494a',
                fontWeight: '600',
                fontSize: '12px',
                width: '14%',
                textAlign: 'right',
              }}
            >
              Sub Title
            </Typography>
            <TextField
              variant="outlined"
              size="small"
              value={formData.subTitle}
              onChange={(e) => handleChange(e, formEnum.subTitle)}
              required
              color="info"
              focused
            />{' '}
          </Box>
          <Box
            sx={{
              display: 'flex',
              gap: '12px',
            }}
          >
            <Typography
              sx={{
                color: '#4a494a',
                fontWeight: '600',
                fontSize: '12px',
                width: '14%',
                textAlign: 'right',
              }}
            >
              Main Content
            </Typography>
            <TextField
              variant="outlined"
              size="small"
              value={formData.mainContent}
              onChange={(e) => handleChange(e, formEnum.mainContent)}
              required
              color="info"
              focused
            />{' '}
          </Box>
          <Box
            sx={{
              display: 'flex',
              gap: '12px',
            }}
          >
            <Typography
              sx={{
                color: '#4a494a',
                fontWeight: '600',
                fontSize: '12px',
                width: '14%',
                textAlign: 'right',
              }}
            >
              Footer
            </Typography>
            <TextField
              variant="outlined"
              size="small"
              value={formData.footer}
              onChange={(e) => handleChange(e, formEnum.footer)}
              required
              color="info"
              focused
            />
          </Box>
          <FormControl
            sx={{
              display: 'flex',
              alignItems: 'center',
              '& .MuiButtonBase-root': {
                paddingTop: '0px',
                paddingBottom: '0px',
                paddingRight: '4px',
              },
            }}
          >
            <FormLabel
              id="demo-row-radio-buttons-group-label"
              sx={{
                color: '#4a494a',
                fontWeight: '600',
                fontSize: '12px',
                width: '14%',
                textAlign: 'right',
              }}
            >
              Open State
            </FormLabel>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              value={formData.openState || 'open'}
              onChange={(e) => handleChange(e, formEnum.openState)}
              name="row-radio-buttons-group"
              sx={{
                '& .MuiTypography-root': {
                  color: '#4a494a',
                  fontWeight: '600',
                  fontSize: '12px',
                },
              }}
            >
              <FormControlLabel
                value="open"
                control={<Radio />}
                label="Open"
                sx={{ color: '#4a494a', fontWeight: '600', fontSize: '12px' }}
              />
              <FormControlLabel
                value="close"
                control={<Radio />}
                label="Close"
                sx={{ color: '#4a494a', fontWeight: '600', fontSize: '12px' }}
              />
            </RadioGroup>
          </FormControl>
          <LoadingButton
            loading={isLoading?.usedFor === 'game' && isLoading.status}
            type="submit"
            variant="contained"
            color="secondary"
            sx={{
              borderRadius: '4px',
              width: 'fit-content',
              marginLeft: 'calc(14% + 12px) !important',
              fontSize: 14,
              height: 30,
              textTransform: 'capitalize',
              padding: ' 0px 6px',
              backgroundColor: '#0382b7',
            }}
          >
            Save
          </LoadingButton>
        </Stack>
      </Box>
    </Fragment>
  )
}

export default AddNew
