import React, { Fragment, useState } from 'react'
import moment from 'moment'
import {
  Box,
  Card,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Skeleton,
  Button,
} from '@mui/material'
import { styled } from '@mui/material/styles'
import { tableCellClasses } from '@mui/material/TableCell'
import MoreActions from 'app/dashboard/components/MoreActions'
import { useSelector, useDispatch } from 'react-redux'
import { updateUserStatus, getSelectedUser } from 'stores/user/user-actions'
import { RootState } from 'stores'
import TablePagination from 'app/web/components/TablePagination'
import { IUserDetails } from 'stores/user/user-slice'

const tableCellOptions: string[] = [
  '',
  'Account',
  'Balance',
  'Register date',
  'Last Login',
  'Manager',
  'Status',
]

const tableCellOptionsSummary: string[] = [
  'Account',
  'Balance',
  'Register date',
  'Last Login',
  'Manager',
]

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.primary.contrastText,
    fontWeight: 'bold',
    fontSize: 16,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    fontWeight: 600,
  },
}))

const StyledTableCellTranparent = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: 'transparent',
    fontWeight: 'bold',
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 12,
    fontWeight: 600,
  },
}))

const label = { inputProps: { 'aria-label': 'Status Switch' } }

interface ICommonTable {
  isSummaryTable: boolean
  data: any
  tableHeads: string[]
  isLoading: any
  toggleUserStatus: (role: string, id: string) => void
  setSelectedUser?: any
}

const CommonTable = ({
  isSummaryTable,
  data,
  tableHeads,
  isLoading,
  setSelectedUser,
  toggleUserStatus,
}: ICommonTable) => {
  const [selectedRow, setSelectedRow] = useState(null)

  const handleRowClick = (row: any) => {
    setSelectedRow(row)
  }

  return (
    <Table sx={{ borderRadius: 0, border: '1px solid #ccc' }}>
      <TableHead sx={{ backgroundColor: '#fff', color: 'primary.dark' }}>
        <TableRow
          sx={{
            height: '31px',
            borderBottom: isSummaryTable
              ? '2px solid #ccc !important'
              : '1px solid #c7c7c7 !important',
          }}
        >
          {tableHeads.map((item, index) =>
            isSummaryTable ? (
              <StyledTableCellTranparent key={index} sx={{ padding: 0, color: '#000' }}>
                {item}
              </StyledTableCellTranparent>
            ) : (
              <StyledTableCell
                key={index}
                sx={{
                  padding: 0,
                  backgroundColor: '#3398cc !important',
                  border: '1px solid #c7c7c7',
                }}
              >
                {item}
              </StyledTableCell>
            )
          )}
        </TableRow>
      </TableHead>

      {isLoading?.status && isLoading.usedFor === 'results' ? (
        <TableBody>
          <TableRow>
            {tableCellOptions.map((item) => (
              <TableCell key={item} sx={{ p: 0 }}>
                {Array.from(new Array(isSummaryTable ? 1 : 5)).map((_, index) => (
                  <Skeleton key={index} height={72} animation="wave" />
                ))}
              </TableCell>
            ))}
          </TableRow>
        </TableBody>
      ) : (
        <TableBody>
          {data.length > 0 &&
            data.map((customer: any, index: number) => {
              const moreActions = <MoreActions customerId={customer._id} />
              return (
                <Fragment key={index}>
                  <TableRow
                    sx={{
                      height: 'fit-content',
                      '& td': {
                        position: 'relative',
                        padding: '6px 0px',
                        bgcolor: selectedRow === index ? 'action.selected' : '',
                        color: '#000',
                        fontSize: '13px !important',
                        cursor: 'pointer',
                        border: isSummaryTable ? 'none' : '1px solid #c7c7c7',
                        borderBottom: isSummaryTable ? '2px solid #ccc' : '1px solid #c7c7c7',
                      },
                      '& td p': {
                        color: '#000',
                      },
                      background: isSummaryTable ? '#fff' : '#f5f5f5',
                    }}
                    hover
                  >
                    {!isSummaryTable && (
                      <TableCell sx={{ width: '80px', padding: '2px 0px !important' }}>
                        <Button
                          onClick={() => {
                            !!setSelectedUser && setSelectedUser(customer)
                            handleRowClick(index)
                          }}
                          variant="contained"
                          sx={{
                            backgroundColor: '#007dce',
                            height: '26px',
                            textTransform: 'none',
                            borderRadius: '8px',
                            width: '60px',
                            '&:hover': {
                              backgroundColor: '#007dce',
                            },
                          }}
                        >
                          Update
                        </Button>
                      </TableCell>
                    )}
                    <TableCell>
                      <Box
                        sx={{
                          alignItems: 'center',
                          display: 'flex',
                          justifyContent: 'center',
                        }}
                      >
                        {/* <Avatar
                          src=""
                          sx={{
                            mr: 2,
                            ml: { xs: 0, lg: 5 },
                            padding: '5px 0 0 0px',
                            height: '35px',
                            width: '35px',
                          }}
                        >
                          {getInitials(customer.account)}
                        </Avatar> */}

                        <Typography
                          color="textPrimary"
                          variant="body1"
                          sx={{
                            mt: 0.3,
                            fontSize: '13px',
                          }}
                        >
                          {customer.account}
                        </Typography>
                      </Box>
                    </TableCell>

                    <TableCell>{customer.balance}</TableCell>

                    <TableCell>{moment(customer.registeredDate).format('DD/MM/YYYY')}</TableCell>

                    <TableCell>{moment(customer.lastLoginDate).format('DD/MM/YYYY')}</TableCell>

                    <TableCell>{customer.manager}</TableCell>

                    {!isSummaryTable && (
                      <TableCell>
                        <Switch
                          {...label}
                          checked={customer.status === 'active' ? true : false}
                          onChange={() => toggleUserStatus(customer._id, customer.role)}
                          sx={{ position: 'absolute', top: 0, left: 0, right: 0, margin: 'auto' }}
                        />
                      </TableCell>
                    )}
                  </TableRow>

                  {isSummaryTable && (
                    <TableRow>
                      <TableCell colSpan={tableHeads.length}>{moreActions}</TableCell>
                    </TableRow>
                  )}
                </Fragment>
              )
            })}
        </TableBody>
      )}
    </Table>
  )
}

const UserTable: React.FC<IUserDetails> = ({
  results,
  paginationInfo,
  handlePageChange,
  handleChangeRowsPerPage,
  ...rest
}) => {
  const { userList = {} as any, selectedUser } = useSelector((state: RootState) => state.user)
  const { isLoading } = useSelector((state: RootState) => state.ui)
  const dispatch = useDispatch()

  const toggleUserStatus = (userId: string, role: string) => {
    dispatch(updateUserStatus(userId, role))
  }

  const setSelectedUser = (user: any) => {
    dispatch(getSelectedUser(user))
  }

  return (
    <Box
      key={`${isLoading?.status}-${isLoading?.usedFor}-${userList.length}`}
      // parentId="admin-table-container"
      // childId="admin-table"
      sx={{
        border: '2px solid #ccc',
        backgroundColor: '#f5f5f5',
        borderRadius: '4px',
        padding: '10px',
        marginTop: '10px',
        '& table': {
          '& th, td': {
            fontSize: { sm: 14 },
          },
        },
      }}
    >
      <Card {...rest} sx={{ borderRadius: '2px', boxShadow: 'none' }}>
        {results.length > 0 && (
          <CommonTable
            isSummaryTable={true}
            data={!!selectedUser ? [selectedUser] : [{ ...results[0] }]}
            tableHeads={tableCellOptionsSummary}
            isLoading={isLoading}
            toggleUserStatus={toggleUserStatus}
          />
        )}
      </Card>
      <br />

      <Card {...rest} sx={{ borderRadius: '2px', boxShadow: 'none' }}>
        <CommonTable
          isSummaryTable={false}
          data={results}
          tableHeads={tableCellOptions}
          isLoading={isLoading}
          toggleUserStatus={toggleUserStatus}
          setSelectedUser={setSelectedUser}
        />

        <TablePagination
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          handlePageChange={handlePageChange}
          paginationInfo={paginationInfo}
        />
      </Card>
    </Box>
  )
}

export default UserTable
