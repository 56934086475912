import React, { FormEvent, Fragment, useState } from 'react'
import { Typography, Box, Stack, TextField, SelectChangeEvent, Container } from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton'
import PageTitle from 'app/dashboard/components/PageTitle'
import { RootState } from 'stores'
import { useDispatch, useSelector } from 'react-redux'
import { createIssue } from 'stores/issues/issues-action'
import BackButton from 'app/dashboard/components/BackButton'
import { useNavigate } from 'react-router-dom'

type formKey = 'title' | 'description'

const CreateIssue: React.FC = () => {
  const navigate = useNavigate()
  const [formData, setFormData] = useState({
    title: '',
    description: '',
  })
  const { isLoading } = useSelector((state: RootState) => state.ui)

  const dispatch = useDispatch()

  const handleChange = (
    event: SelectChangeEvent | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    formKey: formKey
  ) => {
    setFormData({ ...formData, [formKey]: event.target.value })
  }

  const handleSubmit = async (event: FormEvent) => {
    event.preventDefault()
    await dispatch(createIssue(formData))
    navigate('/app/issues')
  }

  return (
    <Fragment>
      <PageTitle pageTitle="Send Report | Galaxy GamesGroup" />

      <Container
        maxWidth={false}
        sx={{ paddingLeft: '10px !important', paddingTop: '16px !important' }}
      >
        <Typography
          sx={{
            borderBottom: '1px solid #ded9d9',
            width: '100%',
            paddingBottom: '10px',
            paddingTop: '6px',
            marginBottom: '20px',
            display: 'flex',
            alignItems: 'center',
          }}
          variant="h4"
          color="secondary.main"
          fontSize={'18px'}
          fontWeight={'600'}
        >
          <BackButton iconOnly href="/app/issues" /> Create Report
        </Typography>
        <Box component={'form'} onSubmit={handleSubmit}>
          <Stack
            spacing={2}
            sx={{
              border: '2px solid #ccc',
              borderRadius: '4px',
              padding: '8px',
              '& .MuiOutlinedInput-notchedOutline': {
                borderColor: '#7F9DB9 !important',
                borderRadius: '4px',
              },

              '& input': {
                width: '192px',
                height: '22px',
                background: '#fff',
                fontSize: '12px',
                padding: '0px 5px',
              },
              '&  .MuiInputBase-multiline': {
                background: '#fff',
                fontSize: '13px',
                padding: '6px',
                width: '200px',
              },
            }}
          >
            <Box
              sx={{
                display: 'flex',
                gap: '12px',
                alignItems: 'center',
              }}
            >
              <Typography
                sx={{
                  color: '#4a494a',
                  fontWeight: '600',
                  fontSize: '12px',
                  width: '14%',
                  textAlign: 'right',
                }}
              >
                Title:
              </Typography>
              <TextField
                variant="outlined"
                size="small"
                required
                onChange={(e) => handleChange(e, 'title')}
              />
            </Box>
            <Box
              sx={{
                display: 'flex',
                gap: '12px',
                alignItems: 'center',
              }}
            >
              <Typography
                sx={{
                  color: '#4a494a',
                  fontWeight: '600',
                  fontSize: '12px',
                  width: '14%',
                  textAlign: 'right',
                }}
              >
                Description:
              </Typography>
              <TextField
                type="text"
                multiline
                rows={4}
                value={formData.description}
                variant="outlined"
                size="small"
                onChange={(e) => handleChange(e, 'description')}
                required
              />
            </Box>

            <LoadingButton
              loading={isLoading?.usedFor === 'issues' && isLoading.status}
              type="submit"
              variant="contained"
              sx={{
                borderRadius: '4px',
                width: 'fit-content',
                marginLeft: 'calc(14% + 12px) !important',
                fontSize: 14,
                height: 30,
                textTransform: 'capitalize',
                padding: ' 0px 6px',
                backgroundColor: '#0382b7',
              }}
              color="secondary"
            >
              Send
            </LoadingButton>
          </Stack>
        </Box>
      </Container>
    </Fragment>
  )
}

export default CreateIssue
