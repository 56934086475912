import React, { Fragment } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from 'stores'
import { Box, Typography } from '@mui/material'
import Searchbar from 'app/dashboard/components/Searchbar'
import ScaleContainer from 'app/dashboard/components/ScaleContainer'
import ExportBtn from 'app/dashboard/components/ExportBtn'

interface AdminTopBarProps {
  handleSubmit: (searchText: string) => void
  handleReset: () => void
  adminList: any
}

const AdminTopBar: React.FC<AdminTopBarProps> = (props) => {
  const { userList = {} as any } = useSelector((state: RootState) => state.user)
  const { isLoading } = useSelector((state: RootState) => state.ui)
  return (
    <Fragment>
      <ScaleContainer
        key={`${isLoading?.status}-${isLoading?.usedFor}-${userList.length}`}
        parentId="admin-topbar-container"
        childId="admin-topbar-content"
      >
        <Box
          id="admin-topbar-content"
          sx={{
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'space-between',
            flexWrap: 'wrap',
          }}
        >
          <Typography
            sx={{
              ml: '20px',
              mb: '0px',
              borderBottom: '1px solid #ded9d9',
              width: '100%',
              paddingBottom: '5px',
              paddingTop: '6px',
            }}
            variant="h4"
            color="secondary.main"
            fontSize={'16px'}
            fontWeight={'600'}
          >
            Admin Structure
          </Typography>
        </Box>
      </ScaleContainer>

      <Box
        sx={{
          mb: 1,
          display: 'flex',
          justifyContent: 'space-between',
          pr: 2,
          alignItems: 'center',
        }}
      >
        <Searchbar handleReset={props.handleReset} handleSubmit={props.handleSubmit} />
        <ExportBtn data={props.adminList as any} title="account-manager" />
      </Box>
    </Fragment>
  )
}

export default AdminTopBar
