import React, { Fragment, useEffect } from 'react'
import { Box, Button, Container, Stack, Typography } from '@mui/material'
import PageTitle from 'app/dashboard/components/PageTitle'
import { useSelector, useDispatch } from 'react-redux'
import AddIcon from '@mui/icons-material/Add'
import DisplayAnnouncement from './Result'
import { RootState } from 'stores'
import { getAnnouncement } from 'stores/announcement/announcement-actions'
import ExportBtn from 'app/dashboard/components/ExportBtn'
import BackButton from 'app/dashboard/components/BackButton'
import { Link } from 'react-router-dom'

const Index: React.FC = () => {
  const { gameAnnouncement } = useSelector((state: RootState) => state.announcement)
  const dispatch = useDispatch()

  useEffect(() => {
    if (!gameAnnouncement.length) {
      dispatch(getAnnouncement())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch])

  return (
    <Fragment>
      <PageTitle pageTitle="Manage Announcement | Galaxy GamesGroup" />
      <Container maxWidth={false}>
        <Box sx={{ py: 2 }}>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            sx={{ paddingBottom: '10px', marginBottom: '10px', borderBottom: '1px solid #ded9d9' }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <BackButton iconOnly href="/app" />
              <Typography variant="h4" color="secondary.main" fontSize={'16px'} fontWeight={'600'}>
                Announcement List
              </Typography>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
              <Link to="/app/announcement/create">
                <Button
                  variant="contained"
                  color="secondary"
                  sx={{
                    height: '100%',
                    fontWeight: '500',
                    color: 'white',
                    fontSize: '13px',
                    backgroundColor: '#722fee',
                    textTransform: 'none',
                    padding: '6px 12px',
                    borderRadius: '4px',
                  }}
                >
                  Add New
                </Button>
              </Link>
              <ExportBtn icon data={gameAnnouncement} title="announcement" />
            </Box>
          </Stack>
        </Box>
        <Box>
          <DisplayAnnouncement announcements={gameAnnouncement} />
        </Box>
      </Container>
    </Fragment>
  )
}

export default Index
