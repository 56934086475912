import { useState } from 'react'
import { Outlet } from 'react-router-dom'
import { makeStyles } from '@mui/styles'
import NavBar from './NavBar'
import TopBar from './TopBar'
import BalanceNav from './BalanceNav'
import ScaleContainer from 'app/dashboard/components/ScaleContainer'

const DashboardLayout = (): JSX.Element => {
  const classes = useStyles()
  const [isMobileNavOpen, setMobileNavOpen] = useState(false)

  return (
    <ScaleContainer childId="main-content" parentId="dashboard-layout">
      <div id="main-content" className={classes.root}>
        <TopBar onMobileNavOpen={() => setMobileNavOpen(true)} />
        <BalanceNav />
        <div className={classes.container}>
          <NavBar onMobileClose={() => setMobileNavOpen(false)} openMobile={isMobileNavOpen} />

          <div className={classes.wrapper}>
            <div className={classes.contentContainer}>
              <div className={classes.content}>
                <Outlet />
              </div>
            </div>
          </div>
        </div>
      </div>
    </ScaleContainer>
  )
}

export default DashboardLayout

const useStyles = makeStyles((theme: { [k: string]: any }) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    display: 'flex',
    flexDirection: 'column',
    // height: '100%',
    // overflow: 'hidden',
    overflow: 'auto',
    minWidth: 1000,

    '@media (min-width: 600px)': {
      height: '100vh',
    },
  },
  wrapper: {
    display: 'flex',
    overflow: 'hidden',
    width: '100%',
    marginLeft: 20,
    marginRight: 4,
    borderRadius: 3,
  },
  contentContainer: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
  },
  content: {
    flex: '1 1 auto',
    height: '100%',
    overflow: 'auto',
  },
  container: {
    display: 'flex',
    padding: '0px 7px',
    flex: 1,
    background: '#fafafa',
    height: 'calc(100vh - 200px)',
  },
}))
