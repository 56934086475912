import React, { Fragment } from 'react'
import {
  Card,
  Skeleton,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material'
import { styled } from '@mui/material/styles'
import { tableCellClasses } from '@mui/material/TableCell'
import { IUserDetails } from 'stores/user/user-slice'
import MoreActions from 'app/dashboard/components/MoreActions'
import { useDispatch, useSelector } from 'react-redux'
import { updateUserStatus, getSelectedAdmin } from 'stores/user/user-actions'
import moment from 'moment'
import { RootState } from 'stores'
import TablePagination from 'app/web/components/TablePagination'
import ScaleContainer from 'app/dashboard/components/ScaleContainer'

const tableCellOptions: string[] = [
  'Account',
  'Balance',
  'Level',
  'Manager',
  'Last Login',
  'Status',
]

const tableCellOptionsSummary: string[] = ['Account', 'Balance', 'Level', 'Manager', 'Last Login']

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    fontWeight: 'bold',
    fontSize: 16,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}))

const StyledTableCellTranparent = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: 'transparent',
    fontWeight: 'bold',
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    fontWeight: 600,
  },
}))

interface ICommonTable {
  isSummaryTable: boolean
  data: any
  tableHeads: string[]
  isLoading: any
  toggleUserStatus: (role: string, id: string) => void
  setSelectedAdmin?: any
}

const CommonTable = ({
  isSummaryTable,
  data,
  tableHeads,
  isLoading,
  toggleUserStatus,
  setSelectedAdmin,
}: ICommonTable) => {
  return (
    <Table
      id="admin-table"
      sx={{ borderRadius: 0, border: '1px solid #ccc', transform: 'none !important' }}
    >
      <TableHead sx={{ backgroundColor: '#fff', color: 'primary.dark' }}>
        <TableRow
          sx={{
            height: '31px',
            borderBottom: isSummaryTable
              ? '2px solid #ccc !important'
              : '1px solid #c7c7c7 !important',
          }}
        >
          {tableHeads.map((item, index) =>
            isSummaryTable ? (
              <StyledTableCellTranparent key={index} sx={{ padding: 0, color: '#000' }}>
                {item}
              </StyledTableCellTranparent>
            ) : (
              <StyledTableCell
                key={index}
                sx={{
                  padding: 0,
                  backgroundColor: '#3398cc !important',
                  border: '1px solid #c7c7c7',
                }}
              >
                {item}
              </StyledTableCell>
            )
          )}
        </TableRow>
      </TableHead>

      {isLoading?.status && isLoading.usedFor === 'adminList' ? (
        <TableBody>
          <TableRow>
            {tableCellOptions.map((item) => (
              <TableCell key={item} sx={{ p: 0 }}>
                {Array.from(new Array(isSummaryTable ? 1 : 5)).map((_, index) => (
                  <Skeleton key={index} height={72} animation="wave" />
                ))}
              </TableCell>
            ))}
          </TableRow>
        </TableBody>
      ) : (
        <TableBody>
          {data.map((admin: any, index: number) => (
            <Fragment key={index}>
              <TableRow
                hover
                sx={{
                  height: 'fit-content',
                  '& td': {
                    position: 'relative',
                    padding: '6px 0px',
                    fontSize: '13px !important',
                    // bgcolor: selectedRow === index ? 'action.selected' : '',
                    color: '#000',
                    cursor: 'pointer',
                    border: isSummaryTable ? 'none' : '1px solid #c7c7c7',
                    borderBottom: isSummaryTable ? '2px solid #ccc' : '1px solid #c7c7c7',
                  },

                  '&:nth-of-type(even)': {
                    bgcolor: 'background.default',
                  },
                  '&:nth-of-type(odd)': {
                    bgcolor: 'background.paper',
                  },
                }}
                onClick={() => !!setSelectedAdmin && setSelectedAdmin(admin)}
              >
                <TableCell>{admin.account}</TableCell>

                <TableCell>{admin.balance}</TableCell>

                <TableCell>{admin.role}</TableCell>

                <TableCell>{admin.manager}</TableCell>

                <TableCell>{moment(admin.lastLoginDate).format('DD/MM/YYYY')}</TableCell>

                {!isSummaryTable && (
                  <TableCell>
                    <Switch
                      checked={admin.status === 'active' ? true : false}
                      onChange={() => toggleUserStatus(admin._id, admin.role)}
                      sx={{ position: 'absolute', top: 0, left: 0, right: 0, margin: 'auto' }}
                    />
                  </TableCell>
                )}
              </TableRow>
              {isSummaryTable && (
                <TableRow>
                  <TableCell colSpan={tableHeads.length}>
                    <MoreActions adminId={admin._id} />
                  </TableCell>
                </TableRow>
              )}
            </Fragment>
          ))}
        </TableBody>
      )}
    </Table>
  )
}

const AdminTable: React.FC<IUserDetails> = ({
  results,
  paginationInfo,
  handlePageChange,
  handleChangeRowsPerPage,
  ...rest
}) => {
  const { userList = {} as any, selectedAdmin } = useSelector((state: RootState) => state.user)
  const { isLoading } = useSelector((state: RootState) => state.ui)
  const dispatch = useDispatch()

  const toggleUserStatus = (userId: string, role: string) => {
    dispatch(updateUserStatus(userId, role))
  }

  const setSelectedAdmin = (user: any) => {
    dispatch(getSelectedAdmin(user))
  }

  return (
    <ScaleContainer
      key={`${isLoading?.status}-${isLoading?.usedFor}-${userList.length}`}
      parentId="admin-table-container"
      childId="admin-table"
      sx={{
        transform: 'none !important',
        height: 'fit-content !important',
        '& table': {
          '& th, td': {
            fontSize: 14,
          },
        },
      }}
    >
      <Card {...rest} sx={{ borderRadius: '2px', boxShadow: 'none' }}>
        {results.length > 0 && (
          <CommonTable
            isSummaryTable={true}
            data={!!selectedAdmin ? [selectedAdmin] : [{ ...results[0] }]}
            tableHeads={tableCellOptionsSummary}
            isLoading={isLoading}
            toggleUserStatus={toggleUserStatus}
          />
        )}
      </Card>
      <br />
      <Card {...rest} sx={{ borderRadius: '2px', boxShadow: 'none' }}>
        <CommonTable
          isSummaryTable={false}
          data={results}
          tableHeads={tableCellOptions}
          isLoading={isLoading}
          toggleUserStatus={toggleUserStatus}
          setSelectedAdmin={setSelectedAdmin}
        />

        <TablePagination
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          handlePageChange={handlePageChange}
          paginationInfo={paginationInfo}
        />
      </Card>
    </ScaleContainer>
  )
}

export default AdminTable
