import React, { Fragment, useEffect } from 'react'
import { Box, Container, Typography } from '@mui/material'
import { useSelector, useDispatch } from 'react-redux'
import { getGameByTitle } from 'stores/game/game-action'
import PageTitle from 'app/dashboard/components/PageTitle'
import GameToolBox from './GameToolBox'
import DisplayGame from './DisplayGame'
import { RootState } from 'stores'
import ExportBtn from 'app/dashboard/components/ExportBtn'
import ScaleContainer from 'app/dashboard/components/ScaleContainer'

const Index: React.FC = () => {
  const [searchText, setSearchText] = React.useState<string>('')
  const { game } = useSelector((state: RootState) => state.gameList)
  const { isLoading } = useSelector((state: RootState) => state.ui)
  const { userList = {} as any } = useSelector((state: RootState) => state.user)
  const [paginationInfo, setPaginationInfo] = React.useState({
    currentPage: 1,
    lastPage: false,
    pageSize: 10,
    totalPage: 0,
    totalRecord: 0,
  })

  const dispatch = useDispatch()

  const handlePageChange = (_event: any, newPage: number) => {
    setPaginationInfo((value) => ({
      ...value,
      currentPage: newPage,
    }))
  }

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPaginationInfo((value) => ({
      ...value,
      pageSize: +event.target.value,
    }))
  }

  const handleSearch = (searchKey: string) => {
    setSearchText(searchKey)
    setPaginationInfo((value) => ({
      ...value,
      currentPage: 1,
    }))
  }

  const handleReset = () => {
    setSearchText('')
    setPaginationInfo((value) => ({
      ...value,
      currentPage: 1,
    }))
  }
  useEffect(() => {
    const fetch = async () => {
      const data = (await dispatch(
        getGameByTitle(paginationInfo?.currentPage, paginationInfo?.pageSize, searchText!)
      )) as any
      data && setPaginationInfo(data)
    }

    fetch()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paginationInfo?.currentPage, paginationInfo?.pageSize, searchText])

  return (
    <Fragment>
      <PageTitle pageTitle="Manage Games | Galaxy GamesGroup" />

      <Container maxWidth={false} sx={{ padding: '0px !important' }}>
        <Box
          sx={{
            backgroundColor: 'background.default',
            border: '2px solid #ccc',
            borderRadius: '4px',
            padding: '12px',
          }}
        >
          <GameToolBox handleSubmit={handleSearch} handleReset={handleReset} />
        </Box>

        <ScaleContainer
          key={`${isLoading?.status}--${isLoading?.usedFor}-${userList.length}`}
          parentId="manage-game-page-container"
          childId="manage-game-page-content"
        >
          <Box
            sx={{
              backgroundColor: 'background.default',
              border: '2px solid #ccc',
              borderRadius: '4px',
              padding: '12px',
              mt: 2,
            }}
            id="manage-game-page-content"
          >
            <Typography
              sx={{
                ml: '10px',
                paddingBottom: '5px',
                paddingTop: '6px',
                borderBottom: '1px solid #ded9d9',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                mb: 2,
              }}
              variant="h4"
              color="secondary.main"
              fontSize={'16px'}
              fontWeight={'600'}
            >
              Game List
              <ExportBtn icon data={game} title="game-list" />
            </Typography>

            <DisplayGame
              games={game}
              handlePageChange={handlePageChange}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
              paginationInfo={paginationInfo}
            />

            <br />
          </Box>
        </ScaleContainer>
      </Container>
    </Fragment>
  )
}

export default Index
