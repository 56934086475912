import { useEffect, useState } from 'react'
import { Box, Container, Typography } from '@mui/material'
import ScaleContainer from 'app/dashboard/components/ScaleContainer'
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'stores'
import { getTransactionDetailBySearch } from 'stores/transaction/transaction-actions'
import TransactionDetails from './TransactionDetails'

export interface TransactionRecordProps {
  customerId: string
}

const TransactionRecord: React.FC<TransactionRecordProps> = ({ customerId }) => {
  const { isLoading } = useSelector((state: RootState) => state.ui)
  const { userList = {} as any } = useSelector((state: RootState) => state.user)
  const { transactionDetailData } = useSelector((state: RootState) => state.transaction)
  const dispatch = useDispatch()

  const [paginationInfo, setPaginationInfo] = useState({
    currentPage: 1,
    lastPage: false,
    pageSize: 10,
    totalPage: 0,
    totalRecord: 0,
  })

  const handlePageChange = (_event: any, newPage: number) => {
    setPaginationInfo((value) => ({
      ...value,
      currentPage: newPage,
    }))
  }

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPaginationInfo((value) => ({
      ...value,
      pageSize: +event.target.value,
    }))
  }

  const firstWeek = moment().startOf('week').format('YYYY-MM-DD')
  const lastWeek = moment().endOf('week').format('YYYY-MM-DD')

  useEffect(
    () => {
      const fetchTransactionDetails = async () => {
        const response = (await dispatch(
          getTransactionDetailBySearch(
            customerId,
            firstWeek,
            lastWeek,
            paginationInfo.currentPage,
            paginationInfo.pageSize
          )
        )) as any
        response &&
          setPaginationInfo({
            ...response,
            currentPage: paginationInfo.currentPage,
            pageSize: paginationInfo.pageSize,
          })
      }

      fetchTransactionDetails()
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [paginationInfo.currentPage, paginationInfo.pageSize]
  )

  return (
    <>
      <Container maxWidth={false}>
        <ScaleContainer
          key={`${isLoading?.status}--${isLoading?.usedFor}-${userList.length}`}
          parentId="transaction-record-container"
          childId="transaction-record-child"
        >
          <Box id="transaction-record-child" sx={{ minWidth: 1200 }}>
            <Typography variant="h4" color="textPrimary" sx={{ py: 4 }}>
              Transaction Details
            </Typography>
            <TransactionDetails
              results={transactionDetailData}
              paginationInfo={paginationInfo}
              handlePageChange={handlePageChange}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </Box>
        </ScaleContainer>
      </Container>
    </>
  )
}

export default TransactionRecord
