import { Box } from '@mui/material'
import { useSelector } from 'react-redux'
import { RootState } from 'stores'

const BalanceNav = () => {
  const { userInfo } = useSelector((state: RootState) => state.user)
  return (
    <Box
      sx={{
        height: 30,
        backgroundColor: '#fcf8e3',
        padding: '6px 12px',
        color: 'text.secondary',
        fontSize: 14,
        fontWeight: 600,
        marginBottom: '6px',
      }}
    >
      <span>Balance: {userInfo?.balance.toFixed(2)}</span>
    </Box>
  )
}

export default BalanceNav
