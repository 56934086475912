// @ts-nocheck
import React from 'react'
import moment from 'moment'
import { Box, Card, Typography, CardContent, TextField, Button, Stack, Chip } from '@mui/material'
import { RootState } from 'stores'
import { useSelector } from 'react-redux'
import { LoadingButton } from '@mui/lab'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { DemoContainer } from '@mui/x-date-pickers/internals/demo'

const ReportSearch: React.FC<any> = ({ handlers, values }) => {
  const { isLoading } = useSelector((state: RootState) => state.ui)
  const { setStartDate, setEndDate, handleReset } = handlers
  const { startDate, endDate } = values

  const handleSearch = () => {
    handlers.handleSearch({
      startDate,
      endDate,
    })
  }

  const lastWeekStart = moment().subtract(1, 'week').startOf('isoWeek').format('YYYY-MM-DD')
  const lastWeekEnd = moment().subtract(1, 'week').endOf('isoWeek').format('YYYY-MM-DD')

  const lastMonthStart = moment().subtract(1, 'month').startOf('month').format('YYYY-MM-DD')
  const lastMonthEnd = moment().subtract(1, 'month').endOf('month').format('YYYY-MM-DD')

  return (
    <React.Fragment>
      <DemoContainer components={['DatePicker']}>
        <Box
          component="form"
          onSubmit={(e) => {
            handleSearch()
            e.preventDefault()
          }}
          sx={{ mb: 3 }}
        >
          <Card
            sx={{
              borderRadius: '0px',
              p: 0,
              backgroundColor: 'background.default',
              boxShadow: 'none',
            }}
            id="search-bar-content"
          >
            <CardContent sx={{ p: '10px 0px 0px 0px !important' }}>
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                <Box
                  sx={{
                    display: 'flex',
                    gap: '10px',
                    alignItems: 'center',
                    mb: 1,
                    width: '100%',
                  }}
                >
                  <Typography
                    sx={{
                      color: '#4a494a',
                      fontWeight: '600',
                      fontSize: '13px',
                    }}
                  >
                    Period:
                  </Typography>

                  <DatePicker
                    format="YYYY-MM-DD"
                    value={moment(startDate) || ''}
                    onChange={(value) => setStartDate(moment(value).format('YYYY-MM-DD'))}
                    sx={{
                      '& input': {
                        padding: '8.5px 14px',
                        fontSize: '14px',
                      },

                      backgroundColor: '#fff',
                      '& .MuiInputBase-root': {
                        borderRadius: '3px',
                      },
                      '& svg': {
                        width: '16px',
                        height: '16px',
                      },
                    }}
                  />

                  <Typography
                    variant="body1"
                    sx={{ fontSize: '1.15rem', textAlign: 'center', fontWeight: 'bold' }}
                  >
                    ~
                  </Typography>

                  <DatePicker
                    format="YYYY-MM-DD"
                    value={moment(endDate) || ''}
                    onChange={(value) => setEndDate(moment(value).format('YYYY-MM-DD'))}
                    sx={{
                      '& input': {
                        padding: '8.5px 14px',
                        fontSize: '14px',
                      },

                      backgroundColor: '#fff',
                      '& .MuiInputBase-root': {
                        borderRadius: '3px',
                      },
                      '& svg': {
                        width: '16px',
                        height: '16px',
                      },
                    }}
                  />
                  <LoadingButton
                    variant="contained"
                    type="submit"
                    loading={isLoading?.status && isLoading?.usedFor === 'global'}
                    color="secondary"
                    sx={{
                      fontWeight: '500',
                      color: 'white',
                      fontSize: '14px',
                      borderRadius: '4px',
                      backgroundColor: 'secondary.main',
                      textTransform: 'none',
                      padding: '6px 12px',
                      height: 34,
                    }}
                  >
                    Search
                  </LoadingButton>
                  <Button
                    variant="contained"
                    onClick={(e) => {
                      e.preventDefault()
                      handleReset()
                    }}
                    color="info"
                    sx={{
                      fontWeight: '500',
                      color: 'white',
                      fontSize: '14px',
                      borderRadius: '4px',
                      textTransform: 'none',
                      padding: '6px 12px',
                      height: 34,
                    }}
                  >
                    Clear
                  </Button>
                </Box>
                <Box sx={{ display: 'flex', gap: '8px', alignItems: 'center', width: '40%' }}>
                  {/* @ts-ignore */}
                  <Stack
                    direction="row"
                    spacing={1}
                    sx={{
                      justifyContent: 'flex-start',
                      marginLeft: '53px',
                      '& .MuiChip-root': {
                        fontWeight: '500',
                        color: 'white',
                        fontSize: '14px',
                        borderRadius: '4px',
                        backgroundColor: 'secondary.main',
                        textTransform: 'none',
                        padding: '6px 12px',
                        height: '34px',
                        '&:hover': {
                          backgroundColor: '#0382b7 !important',
                          opacity: 0.8,
                        },
                        '& .MuiChip-label': {
                          paddingLeft: '0px',
                          paddingRight: '0px',
                        },
                      },
                    }}
                  >
                    <Chip
                      label="Last Week"
                      onClick={() => {
                        setStartDate(lastWeekStart || '')
                        setEndDate(lastWeekEnd || '')
                      }}
                      color={'primary'}
                      variant={'filled'}
                      sx={{ cursor: 'pointer' }}
                    />

                    <Chip
                      label="Last Month"
                      onClick={() => {
                        setStartDate(lastMonthStart || '')
                        setEndDate(lastMonthEnd || '')
                      }}
                      color={
                        startDate === lastMonthStart && endDate === lastMonthEnd
                          ? 'secondary'
                          : 'default'
                      }
                      variant={
                        startDate === lastMonthStart && endDate === lastMonthEnd
                          ? 'default'
                          : 'outlined'
                      }
                      sx={{ cursor: 'pointer' }}
                    />
                  </Stack>
                </Box>
              </Box>
            </CardContent>
          </Card>
        </Box>
      </DemoContainer>
    </React.Fragment>
  )
}

export default ReportSearch
