import React from 'react'
import { Card, Skeleton, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material'
import { styled } from '@mui/material/styles'
import { tableCellClasses } from '@mui/material/TableCell'
import ScaleContainer from 'app/dashboard/components/ScaleContainer'
import { BurntReportInterface, IBurnReportDetails } from 'stores/burntreport/burnt-report-slice'
import { useSelector } from 'react-redux'
import { RootState } from 'stores'
import TablePagination from 'app/web/components/TablePagination'

const tableCellOptions: string[] = ['Game Name', 'Burnt Amount', 'Burnt Percentage']

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    fontWeight: 'bold',
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    fontWeight: 600,
  },
}))

interface ICommonTable {
  records: BurntReportInterface[]
  tableHeads: string[]
  isLoading: any
}

const CommonTable = ({ records, tableHeads, isLoading }: ICommonTable) => {
  return (
    <Table id="burnt-report-table" sx={{ borderRadius: 0, border: '1px solid #ccc' }}>
      <TableHead>
        <TableRow
          sx={{
            height: '40px',
            borderBottom: '1px solid #c7c7c7 !important',
          }}
        >
          {tableHeads.map((head, index) => (
            <StyledTableCell
              sx={{
                padding: 0,
                backgroundColor: '#3398cc !important',
                border: '1px solid #c7c7c7',
              }}
              key={index}
            >
              {head}
            </StyledTableCell>
          ))}
        </TableRow>
      </TableHead>
      {isLoading?.status && isLoading.usedFor === 'burnt-report' ? (
        <TableBody>
          <TableRow
            sx={{
              height: 'fit-content',
              '& td': {
                position: 'relative',
                padding: '6px 2px',
                color: '#000',
                cursor: 'pointer',
                border: '1px solid #c7c7c7',
              },
              '& td p': {
                color: '#000',
              },
              '&:nth-of-type(even)': {
                bgcolor: 'background.default',
              },
              '&:nth-of-type(odd)': {
                bgcolor: 'background.paper',
              },
            }}
          >
            {tableCellOptions.map((cell) => (
              <TableCell key={cell}>
                {Array.from(new Array(10)).map((_, index) => (
                  <Skeleton key={index} height={72} animation="wave" />
                ))}
              </TableCell>
            ))}
          </TableRow>
        </TableBody>
      ) : (
        <TableBody>
          {records.map((record: any, index: number) => (
            <TableRow
              key={index}
              hover
              sx={{
                height: 'fit-content',
                '& td': {
                  position: 'relative',
                  color: '#000',
                  fontSize: '12px !important',
                  cursor: 'pointer',
                  border: '1px solid #c7c7c7',
                  borderBottom: '1px solid #c7c7c7',
                },
                '& td p': {
                  color: '#000',
                },
                '&:nth-of-type(even)': {
                  bgcolor: 'background.default',
                },
                '&:nth-of-type(odd)': {
                  bgcolor: 'background.paper',
                },
              }}
            >
              <TableCell>{record.gameName}</TableCell>
              <TableCell
                sx={{
                  color: Math.sign(record.burntAmount) === -1 ? 'error.main' : 'success.main',
                }}
              >
                {record.burntAmount}
              </TableCell>
              <TableCell>{record.burntPercentage}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      )}
    </Table>
  )
}

const ReportDetails: React.FC<IBurnReportDetails> = ({
  results,
  paginationInfo,
  handlePageChange,
  handleChangeRowsPerPage,
  ...rest
}) => {
  const { isLoading } = useSelector((state: RootState) => state.ui)
  const { userList = {} as any } = useSelector((state: RootState) => state.user)

  return (
    <ScaleContainer
      key={`${isLoading?.status}-${isLoading?.usedFor}-${userList?.length}`}
      parentId="burnt-report-table"
      childId="report-table"
      sx={{
        '& table': {
          '& th, td': {
            fontSize: 14,
          },
        },
      }}
    >
      <Card {...rest} sx={{ borderRadius: '0px' }}>
        <CommonTable records={results} tableHeads={tableCellOptions} isLoading={isLoading} />
        <TablePagination
          paginationInfo={paginationInfo}
          handlePageChange={handlePageChange}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Card>
    </ScaleContainer>
  )
}

export default ReportDetails
