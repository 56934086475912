import React, { FormEvent, Fragment, useEffect, useRef, useState } from 'react'
import {
  Typography,
  Box,
  Stack,
  TextField,
  InputLabel,
  FormControl,
  Select,
  MenuItem,
  SelectChangeEvent,
  IconButton,
} from '@mui/material'
import { styled } from '@mui/material/styles'
import LoadingButton from '@mui/lab/LoadingButton'
import { RootState } from 'stores'
import { useDispatch, useSelector } from 'react-redux'
import { PhotoCamera } from '@mui/icons-material'
import CloudUploadIcon from '@mui/icons-material/CloudUpload'
import CloudDoneIcon from '@mui/icons-material/CloudDone'
import SunEditor from 'suneditor-react'
import SunEditorCore from 'suneditor/src/lib/core'
import 'suneditor/dist/css/suneditor.min.css'
import CircularProgressWithLabel from 'app/dashboard/components/CircularProgressWithLabel'
import handleFileUpload from 'app/dashboard/utils/fileUploadHandler'
import { IResponse } from 'app/dashboard/utils/fileUploadHandler'
import { getCategory } from 'stores/game/game-category-actions'
import { createGame, updateGame } from 'stores/game/game-action'
import { handleErrorResponse } from 'app/dashboard/utils/errorHandler'
import { useParams } from 'react-router-dom'
import { IActionType } from 'app/dashboard/pages/category/AddNew'
import { Status } from 'commons/types'
import { STATUSES } from 'constants/index'

const Input = styled('input')({
  display: 'none',
})

enum formEnum {
  title = 'title',
  code = 'code',
  category = 'category',
  images = 'images',
  featuredImage = 'featuredImage',
  videoEmbedId = 'videoEmbedId',
  status = 'active',
}

const AddNew: React.FC<IActionType> = ({ actionType = 'add' }) => {
  const [formData, setFormData] = useState<{
    title: string
    code: string
    category: string
    images: Array<string>
    featuredImage: string
    videoEmbedId: string
    status: Status
  }>({
    title: '',
    code: '',
    category: '',
    images: [],
    featuredImage: '',
    videoEmbedId: '',
    status: STATUSES.active as Status,
  })
  const [description, setDescription] = useState('')
  const [isFeaturedImgUploading, setIsFeaturedImgUploading] = useState(false)
  const [isUploading, setIsUploading] = useState(false)
  const [progress, setProgress] = React.useState(10)
  const { isLoading } = useSelector((state: RootState) => state.ui)
  const { game } = useSelector((state: RootState) => state.gameList)
  const { gameCategory } = useSelector((state: RootState) => state.category)
  const dispatch = useDispatch()
  const { id } = useParams()

  useEffect(() => {
    if (!gameCategory.length) {
      dispatch(getCategory(1, 5))
    }

    if (game.length && actionType === 'edit' && id?.length) {
      const [targetGame] = game.filter((g) => g._id === id)
      setFormData({
        title: targetGame.title,
        code: targetGame.code,
        category: targetGame.category,
        images: targetGame.images,
        featuredImage: targetGame.featuredImage,
        videoEmbedId: targetGame.videoEmbedId,
        status: targetGame.status,
      })
      editor.current?.setContents(targetGame.description)
    }
  }, [actionType, dispatch, game, gameCategory.length, id])

  const editor = useRef<SunEditorCore>()

  const getSunEditorInstance = (sunEditor: SunEditorCore) => {
    editor.current = sunEditor
  }

  const handleChange = (
    event: SelectChangeEvent | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    formKey: formEnum
  ) => {
    if (formKey === formEnum.featuredImage) {
      const target = event.target as HTMLInputElement
      const file: File = (target.files as FileList)[0]
      setIsFeaturedImgUploading(true)
      handleFileUpload(file, setProgress)
        .then((res) => {
          const featUrl = (res as IResponse).uploadedImg[0]
          setFormData({ ...formData, [formEnum.featuredImage]: featUrl })
        })
        .catch((errRes) => {
          return handleErrorResponse(errRes, dispatch)
        })
        .finally(() => {
          setIsFeaturedImgUploading(false)
        })
    } else if (formKey === formEnum.images) {
      const target = event.target as HTMLInputElement
      const files = target.files as FileList
      setIsUploading(true)
      handleFileUpload(files, setProgress)
        .then((res) => {
          const screenShot = (res as IResponse).uploadedImg
          setFormData({ ...formData, [formEnum.images]: screenShot })
        })
        .catch((errRes) => {
          return handleErrorResponse(errRes, dispatch)
        })
        .finally(() => {
          setIsUploading(false)
        })
    } else {
      setFormData({ ...formData, [formKey]: event.target.value })
    }
  }

  const onEditorContentChange = (content: any) => {
    setDescription(content)
  }

  const handleSubmit = (event: FormEvent) => {
    event.preventDefault()
    if (!formData.featuredImage) {
      window.alert('Featured image is required!')
      return
    }

    if (!(formData.images.length > 1)) {
      window.alert('Please choose at least 2 screenshots')
      return
    }

    if (actionType === 'edit' && id) {
      dispatch(updateGame({ ...formData, description }, id))
    } else {
      dispatch(createGame({ ...formData, description }))
    }
    setTimeout(() => {
      setFormData({
        title: '',
        code: '',
        category: '',
        images: [],
        featuredImage: '',
        videoEmbedId: '',
        status: STATUSES.active as Status,
      })
      editor.current?.setContents('')
    }, 1000)
  }

  return (
    <Fragment>
      <Box
        component="form"
        sx={{
          mx: 'auto',
          my: { xs: 3, md: 1 },
          p: 3,
          maxWidth: '600px',
          backgroundColor: 'background.paper',
          borderRadius: '16px',
          boxShadow: 24,
          '& .MuiFormControl-root': {
            my: 2,
          },
        }}
        onSubmit={handleSubmit}
      >
        <Typography variant="h4" mb={2} color="textPrimary">
          {actionType === 'edit' ? 'Update Game' : 'Create New Game'}
        </Typography>
        <Stack spacing={2}>
          <TextField
            color="info"
            variant="outlined"
            label="Game title"
            size="small"
            value={formData.title}
            onChange={(e) => handleChange(e, formEnum.title)}
            required
            focused
          />
          <TextField
            variant="outlined"
            label="Game code"
            size="small"
            color="info"
            value={formData.code}
            onChange={(e) => handleChange(e, formEnum.code)}
            required
            focused
          />
          <FormControl fullWidth size="small">
            <InputLabel id="demo-simple-select-label">Choose category</InputLabel>
            <Select
              value={formData.category}
              color="info"
              label="Game category"
              onChange={(e) => handleChange(e, formEnum.category)}
              required
            >
              {gameCategory.map((menu) => (
                <MenuItem key={menu._id} value={menu._id}>
                  {menu.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField
            variant="outlined"
            label="Video Embed Id"
            size="small"
            color="info"
            value={formData.videoEmbedId}
            onChange={(e) => handleChange(e, formEnum.videoEmbedId)}
            required
            focused
          />
          <Stack
            direction="row"
            justifyContent="center"
            spacing={3}
            sx={{
              '& span': { cursor: 'pointer' },
              border: '1px dotted rgba(0, 0, 0, 0.25)',
              py: 3,
              bgcolor: '#F3F6FB',
            }}
          >
            {isFeaturedImgUploading ? (
              <CircularProgressWithLabel value={progress} />
            ) : (
              <label htmlFor="featured-image">
                <Input
                  accept="image/*"
                  id="featured-image"
                  type="file"
                  onChange={(e) => handleChange(e, formEnum.featuredImage)}
                />
                <IconButton color="primary" aria-label="upload picture" component="span">
                  {formData.featuredImage ? (
                    <CloudDoneIcon fontSize="large" color="success" />
                  ) : (
                    <CloudUploadIcon fontSize="large" />
                  )}
                </IconButton>

                <Typography variant="caption">Featured Image</Typography>
              </label>
            )}
            {isUploading ? (
              <CircularProgressWithLabel value={progress} />
            ) : (
              <label htmlFor="screenshot-image">
                <Input
                  accept="image/*"
                  id="screenshot-image"
                  type="file"
                  multiple
                  onChange={(e) => handleChange(e, formEnum.images)}
                />
                <IconButton color="primary" aria-label="upload picture" component="span">
                  {formData.images && formData.images.length > 0 ? (
                    <CloudDoneIcon fontSize="large" color="success" />
                  ) : (
                    <PhotoCamera fontSize="large" />
                  )}
                </IconButton>

                <Typography variant="caption">Screenshots</Typography>
              </label>
            )}
          </Stack>

          <Typography variant="subtitle2">Game Description</Typography>

          <SunEditor
            getSunEditorInstance={getSunEditorInstance}
            placeholder="Write something catchy!"
            setAllPlugins={true}
            setOptions={{
              buttonList: [
                ['font', 'fontSize', 'formatBlock'],
                ['bold', 'underline', 'italic', 'strike', 'subscript', 'superscript'],
                ['outdent', 'indent'],
                ['align', 'list', 'lineHeight'],
                ['fullScreen', 'codeView'],
                ['preview'],
              ],
              font: ['Arial', 'Calibri', 'Courier', 'Segoe UI', 'Times New Roman', 'Ubuntu'],
            }}
            onChange={onEditorContentChange}
            setDefaultStyle="font-size: 16px;"
            height="130px"
          />
          {/* <Box sx={{ maxWidth: '100%', display: 'flex', justifyContent: 'center' }}> */}
          <LoadingButton
            loading={isLoading?.usedFor === 'game' && isLoading.status}
            type="submit"
            variant="contained"
            sx={{
              maxWidth: '20%',
              '&.MuiLoadingButton-root ': { mt: 4 },
              color: 'white',
              fontWeight: 'bold',
            }}
            color="secondary"
          >
            Save
          </LoadingButton>
          {/* </Box> */}
        </Stack>
      </Box>
    </Fragment>
  )
}

export default AddNew
