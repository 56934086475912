import moment from 'moment'
import { Card, Skeleton, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material'
import { styled } from '@mui/material/styles'
import { tableCellClasses } from '@mui/material/TableCell'
import { ITransactionDetails, ITransactionDetailsState } from 'stores/transaction/transaction-slice'
import { useSelector } from 'react-redux'
import { RootState } from 'stores'
import TablePagination from 'app/web/components/TablePagination'
import ScaleContainer from 'app/dashboard/components/ScaleContainer'

const tableCellOptions: string[] = [
  'NickName',
  'Before',
  'Recharged',
  'Redeemed',
  'After',
  'Remark',
  'Date',
]

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    fontWeight: 600,
    fontSize: 16,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    fontWeight: 500,
  },
}))

interface ICommonTable {
  transactions: ITransactionDetailsState[]
  tableHeads: string[]
  isLoading: any
}

const CommonTable = ({ transactions, tableHeads, isLoading }: ICommonTable) => {
  return (
    <Table id="transaction-details-table" sx={{ borderRadius: 0, border: '1px solid #ccc' }}>
      <TableHead sx={{ backgroundColor: '#fff', color: 'primary.dark' }}>
        <TableRow
          sx={{
            height: '31px',
            borderBottom: '1px solid #c7c7c7 !important',
          }}
        >
          {tableHeads.map((head, index) => (
            <StyledTableCell
              sx={{
                padding: 0,
                backgroundColor: '#3398cc !important',
                border: '1px solid #c7c7c7',
              }}
              key={index}
            >
              {head}
            </StyledTableCell>
          ))}
        </TableRow>
      </TableHead>
      {isLoading && isLoading.status && isLoading.usedFor === 'transaction' ? (
        <TableBody>
          <TableRow>
            {tableCellOptions.map((cell) => (
              <StyledTableCell key={cell}>
                {Array.from(new Array(5)).map((_, index) => (
                  <Skeleton key={index} height={72} animation="wave" />
                ))}
              </StyledTableCell>
            ))}
          </TableRow>
        </TableBody>
      ) : (
        <TableBody>
          {transactions.map((transaction) => (
            <TableRow
              key={transaction._id}
              hover
              sx={{
                height: 'fit-content',
                '& td': {
                  position: 'relative',
                  padding: '6px 0px',
                  color: '#000',
                  cursor: 'pointer',
                  fontSize: '12px !important',
                  border: '1px solid #c7c7c7',
                  borderBottom: '1px solid #c7c7c7',
                },
                '& td p': {
                  color: '#000',
                },
                '&:nth-of-type(even)': {
                  bgcolor: 'background.default',
                },
                '&:nth-of-type(odd)': {
                  bgcolor: 'background.paper',
                },
              }}
            >
              <TableCell>{transaction.customer.account}</TableCell>
              <TableCell>{transaction.before.toFixed(2)}</TableCell>
              <TableCell>{transaction.recharge.toFixed(2)}</TableCell>
              <TableCell>{transaction.redeem.toFixed(2)}</TableCell>
              <TableCell>{transaction.after.toFixed(2)}</TableCell>
              <TableCell>{transaction.remark}</TableCell>
              <TableCell>{moment(transaction.createdAt).format('DD/MM/YYYY')}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      )}
    </Table>
  )
}

const TransactionDetails: React.FC<ITransactionDetails> = ({
  results,
  paginationInfo,
  handlePageChange,
  handleChangeRowsPerPage,
  ...rest
}) => {
  const { userList = {} as any } = useSelector((state: RootState) => state.user)
  const { isLoading } = useSelector((state: RootState) => state.ui)
  return (
    <ScaleContainer
      key={`${isLoading?.status}-${isLoading?.usedFor}-${userList.length}`}
      parentId="transaction-details-container"
      childId="details-table"
      sx={{
        '& table': {
          '& th, td': {
            fontSize: 14,
          },
        },
      }}
    >
      <Card {...rest} sx={{ borderRadius: '0px', boxShadow: 'none' }}>
        <CommonTable transactions={results} tableHeads={tableCellOptions} isLoading={isLoading} />
        <TablePagination
          paginationInfo={paginationInfo}
          handlePageChange={handlePageChange}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Card>
    </ScaleContainer>
  )
}

export default TransactionDetails
