import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { UserRoleEnum } from 'stores/auth/auth-slice'

export interface IUserState {
  _id: string
  account: string
  nickname: string
  balance: number
  registeredDate: Date
  lastLoginDate: Date
  manager: string
  status: 'active' | 'inactive'
  role: UserRoleEnum
  note: string
}

interface IState {
  userInfo: IUserState | null
  userList?: IUserState[]
  adminList?: IUserState[]
  selectedAdmin?: IUserState
  selectedUser?: IUserState
}

export interface IUpdateWallet {
  targetId: string
  amount: number
  note: string
}

export interface IUserStatus {
  targetId: string
  status: 'active' | 'inactive'
  role: string
}

export interface IUserDetails {
  results: IUserState[]
  paginationInfo?: any
  handleChangeRowsPerPage: (e: any) => void
  handlePageChange: (e: any, n: number) => void
}

const initialState: IState = { userInfo: null, userList: [], adminList: [] }

const userSlice = createSlice({
  name: 'user',
  initialState: initialState,
  reducers: {
    setSelectedAdmin(state, action: PayloadAction<IUserState>) {
      state.selectedAdmin = action.payload
    },
    setSelectedUser(state, action: PayloadAction<IUserState>) {
      state.selectedUser = action.payload
    },
    setUserInfo(state, action: PayloadAction<IUserState>) {
      state.userInfo = action.payload
    },
    setUserList(state, action: PayloadAction<IUserState[]>) {
      state.userList = action.payload
    },
    setAdminList(state, action: PayloadAction<IUserState[]>) {
      state.adminList = action.payload
    },
    updateUserList(state, action: PayloadAction<IUserState>) {
      if (action.payload.role === UserRoleEnum.CUSTOMER) {
        state.userList?.push(action.payload)
      } else {
        state.adminList?.push(action.payload)
      }
    },
    rechargeWallet(state, action: PayloadAction<IUpdateWallet>) {
      if (state.userInfo) {
        state.userInfo.balance -= action.payload.amount
        state.userInfo.note = action.payload.note
      }
      if (action.payload.note !== UserRoleEnum.CUSTOMER && state.adminList) {
        state.adminList = state.adminList?.map((admin) => {
          if (admin._id === action.payload.targetId) {
            admin.balance += action.payload.amount
          }
          return admin
        })
      }

      if (state.userList) {
        state.userList = state.userList?.map((customer) => {
          if (customer._id === action.payload.targetId) {
            customer.balance += action.payload.amount
          }
          return customer
        })
      }
    },
    redeemWallet(state, action: PayloadAction<IUpdateWallet>) {
      if (state.userInfo) {
        state.userInfo.balance += action.payload.amount
      }

      if (action.payload.note !== UserRoleEnum.CUSTOMER && state.adminList) {
        state.adminList = state.adminList?.map((admin) => {
          if (admin._id === action.payload.targetId) {
            admin.balance -= action.payload.amount
          }
          return admin
        })
      }

      if (state.userList) {
        state.userList = state.userList?.map((customer) => {
          if (customer._id === action.payload.targetId) {
            customer.balance -= action.payload.amount
          }
          return customer
        })
      }
    },
    updateSuperAdminBlc(state, action: PayloadAction<IUpdateWallet>) {
      if (state.userInfo) {
        state.userInfo.balance = state.userInfo.balance + action.payload.amount
      }
    },
    updateUserStatus(state, action: PayloadAction<IUserStatus>) {
      if (action.payload.role !== UserRoleEnum.CUSTOMER && state.adminList) {
        state.adminList = state.adminList?.map((admin) => {
          if (admin._id === action.payload.targetId) {
            admin.status = action.payload.status
          }
          return admin
        })
      }

      if (state.userList) {
        state.userList = state.userList?.map((customer) => {
          if (customer._id === action.payload.targetId) {
            customer.status = action.payload.status
          }
          return customer
        })
      }
    },
  },
})

export const userActions = userSlice.actions
export default userSlice.reducer
