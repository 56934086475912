export const removeHtmlTags = (text: string): string => {
  return text.replace(/<[^>]+>/g, '')
}

export const isLocalHost = () => {
  const hostname = window.location.hostname
  return hostname === 'localhost' || hostname === '127.0.0.1'
}

export const debounce = (func: any, timeout = 300) => {
  let timer : any;

  return (...args: any) => {
    clearTimeout(timer);
    timer = setTimeout(() => { func.apply(this, args); }, timeout);
  };
}