import * as React from 'react'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import Typography from '@mui/material/Typography'
import FilterBar from 'app/dashboard/components/FilterBar'
import PageTitle from 'app/dashboard/components/PageTitle'
import JpDetails from './JpDetails'
import JpRecords from './data'
import { useSelector } from 'react-redux'
import { RootState } from 'stores'
import ScaleContainer from 'app/dashboard/components/ScaleContainer'

const Default: React.FC = () => {
  const [paginationInfo, setPaginationInfo] = React.useState({
    currentPage: 1,
    lastPage: false,
    pageSize: 10,
    totalPage: 0,
    totalRecord: 0,
  })
  const { isLoading } = useSelector((state: RootState) => state.ui)
  const { userList = {} as any } = useSelector((state: RootState) => state.user)

  const handlePageChange = (_event: any, newPage: number) => {
    setPaginationInfo((value) => ({
      ...value,
      currentPage: newPage,
    }))
  }

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPaginationInfo((value) => ({
      ...value,
      pageSize: +event.target.value,
    }))
  }

  return (
    <Box>
      <PageTitle pageTitle="Jp Records | Galaxy GamesGroup" />

      <Container maxWidth={false} sx={{ padding: '0px !important' }}>
        <ScaleContainer
          key={`${isLoading?.status}--${isLoading?.usedFor}-${userList.length}`}
          parentId="jp-records-page-container"
          childId="jp-records-page-content"
        >
          <Box sx={{}} id="jp-records-page-content">
            <Box
              sx={{
                backgroundColor: 'background.default',
                border: '2px solid #ccc',
                borderRadius: '4px',
              }}
            >
              <FilterBar barHeading="JP Records" />
            </Box>

            <Box
              sx={{
                backgroundColor: 'background.default',
                border: '2px solid #ccc',
                borderRadius: '4px',
                marginTop: '14px',
                padding: '12px',
              }}
            >
              <Typography
                sx={{
                  ml: '10px',
                  mb: '20px',
                  borderBottom: '1px solid #ded9d9',
                  width: '100%',
                  paddingBottom: '5px',
                  paddingTop: '6px',
                }}
                variant="h4"
                color="secondary.main"
                fontSize={'16px'}
                fontWeight={'600'}
              >
                Record Details
              </Typography>

              <JpDetails
                jprecords={JpRecords}
                handlePageChange={handlePageChange}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
                paginationInfo={paginationInfo}
              />

              <br />
            </Box>
          </Box>
        </ScaleContainer>
      </Container>
    </Box>
  )
}

export default Default
