import React from 'react'
import { useSelector } from 'react-redux'
import { useLocation, Navigate, Outlet } from 'react-router-dom'
import { RootState } from 'stores/index'
import { UserRoleEnum } from 'stores/auth/auth-slice'

const RequireSubAdminAccess: React.FC = () => {
  const { role } = useSelector((state: RootState) => state.auth)
  const location = useLocation()

  // const isSubAdmin = () => {
  //   return [UserRoleEnum.SUBADMIN, UserRoleEnum.SUPERADMIN].includes(role as UserRoleEnum)
  // }

  const isSubAdmin = () => {
    return [UserRoleEnum.ADMIN, UserRoleEnum.SUPERADMIN].includes(role as UserRoleEnum)
  }

  if (!isSubAdmin()) {
    return <Navigate to="/app" state={{ from: location }} />
  }

  return <Outlet />
}

export default RequireSubAdminAccess
