import React from 'react'
import { Snackbar, Alert } from '@mui/material'
import { useDispatch } from 'react-redux'
import { uiActions } from 'stores/ui/ui-slice'

interface NotificationProps {
  notiType: 'error' | 'success'
  msg: string
}
const Notification: React.FC<NotificationProps> = ({ notiType, msg }) => {
  const dispatch = useDispatch()

  const handleClose = () => {
    dispatch(uiActions.hideNotification())
  }

  return (
    <Snackbar
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={true}
      onClose={handleClose}
    > 
      <Alert
        onClose={handleClose}
        severity={notiType}
        sx={{
          width: '100%',
          backgroundColor: `${notiType}.light`,
          color: 'success.contrastText',
          fontWeight: 'bold',
          '& .MuiSvgIcon-root': {
            color: '#ffffff',
          },
        }}
      >
        {msg}
      </Alert>
    </Snackbar>
  )
}

export default Notification
