// @ts-nocheck
import React from 'react'
import { Box, Card, CardContent, Button, TextField, Typography } from '@mui/material'
import { RootState } from 'stores'
import { useSelector } from 'react-redux'
import { LoadingButton } from '@mui/lab'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { DemoContainer } from '@mui/x-date-pickers/internals/demo'
import SearchGameIdField from '../games/SearchGameIdField'
import moment from 'moment'

const ReportSearch: React.FC<any> = ({ handlers, values }) => {
  const { isLoading } = useSelector((state: RootState) => state.ui)
  const { setGameIdOption, handleOverviewReset, setStartOverviewDate, setEndOverviewDate } =
    handlers
  const { gameIdOption, startOverviewDate, endOverviewDate } = values

  const handleOverviewSearch = () => {
    handlers.handleOverviewSearch({
      gameIdOption,
      startOverviewDate,
      endOverviewDate,
    })
  }

  return (
    <React.Fragment>
      <DemoContainer components={['DatePicker']}>
        <Box
          component="form"
          onSubmit={(e) => {
            handleOverviewSearch()
            e.preventDefault()
          }}
          sx={{ mb: 2 }}
        >
          <Card
            sx={{
              borderRadius: '0px',
              p: 0,
              backgroundColor: 'background.default',
              boxShadow: 'none',
            }}
            id="search-bar-content"
          >
            <CardContent sx={{ p: '10px 0px 0px 0px !important' }}>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                <Box sx={{ display: 'flex', gap: '8px', alignItems: 'center', width: '40%' }}>
                  <Typography
                    sx={{
                      color: '#4a494a',
                      fontWeight: '600',
                      fontSize: '13px',
                      flex: 1,
                    }}
                  >
                    Period:
                  </Typography>
                  <DatePicker
                    format="YYYY-MM-DD"
                    value={moment(startOverviewDate) || ''}
                    onChange={(value) => setStartOverviewDate(moment(value).format('YYYY-MM-DD'))}
                    sx={{
                      '& input': {
                        padding: '8.5px 14px',
                        fontSize: '14px',
                      },

                      backgroundColor: '#fff',
                      '& .MuiInputBase-root': {
                        borderRadius: '3px',
                      },
                      '& svg': {
                        width: '16px',
                        height: '16px',
                      },
                    }}
                  />
                  {/* <TextField
                    defaultValue={startOverviewDate || ''}
                    type="date"
                    variant="outlined"
                    size="small"
                    onChange={(e) => setStartOverviewDate(e.currentTarget.value)}
                    sx={{
                      width: '100%',
                      '& input': {
                        fontSize: '13px',
                      },
                      borderRadius: '3px',
                      backgroundColor: '#fff',
                      '&  .MuiInputBase-root': {
                        borderRadius: '3px',
                      },
                    }}
                    fullWidth
                    focused
                  /> */}

                  <Typography
                    variant="body1"
                    sx={{ fontSize: '1.15rem', textAlign: 'center', fontWeight: 'bold' }}
                  >
                    ~
                  </Typography>

                  <DatePicker
                    format="YYYY-MM-DD"
                    value={moment(endOverviewDate) || ''}
                    onChange={(value) => setEndOverviewDate(moment(value).format('YYYY-MM-DD'))}
                    sx={{
                      '& input': {
                        padding: '8.5px 14px',
                        fontSize: '14px',
                      },

                      backgroundColor: '#fff',
                      '& .MuiInputBase-root': {
                        borderRadius: '3px',
                      },
                      '& svg': {
                        width: '16px',
                        height: '16px',
                      },
                    }}
                  />
                  {/* <TextField
                    type="date"
                    variant="outlined"
                    size="small"
                    defaultValue={endOverviewDate || ''}
                    onChange={(e) => setEndOverviewDate(e.currentTarget.value)}
                    sx={{
                      borderRadius: '3px',
                      mr: 1,
                      backgroundColor: '#fff',
                      '&  .MuiInputBase-root': {
                        borderRadius: '3px',
                      },
                      '& input': {
                        fontSize: '13px',
                      },
                    }}
                    fullWidth
                    focused
                  /> */}
                </Box>
                <Box sx={{ width: '30%' }}>
                  <SearchGameIdField onChange={setGameIdOption} value={gameIdOption} />
                </Box>
                <Box sx={{ display: 'flex', width: '20%', gap: '10px' }}>
                  <LoadingButton
                    variant="contained"
                    type="submit"
                    loading={isLoading?.status && isLoading?.usedFor === 'global'}
                    color="secondary"
                    sx={{
                      fontWeight: '500',
                      color: 'white',
                      fontSize: '14px',
                      borderRadius: '4px',
                      backgroundColor: 'secondary.main',
                      textTransform: 'none',
                      padding: '6px 12px',
                      height: 34,
                    }}
                  >
                    Search
                  </LoadingButton>
                  <Button
                    variant="contained"
                    onClick={(e) => {
                      e.preventDefault()
                      handleOverviewReset()
                    }}
                    color="info"
                    sx={{
                      fontWeight: '500',
                      color: 'white',
                      fontSize: '14px',
                      borderRadius: '4px',
                      textTransform: 'none',
                      padding: '6px 12px',
                      height: 34,
                    }}
                  >
                    Clear
                  </Button>
                </Box>
              </Box>
            </CardContent>
          </Card>
        </Box>
      </DemoContainer>
    </React.Fragment>
  )
}

export default ReportSearch
