import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'stores/index'
import { IUserState } from 'stores/user/user-slice'
import { getSelectedUser, rechargeCustomerBlc, redeemBlc } from 'stores/user/user-actions'
import { Typography, Stack, TextField, Container, Box } from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton'
import ScaleContainer from './ScaleContainer'

export enum WalletEnum {
  Recharge = 'Recharge',
  Redeem = 'Redeem',
}

interface WalletProps {
  walletFuncText: WalletEnum.Recharge | WalletEnum.Redeem
  customer: IUserState | undefined
  setSelectedUser?: React.Dispatch<React.SetStateAction<IUserState | undefined>>
}

const Wallet: React.FC<WalletProps> = ({ walletFuncText, customer, setSelectedUser }) => {
  const [amount, setAmount] = useState<number>(0)
  const [note, setNote] = useState<string>()
  const { userInfo } = useSelector((state: RootState) => state.user)
  const { isLoading } = useSelector((state: RootState) => state.ui)
  const dispatch = useDispatch()

  const selectedUser = (user: any) => {
    dispatch(getSelectedUser(user))
  }

  const handleSubmit = () => {
    selectedUser(setSelectedUser)
    if (walletFuncText === WalletEnum.Recharge) {
      dispatch(
        rechargeCustomerBlc(
          customer?._id as string,
          customer?.nickname as string,
          amount,
          note as string
        )
      )
    }
    if (walletFuncText === WalletEnum.Redeem) {
      dispatch(
        redeemBlc(customer?._id as string, customer?.nickname as string, amount, note as string)
      )
    }
  }

  return (
    <Container
      maxWidth={false}
      sx={{ marginTop: '-20px', paddingLeft: '0px !important', paddingRight: '0px !important' }}
    >
      <ScaleContainer key="waller" parentId="wallet-container" childId="wallet-child-content">
        <Box id="wallet-child-content" sx={{ minWidth: '600px' }}>
          <Typography
            sx={{
              borderBottom: '1px solid #ded9d9',
              width: '100%',
              paddingBottom: '10px',
              paddingTop: '6px',
              marginBottom: '20px',
            }}
            variant="h4"
            color="secondary.main"
            fontSize={'18px'}
            fontWeight={'600'}
          >
            {walletFuncText}
          </Typography>
          <Stack
            spacing={1}
            sx={{
              border: '2px solid #ccc',
              borderRadius: '4px',
              padding: '8px',
              '& .MuiFormControl-root': {
                flex: 1,
              },
              '&  .MuiInputBase-root': {
                borderRadius: '3px',
                backgroundColor: '#fff',
              },
              '& input': {
                fontSize: '12px',
                height: '22px',
                padding: '0px 5px',
              },
            }}
          >
            <Box
              sx={{
                display: 'flex',
                marginBottom: '6px',
                alignItems: 'center',
                gap: '12px',
              }}
            >
              <Typography
                sx={{
                  color: '#4a494a',
                  fontWeight: '600',
                  fontSize: '12px',
                  width: '20%',
                  textAlign: 'right',
                }}
              >
                Account:
              </Typography>
              <TextField
                variant="outlined"
                size="small"
                value={customer?.account}
                InputProps={{
                  readOnly: true,
                }}
                sx={{ maxWidth: '100px' }}
              />
            </Box>
            <Box
              sx={{
                display: 'flex',
                marginBottom: '6px',
                alignItems: 'center',
                gap: '12px',
              }}
            >
              <Typography
                sx={{
                  color: '#4a494a',
                  fontWeight: '600',
                  fontSize: '12px',
                  width: '20%',
                  textAlign: 'right',
                }}
              >
                Customer balance:
              </Typography>
              <TextField
                type="number"
                variant="outlined"
                size="small"
                value={customer?.balance.toFixed(2)}
                InputProps={{
                  readOnly: true,
                }}
                sx={{ maxWidth: '100px' }}
              />
            </Box>
            <Box
              sx={{
                display: 'flex',
                marginBottom: '6px',
                alignItems: 'center',
                gap: '12px',
              }}
            >
              <Typography
                sx={{
                  color: '#4a494a',
                  fontWeight: '600',
                  fontSize: '12px',
                  width: '20%',
                  textAlign: 'right',
                }}
              >
                Available balance:
              </Typography>
              <TextField
                type="number"
                variant="outlined"
                size="small"
                value={userInfo?.balance.toFixed(2)}
                InputProps={{
                  readOnly: true,
                }}
                sx={{ maxWidth: '100px' }}
              />
            </Box>
            <Box
              sx={{
                display: 'flex',
                marginBottom: '6px',
                alignItems: 'center',
                gap: '12px',
              }}
            >
              <Typography
                sx={{
                  color: '#4a494a',
                  fontWeight: '600',
                  fontSize: '12px',
                  width: '20%',
                  textAlign: 'right',
                }}
              >
                {walletFuncText}:
              </Typography>
              <TextField
                type="number"
                variant="outlined"
                size="small"
                value={amount}
                onChange={(e) => setAmount(Number(e.target.value))}
                InputProps={{
                  inputProps: {
                    min: 0,
                  },
                }}
                sx={{ maxWidth: '160px' }}
                required
                focused
              />
            </Box>
            <Box
              sx={{
                display: 'flex',
                marginBottom: '6px',
                alignItems: 'center',
                gap: '12px',
              }}
            >
              <Typography
                sx={{
                  color: '#4a494a',
                  fontWeight: '600',
                  fontSize: '12px',
                  width: '20%',
                  textAlign: 'right',
                }}
              >
                Note:
              </Typography>
              <TextField
                variant="outlined"
                multiline
                maxRows={4}
                onChange={(e) => setNote(e.target.value)}
                value={note}
                sx={{ maxWidth: '308px' }}
              />
            </Box>

            <LoadingButton
              loading={isLoading?.usedFor === 'userActions' && isLoading.status}
              variant="contained"
              type="submit"
              onClick={handleSubmit}
              disabled={amount <= 0}
              sx={{
                borderRadius: '4px',
                width: 'fit-content',
                marginLeft: '21% !important',
                fontSize: 14,
                height: 30,
                textTransform: 'capitalize',
                padding: ' 0px 6px',
                backgroundColor: '#007dce',
              }}
            >
              {walletFuncText}
            </LoadingButton>
          </Stack>
        </Box>
      </ScaleContainer>
    </Container>
  )
}

export default Wallet
