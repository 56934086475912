import React from 'react'
import {
  Button,
  Switch,
  Card,
  Skeleton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material'
import { styled } from '@mui/material/styles'
import { tableCellClasses } from '@mui/material/TableCell'
import { GameInterface } from 'stores/game/game-slice'
import { removeGame, updateGameStatus } from 'stores/game/game-action'
import moment from 'moment'
import { useSelector, useDispatch } from 'react-redux'
import { RootState } from 'stores'
import { useNavigate } from 'react-router'
import { removeHtmlTags } from 'utils'
import { STATUSES } from 'constants/index'
import TablePagination from 'app/web/components/TablePagination'
import ScaleContainer from 'app/dashboard/components/ScaleContainer'

const tableCellOptions: string[] = [
  'ID',
  'Title',
  'Code',
  'Description',
  'Created At',
  'Status',
  'Actions',
]

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    fontWeight: 'bold',
    fontSize: 16,
  },

  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    fontWeight: 600,
  },
}))

interface IDisplayGame {
  games: GameInterface[]
  paginationInfo: any
  handleChangeRowsPerPage: (event: any) => void
  handlePageChange: (event: any, page: number) => void
}

const DisplayGame: React.FC<IDisplayGame> = ({
  games,
  handlePageChange,
  handleChangeRowsPerPage,
  paginationInfo,
  ...rest
}) => {
  const { isLoading } = useSelector((state: RootState) => state.ui)
  const { userList = {} as any } = useSelector((state: RootState) => state.user)
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const handleDeleteGame = (gameId: string) => {
    const confirm = window.confirm('Are you sure you want to delete?')
    if (!confirm) {
      return
    }
    dispatch(removeGame(gameId))
  }

  const handleEdit = (gameId: string) => {
    return navigate(`/app/edit/game/${gameId}`, { replace: true })
  }

  const toggleGameStatus = (gameId: string) => {
    dispatch(updateGameStatus(gameId))
  }

  return (
    <Card {...rest} sx={{ borderRadius: '0px', boxShadow: 'none' }}>
      <ScaleContainer
        key={`${isLoading?.status}-${isLoading?.usedFor}-${userList.length}`}
        parentId="manage-games-table-container"
        childId="manage-games-table"
        sx={{
          '& table': {
            '& th, td': {
              fontSize: 14,
            },
          },
        }}
      >
        <Table id="manage-games-table" sx={{ borderRadius: 0, border: '1px solid #ccc' }}>
          <TableHead>
            <TableRow
              sx={{
                height: '31px',
                borderBottom: '1px solid #c7c7c7 !important',
              }}
            >
              {tableCellOptions.map((item, index) => (
                <StyledTableCell
                  key={index}
                  sx={{
                    padding: 0,
                    backgroundColor: '#3398cc !important',
                    border: '1px solid #c7c7c7',
                  }}
                  align={['Actions', 'Status'].includes(item) ? 'center' : 'inherit'}
                >
                  {item}
                </StyledTableCell>
              ))}
            </TableRow>
          </TableHead>

          {isLoading?.status && isLoading.usedFor === 'game' ? (
            <TableBody>
              <TableRow
                sx={{
                  height: 'fit-content',
                  '& td': {
                    position: 'relative',
                    padding: '6px 0px',
                    color: '#000',
                    cursor: 'pointer',
                    border: '1px solid #c7c7c7',
                  },
                  '& td p': {
                    color: '#000',
                  },
                  '&:nth-of-type(even)': {
                    bgcolor: 'background.default',
                  },
                  '&:nth-of-type(odd)': {
                    bgcolor: 'background.paper',
                  },
                }}
              >
                {tableCellOptions.map((item) => (
                  <TableCell key={item}>
                    {Array.from(new Array(5)).map((_, index) => (
                      <Skeleton key={index} height={72} animation="wave" />
                    ))}
                  </TableCell>
                ))}
              </TableRow>
            </TableBody>
          ) : (
            <TableBody>
              {games.map((game: GameInterface) => (
                <TableRow
                  hover
                  key={game._id}
                  sx={{
                    height: 'fit-content',
                    '& td': {
                      position: 'relative',
                      padding: '6px',
                      color: '#000',
                      cursor: 'pointer',
                      fontSize: '13px !important',
                      border: '1px solid #c7c7c7',
                    },
                    '& td p': {
                      color: '#000',
                    },
                    '&:nth-of-type(even)': {
                      bgcolor: 'background.default',
                    },
                    '&:nth-of-type(odd)': {
                      bgcolor: 'background.paper',
                    },
                  }}
                >
                  <TableCell width="150px">{game._id}</TableCell>

                  <TableCell width="160px">{game.title}</TableCell>

                  <TableCell width="150px">{game.code}</TableCell>

                  <TableCell>{removeHtmlTags(game.description)}</TableCell>

                  <TableCell width="132px">{moment(game.createdAt).format('DD/MM/YYYY')}</TableCell>

                  <TableCell align="center">
                    <Switch
                      checked={game.status === STATUSES.active}
                      onChange={() => toggleGameStatus(game._id as string)}
                    />
                  </TableCell>

                  <TableCell size="small" sx={{ width: '100px' }}>
                    <Stack
                      direction="row"
                      spacing={1}
                      sx={{
                        justifyContent: 'flex-end',
                      }}
                    >
                      <Button
                        variant="contained"
                        color="error"
                        size="small"
                        onClick={() => handleDeleteGame(game._id!)}
                        sx={{
                          borderRadius: '4px',
                          padding: '4px 10px',
                          textTransform: 'none',
                          fontWeight: '500',
                        }}
                      >
                        Delete
                      </Button>

                      <Button
                        color="secondary"
                        variant="contained"
                        sx={{
                          borderRadius: '4px',
                          padding: '4px 10px',
                          textTransform: 'none',
                          fontWeight: '500',
                        }}
                        // endIcon={<SendIcon />}
                        size="small"
                        onClick={() => handleEdit(game._id!)}
                      >
                        Edit
                      </Button>
                    </Stack>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          )}
        </Table>
      </ScaleContainer>

      <TablePagination
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        handlePageChange={handlePageChange}
        paginationInfo={paginationInfo}
      />
    </Card>
  )
}

export default DisplayGame
