import { useState, memo } from 'react'
import { useSelector } from 'react-redux'
import PriceCheckIcon from '@mui/icons-material/PriceCheck'
import ViewListIcon from '@mui/icons-material/ViewList'
import CreditScoreIcon from '@mui/icons-material/CreditScore'
import LockIcon from '@mui/icons-material/Lock'
import PermDeviceInformationIcon from '@mui/icons-material/PermDeviceInformation'
import ModalContainer from './ModalContainer'
import Wallet, { WalletEnum } from './Wallet'
import ResetPassword from './ResetPassword'
import TransactionRecord from '../pages/transactions/TransactionRecord'
// import GameRecord from '../pages/games/GameRecord'
import { RootState } from 'stores/index'
// import SportsEsportsIcon from '@mui/icons-material/SportsEsports'
import AttachMoneyIcon from '@mui/icons-material/AttachMoney'
import { IUserState } from 'stores/user/user-slice'
import { Box, Button } from '@mui/material'

interface OptionProps {
  iconName: JSX.Element
  menuText: string
  color: 'error' | 'warning' | 'info' | 'success' | 'secondary'
}

const options: OptionProps[] = [
  { iconName: <ViewListIcon color="inherit" />, menuText: 'JP Record', color: 'secondary' },
  { iconName: <PriceCheckIcon color="inherit" />, menuText: 'Recharge', color: 'error' },
  { iconName: <CreditScoreIcon color="inherit" />, menuText: 'Redeem', color: 'info' },
  { iconName: <LockIcon color="inherit" />, menuText: 'Reset Password', color: 'secondary' },
  {
    iconName: <PermDeviceInformationIcon color="inherit" />,
    menuText: 'Device: Unbind',
    color: 'secondary',
  },
  {
    iconName: <AttachMoneyIcon color="inherit" />,
    menuText: 'Transaction Record',
    color: 'secondary',
  },
  // { iconName: <SportsEsportsIcon color="inherit" />, menuText: 'Game Record', color: 'error' },
]

let targetUserInfo: IUserState[]

const MoreActions = ({
  customerId,
  adminId,
  menuItems = options,
}: {
  customerId?: string
  adminId?: string
  menuItems?: OptionProps[]
}): JSX.Element | null => {
  const [selectedMenu, setSelectedMenu] = useState<null | OptionProps>(null)
  const [isRechargeModalOpen, setIsRechargeModalOpen] = useState(false)
  const [isRedeemModalOpen, setIsRedeemModalOpen] = useState(false)
  const [isResetPasswordModalOpen, setIsResetPasswordModalOpen] = useState(false)
  const [isTransactionRecordModalOpen, setIsTransactionRecordModalOpen] = useState(false)
  // const [isGameRecordModalOpen, setIsGameRecordModalOpen] = useState(false)

  const { userList, adminList } = useSelector((state: RootState) => state.user)

  if (customerId) {
    targetUserInfo = userList?.filter((customer) => customer._id === customerId) as IUserState[]
  }

  if (adminId) {
    targetUserInfo = adminList?.filter((admin) => admin._id === adminId) as IUserState[]
  }

  const handleMenuItemClick = (item: OptionProps) => () => {
    setSelectedMenu(item)
    if (item.menuText.trim().toLowerCase() === WalletEnum.Recharge.trim().toLowerCase()) {
      setIsRechargeModalOpen(true)
    } else if (item.menuText.trim().toLowerCase() === WalletEnum.Redeem.trim().toLowerCase()) {
      setIsRedeemModalOpen(true)
    } else if (item.menuText.trim().toLowerCase() === options[3].menuText.trim().toLowerCase()) {
      setIsResetPasswordModalOpen(true)
    } else if (item.menuText.trim().toLowerCase() === options[5].menuText.trim().toLowerCase()) {
      setIsTransactionRecordModalOpen(true)
    }
    // } else if (item.menuText.trim().toLowerCase() === options[6].menuText.trim().toLowerCase()) {
    //   setIsGameRecordModalOpen(true)
    // }
  }

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          columnGap: '40px',
          rowGap: '18px',
          padding: '12px',
          '@media (max-width: 1065px)': {
            columnGap: '20px',
          },
          '@media (max-width: 895px)': {
            columnGap: '14px',
            rowGap: '14px',
            paddingLeft: 0,
          },
        }}
      >
        {menuItems.map((item, index) => (
          <Button
            key={index}
            variant="contained"
            color={item.color}
            onClick={handleMenuItemClick(item)}
            sx={{
              textTransform: 'none',
              padding: '6px 12px',
              fontWeight: '500',
              borderRadius: '4px',
            }}
            startIcon={item.iconName}
          >
            {item.menuText}
          </Button>
        ))}
      </Box>

      {selectedMenu?.menuText.trim().toLowerCase() === WalletEnum.Recharge.trim().toLowerCase() && (
        <ModalContainer
          modalOpen={isRechargeModalOpen}
          handleModalClose={() => setIsRechargeModalOpen(false)}
        >
          <Wallet walletFuncText={WalletEnum.Recharge} customer={targetUserInfo[0]} />
        </ModalContainer>
      )}

      {selectedMenu?.menuText.trim().toLowerCase() === WalletEnum.Redeem.trim().toLowerCase() && (
        <ModalContainer
          modalOpen={isRedeemModalOpen}
          handleModalClose={() => setIsRedeemModalOpen(false)}
        >
          <Wallet walletFuncText={WalletEnum.Redeem} customer={targetUserInfo[0]} />
        </ModalContainer>
      )}

      {selectedMenu?.menuText.trim().toLowerCase() === options[3].menuText.trim().toLowerCase() && (
        <ModalContainer
          modalOpen={isResetPasswordModalOpen}
          height="540px"
          handleModalClose={() => setIsResetPasswordModalOpen(false)}
        >
          <ResetPassword customer={targetUserInfo[0]} />
        </ModalContainer>
      )}

      {selectedMenu?.menuText.trim().toLowerCase() === options[5].menuText.trim().toLowerCase() && (
        <ModalContainer
          modalOpen={isTransactionRecordModalOpen}
          handleModalClose={() => setIsTransactionRecordModalOpen(false)}
        >
          <TransactionRecord customerId={targetUserInfo[0]._id} />
        </ModalContainer>
      )}

      {/* {selectedMenu?.menuText.trim().toLowerCase() === options[6].menuText.trim().toLowerCase() && (
        <ModalContainer
          modalOpen={isGameRecordModalOpen}
          handleModalClose={() => setIsGameRecordModalOpen(false)}
        >
          <GameRecord customerId={targetUserInfo[0]._id} />
        </ModalContainer>
      )} */}
    </>
  )
}
export default memo(MoreActions, () => false)
