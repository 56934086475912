import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface JpScore {
  accumulatedGrandJP: number
  accumulatedMajorJP: number
  accumulatedMinorJP: number
  accumulatedMiniJP: number
}

const initialState = {
  jpScore: {
    accumulatedGrandJP: 0,
    accumulatedMajorJP: 0,
    accumulatedMiniJP: 0,
    accumulatedMinorJP: 0,
  },
}

const jpSlice = createSlice({
  name: 'jp',
  initialState,
  reducers: {
    setJp(state, action: PayloadAction<JpScore>) {
      state.jpScore = action.payload
    },
  },
})

export const jpActions = jpSlice.actions
export default jpSlice.reducer
