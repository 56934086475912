import React, { useEffect } from 'react'
import { Box, Container } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'stores'
import PageTitle from 'app/dashboard/components/PageTitle'
import { getAllIssues, getMyIssues } from 'stores/issues/issues-action'
import { IIssueState } from 'stores/issues/issues-slice'
import ScaleContainer from 'app/dashboard/components/ScaleContainer'
import Toolbar from './Toolbar'
import IssueTable from './IssueTable'
import { UserRoleEnum } from 'stores/auth/auth-slice'

const IssuesList: React.FC = () => {
  const dispatch = useDispatch()
  const [searchText, setSearchText] = React.useState('')
  const [paginationInfo, setPaginationInfo] = React.useState({
    currentPage: 1,
    lastPage: false,
    pageSize: 10,
    totalPage: 0,
    totalRecord: 0,
  })

  const { issuesList = {} as any } = useSelector((state: RootState) => state.issue)
  const { isLoading } = useSelector((state: RootState) => state.ui)
  const handlePageChange = (_event: any, newPage: number) => {
    setPaginationInfo((value) => ({
      ...value,
      currentPage: newPage,
    }))
  }

  const handleSearch = (searchKey: string) => {
    setSearchText(searchKey)
    setPaginationInfo((value) => ({
      ...value,
      currentPage: 1,
    }))
  }

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPaginationInfo((value) => ({
      ...value,
      pageSize: +event.target.value,
    }))
  }
  const { userInfo } = useSelector((state: RootState) => state.user)

  const isSuperAdmin = [UserRoleEnum.SUPERADMIN].includes(userInfo?.role as UserRoleEnum)

  useEffect(() => {
    const fetch = async () => {
      const data = (await dispatch(
        isSuperAdmin
          ? getAllIssues(paginationInfo?.currentPage, paginationInfo?.pageSize, searchText)
          : getMyIssues(paginationInfo?.currentPage, paginationInfo?.pageSize)
      )) as any
      data && setPaginationInfo(data)
    }

    if (userInfo?.role) fetch()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paginationInfo?.currentPage, paginationInfo?.pageSize, searchText, userInfo?.role])

  return (
    <Box>
      <PageTitle pageTitle="Issue Reports | Galaxy GamesGroup" />

      <Container maxWidth={false} sx={{ padding: '0px !important' }}>
        <ScaleContainer
          key={`${isLoading?.status}--${isLoading?.usedFor}-${issuesList.length}`}
          parentId="issues-page-container"
          childId="issues-page-content"
        >
          <Box id="issues-page-content">
            <Box
              sx={{
                backgroundColor: 'background.default',
                border: '2px solid #ccc',
                borderRadius: '4px',
              }}
            >
              <Toolbar handleSubmit={handleSearch} />
            </Box>

            <Box
              sx={{
                backgroundColor: 'background.default',
                border: '2px solid #ccc',
                borderRadius: '4px',
                marginTop: '14px',
                padding: '12px',
              }}
            >
              <IssueTable
                results={issuesList as IIssueState[]}
                handlePageChange={handlePageChange}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
                paginationInfo={paginationInfo}
              />

              <br />
            </Box>
          </Box>
        </ScaleContainer>
      </Container>
    </Box>
  )
}

export default IssuesList
