import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { Box, Card, CardContent, TextField } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { RootState } from 'stores'
import ScaleContainer from 'app/dashboard/components/ScaleContainer'

interface SearchbarProps {
  handleSubmit: (searchText: string) => void
}

const IssueSearch: React.FC<SearchbarProps> = ({ handleSubmit }) => {
  const [searchText, setSearchText] = useState('')
  const { isLoading } = useSelector((state: RootState) => state.ui)
  const { userList = {} as any } = useSelector((state: RootState) => state.user)

  const handleFormSubmit = (e: any) => {
    handleSubmit(searchText)
    e.preventDefault()
  }

  return (
    <Box component="form" onSubmit={handleFormSubmit} sx={{ flex: '1' }}>
      <ScaleContainer
        key={`${isLoading?.status}--${isLoading?.usedFor}-${userList.length}`}
        parentId="search-bar-container"
        childId="search-bar-content"
      >
        <Card sx={{ p: 0, boxShadow: 'none', borderRadius: 0, ml: 1.5 }} id="search-bar-content">
          <CardContent sx={{ p: '10px 0px !important', backgroundColor: 'background.default' }}>
            <Box
              sx={{
                display: 'flex',
                gap: '12px',
                maxWidth: { md: '95%' },
              }}
            >
              <TextField
                placeholder="Store"
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
                size="small"
                sx={{
                  maxWidth: { xs: '45%', lg: '40%' },
                  borderRadius: '3px',
                  '& input': {
                    fontSize: '13px',
                    padding: '7px 14px',
                  },
                  backgroundColor: '#fff',
                  '&  .MuiInputBase-root': {
                    borderRadius: '3px',
                  },
                  '& fieldset': {
                    borderColor: '#7f9db9 !important',
                  },
                }}
                fullWidth
                required
                focused
              />

              <LoadingButton
                variant="contained"
                type="submit"
                loading={isLoading?.status && isLoading?.usedFor === 'global'}
                sx={{
                  fontWeight: '500',
                  color: 'white',
                  fontSize: '14px',
                  borderRadius: '4px',
                  backgroundColor: 'secondary.main',
                  textTransform: 'none',
                  padding: '6px 12px',
                  height: 34,
                }}
              >
                Search
              </LoadingButton>
            </Box>
          </CardContent>
        </Card>
      </ScaleContainer>
    </Box>
  )
}

export default IssueSearch
