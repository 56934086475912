import api from 'config/apiConfig'
import {
  gameAnnouncementActions,
  AnnouncementInterface,
} from 'stores/announcement/announcement-slice'
import { ServerError } from 'stores/auth/auth-actions'
import { Action, Dispatch } from 'redux'
import { AxiosError } from 'axios'
import { handleErrorResponse } from 'app/dashboard/utils/errorHandler'
import { uiActions } from '../ui/ui-slice'

export const getAnnouncement =
  () =>
  async (dispatch: Dispatch<Action>): Promise<void> => {
    try {
      dispatch(uiActions.setLoading({ status: true, usedFor: 'game' }))
      const { data } = await api.get<AnnouncementInterface[]>('/all/announcement')
      dispatch(gameAnnouncementActions.setAnnouncement(data))
    } catch (error) {
      if (error) {
        const serverError = error as AxiosError<ServerError>
        handleErrorResponse(serverError, dispatch)
      }
    } finally {
      dispatch(uiActions.setLoading({ status: false, usedFor: 'game' }))
    }
  }

export const createAnnouncement =
  (announcement: AnnouncementInterface) =>
  async (dispatch: Dispatch<Action>): Promise<void> => {
    try {
      dispatch(uiActions.setLoading({ status: true, usedFor: 'game' }))
      const { data } = await api.post<AnnouncementInterface>('/create/announcement', announcement)
      dispatch(gameAnnouncementActions.pushAnnouncement(data))
      dispatch(
        uiActions.showNotification({
          status: 200,
          title: 'success',
          msg: 'Announcement successfully created!',
        })
      )
    } catch (error) {
      if (error) {
        const serverError = error as AxiosError<ServerError>
        handleErrorResponse(serverError, dispatch)
      }
    } finally {
      dispatch(uiActions.setLoading({ status: false, usedFor: 'game' }))
      setTimeout(() => {
        dispatch(uiActions.hideNotification())
      }, 3000)
    }
  }

export const removeAnnouncement =
  (announcementId: string) =>
  async (dispatch: Dispatch<Action>): Promise<void> => {
    try {
      await api.delete(`/delete/announcement/${announcementId}`)
      dispatch(gameAnnouncementActions.removeAnnouncement(announcementId))
    } catch (error) {
      if (error) {
        const serverError = error as AxiosError<ServerError>
        handleErrorResponse(serverError, dispatch)
      }
    }
  }

export const toggleAnnouncementState =
  (announcementId: string) =>
  async (dispatch: Dispatch<Action>): Promise<void> => {
    try {
      const { data } = await api.put<AnnouncementInterface>(
        `/toggle/openState/announcement/${announcementId}`
      )
      dispatch(gameAnnouncementActions.updateAnnouncement(data))
    } catch (error) {
      if (error) {
        const serverError = error as AxiosError<ServerError>
        handleErrorResponse(serverError, dispatch)
      }
    }
  }

export const updateAnnouncement =
  (announcement: AnnouncementInterface, announcementId: string) =>
  async (dispatch: Dispatch<Action>): Promise<void> => {
    try {
      dispatch(uiActions.setLoading({ status: true, usedFor: 'game' }))
      const { data } = await api.put<AnnouncementInterface>(
        `/update/announcement/${announcementId}`,
        announcement
      )
      dispatch(gameAnnouncementActions.updateAnnouncement(data))
      dispatch(
        uiActions.showNotification({
          status: 200,
          title: 'success',
          msg: 'Modified Successfully!',
        })
      )
    } catch (error) {
      if (error) {
        const serverError = error as AxiosError<ServerError>
        handleErrorResponse(serverError, dispatch)
      }
    } finally {
      dispatch(uiActions.setLoading({ status: false, usedFor: 'game' }))
      setTimeout(() => {
        dispatch(uiActions.hideNotification())
      }, 6000)
    }
  }
