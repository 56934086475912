import React, { Fragment } from 'react'
import { Box, Container } from '@mui/material'
import BackButton from 'app/dashboard/components/BackButton'
import PageTitle from 'app/dashboard/components/PageTitle'
import AddNew from './AddNew'
import { IActionType } from 'app/dashboard/pages/category/AddNew'

const index: React.FC<IActionType> = ({ actionType = 'add' }) => {
  return (
    <Fragment>
      <PageTitle
        pageTitle={`${
          actionType === 'add' ? 'Create Announcement' : 'Edit Announcement'
        } | Galaxy GamesGroup`}
      />
      <Container maxWidth={false}>
        <Box my={2}>
          <AddNew actionType={actionType} />
        </Box>
      </Container>
    </Fragment>
  )
}

export default index
