import React from 'react'
import {
  Button,
  Card,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Skeleton,
} from '@mui/material'
import { styled } from '@mui/material/styles'
import { tableCellClasses } from '@mui/material/TableCell'
import { CategoryInterface } from 'stores/game/game-category-slice'
import { removeCategory } from 'stores/game/game-category-actions'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import moment from 'moment'
import { RootState } from 'stores'
import Notification from 'app/dashboard/components/Notifications'
import TablePagination from 'app/web/components/TablePagination'
import ScaleContainer from 'app/dashboard/components/ScaleContainer'

const tableCellOptions: string[] = ['ID', 'Name', 'Created At', 'Actions']

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    fontWeight: 'bold',
    fontSize: 16,
  },

  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    fontWeight: 600,
  },
}))

interface ICategoryInterface {
  categories: CategoryInterface[]
  handleChangeRowsPerPage: (e: any) => void
  handlePageChange: (e: any, n: number) => void
  paginationInfo: any
}

const DisplayCategory: React.FC<ICategoryInterface> = ({
  categories,
  paginationInfo,
  handlePageChange,
  handleChangeRowsPerPage,
  ...rest
}) => {
  const { userList = {} as any } = useSelector((state: RootState) => state.user)
  const { isLoading, notification } = useSelector((state: RootState) => state.ui)
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const handleDeleteCategory = (categoryId: string) => {
    const confirm = window.confirm('Are you sure you want to delete?')
    if (!confirm) {
      return
    }
    dispatch(removeCategory(categoryId))
  }

  const handleEdit = (categoryId: string) => {
    return navigate(`/app/edit/category/${categoryId}`, { replace: true })
  }

  return (
    <Card {...rest} sx={{ mt: 2, mb: 3, borderRadius: '0px', boxShadow: 'none' }}>
      {notification?.status === 502 && notification.title === 'error' && (
        <Notification notiType="error" msg={notification.msg} />
      )}

      <ScaleContainer
        key={`${isLoading?.status}-${isLoading?.usedFor}-${userList.length}`}
        parentId="manage-category-table-container"
        childId="manage-category-table"
        sx={{
          '& table': {
            '& th, td': {
              fontSize: 13,
            },
          },
        }}
      >
        <Table id="manage-category-table">
          <TableHead>
            <TableRow
              sx={{
                height: '31px',
                borderBottom: '1px solid #c7c7c7 !important',
              }}
            >
              {tableCellOptions.map((item, index) => (
                <StyledTableCell
                  sx={{
                    padding: 0,
                    backgroundColor: '#3398cc !important',
                    border: '1px solid #c7c7c7',
                  }}
                  key={index}
                  align={item === 'Actions' ? 'center' : 'inherit'}
                >
                  {item}
                </StyledTableCell>
              ))}
            </TableRow>
          </TableHead>

          {isLoading?.status && isLoading.usedFor === 'game' ? (
            <TableBody>
              <TableRow>
                {tableCellOptions.map((item) => (
                  <TableCell key={item}>
                    {Array.from(new Array(5)).map((_, index) => (
                      <Skeleton key={index} height={72} animation="wave" />
                    ))}
                  </TableCell>
                ))}
              </TableRow>
            </TableBody>
          ) : (
            <TableBody>
              {categories.map((category: CategoryInterface) => (
                <TableRow
                  hover
                  key={category._id}
                  sx={{
                    height: 'fit-content',
                    '& td': {
                      position: 'relative',
                      padding: '6px',
                      color: '#000',
                      cursor: 'pointer',
                      border: '1px solid #c7c7c7',
                    },
                    '& td p': {
                      color: '#000',
                    },
                    '&:nth-of-type(even)': {
                      bgcolor: 'background.default',
                    },
                    '&:nth-of-type(odd)': {
                      bgcolor: 'background.paper',
                    },
                  }}
                >
                  <TableCell width={300}>{category._id}</TableCell>

                  <TableCell>{category.name}</TableCell>

                  <TableCell>{moment(category.createdAt).format('DD/MM/YYYY')}</TableCell>

                  <TableCell size="small" width={100}>
                    <Stack
                      direction="row"
                      spacing={1}
                      sx={{
                        justifyContent: 'flex-end',
                      }}
                    >
                      <Button
                        variant="contained"
                        color="error"
                        // startIcon={<DeleteIcon />}
                        size="small"
                        onClick={() => handleDeleteCategory(category._id)}
                        sx={{
                          borderRadius: '4px',
                          padding: '4px 10px',
                          textTransform: 'none',
                        }}
                      >
                        Delete
                      </Button>

                      <Button
                        color="secondary"
                        variant="contained"
                        // endIcon={<SendIcon />}
                        size="small"
                        onClick={() => handleEdit(category._id)}
                        sx={{
                          borderRadius: '4px',
                          padding: '4px 10px',
                          textTransform: 'none',
                        }}
                      >
                        Edit
                      </Button>
                    </Stack>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          )}
        </Table>
      </ScaleContainer>

      <TablePagination
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        handlePageChange={handlePageChange}
        paginationInfo={paginationInfo}
      />
    </Card>
  )
}

export default DisplayCategory
