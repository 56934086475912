import React from 'react'
import Modal from '@mui/material/Modal'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'

interface ModalContainerProps {
  children: JSX.Element
  modalOpen: boolean
  handleModalClose: () => void
  width?: string
  height?: string
}

const ModalContainer: React.FC<ModalContainerProps> = ({
  children,
  modalOpen,
  handleModalClose,
  height = '500px',
  width = '800px',
}) => {
  return (
    <Modal open={modalOpen} onClose={handleModalClose} sx={{ marginTop: '5rem' }}>
      <Box
        component="form"
        sx={{
          padding: '2rem 1.25rem',
          backgroundColor: 'background.default',
          height: { sm: '320px', md: height },
          maxWidth: width,
          boxShadow: 10,
          mx: { md: 'auto' },
          position: 'relative',
          display: 'flex',
          borderRadius: '10px',
          overflow: 'scroll',
        }}
      >
        <IconButton
          color="primary"
          onClick={handleModalClose}
          sx={{ position: 'absolute', right: 1, top: 1 }}
        >
          <CloseIcon />
        </IconButton>
        {children}
      </Box>
    </Modal>
  )
}

export default ModalContainer
