import api from 'config/apiConfig'
import { versionActions, VersionInterface } from './version-slice'
import { ServerError } from 'stores/auth/auth-actions'
import { Action, Dispatch } from 'redux'
import { AxiosError } from 'axios'
import { handleErrorResponse } from 'app/dashboard/utils/errorHandler'
import { uiActions } from '../ui/ui-slice'

export const createVersion =
  (version: string, description: string, testing: boolean) =>
  async (dispatch: Dispatch<Action>): Promise<void> => {
    try {
      dispatch(uiActions.setLoading({ status: true, usedFor: 'version' }))
      const { data } = await api.post<VersionInterface>('/version', {
        version,
        testing,
        description,
      })
      dispatch(versionActions.pushVersion(data))
      dispatch(
        uiActions.showNotification({
          status: 200,
          title: 'success',
          msg: 'Version successfully created!',
        })
      )
    } catch (error) {
      if (error) {
        const serverError = error as AxiosError<ServerError>
        handleErrorResponse(serverError, dispatch)
      }
    } finally {
      dispatch(uiActions.setLoading({ status: false, usedFor: 'version' }))
      setTimeout(() => {
        dispatch(uiActions.hideNotification())
      }, 3000)
    }
  }

export const getGameVersion =
  () => async (dispatch: Dispatch<Action>): Promise<void> => {
    try {
      dispatch(uiActions.setLoading({ status: true, usedFor: 'version' }))
      const { data } = await api.get<VersionInterface[]>('/version')
      dispatch(versionActions.getGameVersion(data))
    } catch (error) {
      if (error) {
        const serverError = error as AxiosError<ServerError>
        handleErrorResponse(serverError, dispatch)
      }
    } finally {
      dispatch(uiActions.setLoading({ status: false, usedFor: 'version' }))
    }
  }