import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface IDashboardOverviewState {
  totalRevenue: number
  totalRecharge: number
  totalRedeem: number
  totalHoldings: number
  holdingPercentage: number
  totalPlayers: number
}

interface IPlayerState {
  userId: string
  userNickName: string
  managerId: string
  managerName: string
  lastPlayedTime: string
  currentBalance: number
  amountBetTotal: number
  totalTimeInSeconds: number
  totalProfit: number
}

interface IGameState {
  title: string
  status: 'active' | 'inactive'
  amountBetTotal: number
  totalTimeInSeconds: number
  totalProfit: number
}

interface IPlayTimeState {
  id: string
  totalTimeInSeconds: number
}

interface IRechargeRedeemState {
  id: string
  recharge: number
  redeem: number
}

interface IManagerState {
  totalCount: number,
  managerName: string,
  managerId: string,
  admins: null
}

interface IState {
  currentOverviewDashboard: IDashboardOverviewState
  previousOverviewDashboard: IDashboardOverviewState
  activeWisePlayerStat: IPlayerState[]
  profitWisePlayerStat: IPlayerState[]
  balanceWisePlayerStat: IPlayerState[]
  betAmountWisePlayerStat: IPlayerState[]
  betAmountWiseGameStat: IGameState[]
  activeWiseGameStat: IGameState[]
  profitWiseGameStat: IGameState[]
  totalPlayerManager: IManagerState[]
  totalActivePlayerManager: IManagerState[]
  maxHoldingManager: IManagerState[]
  playTimeStat: IPlayTimeState
  rechargeRedeemStat: IRechargeRedeemState
}

const initialState: IState = {
  currentOverviewDashboard: {
    totalRevenue: 0,
    totalRecharge: 0,
    totalRedeem: 0,
    totalHoldings: 0,
    holdingPercentage: 0,
    totalPlayers: 0,
  },
  previousOverviewDashboard: {
    totalRevenue: 0,
    totalRecharge: 0,
    totalRedeem: 0,
    totalHoldings: 0,
    holdingPercentage: 0,
    totalPlayers: 0,
  },
  activeWisePlayerStat: [],
  profitWisePlayerStat: [],
  balanceWisePlayerStat: [],
  betAmountWisePlayerStat: [],
  betAmountWiseGameStat: [],
  activeWiseGameStat: [],
  profitWiseGameStat: [],
  playTimeStat: {
    id: '',
    totalTimeInSeconds: 0,
  },
  rechargeRedeemStat: {
    id: '',
    recharge: 0,
    redeem: 0,
  },
  totalPlayerManager: [],
  totalActivePlayerManager: [],
  maxHoldingManager: []
}
  
const DashboardSlice = createSlice({
  name: 'dashboard',
  initialState: initialState,
  reducers: {
    setDashboard(state, action: PayloadAction<IDashboardOverviewState>) {
      state.currentOverviewDashboard = action.payload
      state.previousOverviewDashboard = action.payload
    },
    setPlayerList(state, action: PayloadAction<IState>) {
      state.activeWisePlayerStat = action.payload.activeWisePlayerStat
      state.profitWisePlayerStat = action.payload.profitWisePlayerStat
      state.balanceWisePlayerStat = action.payload.balanceWisePlayerStat
      state.betAmountWisePlayerStat = action.payload.betAmountWisePlayerStat
    },
    setManagerList(state, action: PayloadAction<IState>) {
      state.totalPlayerManager = action.payload.totalPlayerManager
      state.totalActivePlayerManager = action.payload.totalActivePlayerManager
      state.maxHoldingManager = action.payload.maxHoldingManager
    },
    setGameList(state, action: PayloadAction<IState>) {
      state.betAmountWiseGameStat = action.payload.betAmountWiseGameStat
      state.activeWiseGameStat = action.payload.activeWiseGameStat
      state.profitWiseGameStat = action.payload.profitWiseGameStat
    },
    setPlayTimeStat(state, action: PayloadAction<IPlayTimeState>) {
      state.playTimeStat = action.payload
    },
    setRechargeRedeemStat(state, action: PayloadAction<IRechargeRedeemState>) {
      state.rechargeRedeemStat = action.payload
    },
  },
})

export const dashboardActions = DashboardSlice.actions
export default DashboardSlice.reducer
