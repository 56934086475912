import { Card, Table, TableBody, TableCell, TableHead, TableRow, Skeleton } from '@mui/material'
import { useSelector } from 'react-redux'
import { RootState } from 'stores'
import { styled } from '@mui/material/styles'
import { tableCellClasses } from '@mui/material/TableCell'
import Notification from 'app/dashboard/components/Notifications'
import TablePagination from 'app/web/components/TablePagination'
import ScaleContainer from 'app/dashboard/components/ScaleContainer'
import { RTPInterface } from 'stores/rtp/rtp-slice'

const tableCellOptions = [
  'Parent Name',
  'Parent Role',
  'Pool Amount',
  'Bet Amount',
  'Win Amount',
  'Max Win',
  'Negative Pool',
]

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    fontWeight: 'bold',
    fontSize: 16,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    fontWeight: 600,
  },
}))

interface IRTP {
  rtps: RTPInterface[]
  handleChangeRowsPerPage: (e: any) => void
  handlePageChange: (e: any, n: number) => void
  paginationInfo: any
}

const Result: React.FC<IRTP> = ({
  rtps,
  paginationInfo,
  handlePageChange,
  handleChangeRowsPerPage,
  ...rest
}) => {
  const { userList = {} as any } = useSelector((state: RootState) => state.user)
  const { isLoading, notification } = useSelector((state: RootState) => state.ui)

  return (
    <Card {...rest} sx={{ my: 2, mb: 3, borderRadius: '0px', boxShadow: 'none' }}>
      {notification?.status === 502 && notification.title === 'error' && (
        <Notification notiType="error" msg={notification.msg} />
      )}

      <ScaleContainer
        key={`${isLoading?.status} - ${isLoading?.usedFor} - ${userList?.length}`}
        parentId="manage-rtp-table-container"
        childId="manage-rtp-table"
        sx={{
          '& table': {
            '& th, td': {
              fontSize: { xs: 24, sm: 14 },
            },
          },
        }}
      >
        <Table id="manage-rtp-table" sx={{ borderRadius: 0, border: '1px solid #ccc' }}>
          <TableHead sx={{ backgroundColor: '#fff', color: 'primary.dark' }}>
            <TableRow
              sx={{
                height: '31px',
                borderBottom: '1px solid #c7c7c7 !important',
              }}
            >
              {tableCellOptions.map((option, index) => (
                <StyledTableCell
                  key={index}
                  align={option === 'Actions' ? 'center' : 'inherit'}
                  sx={{
                    padding: 0,
                    backgroundColor: '#3398cc !important',
                    border: '1px solid #c7c7c7',
                  }}
                >
                  {option}
                </StyledTableCell>
              ))}
            </TableRow>
          </TableHead>

          {isLoading?.status && isLoading?.usedFor === 'rtp' ? (
            <TableBody>
              <TableRow>
                {tableCellOptions.map((item) => (
                  <TableCell key={item}>
                    {Array.from(new Array(5)).map((_, index) => (
                      <Skeleton key={index} variant="text" width="100%" />
                    ))}
                  </TableCell>
                ))}
              </TableRow>
            </TableBody>
          ) : (
            <TableBody>
              {rtps?.map((rtp: RTPInterface) => (
                <TableRow
                  key={rtp._id}
                  sx={{
                    height: 'fit-content',
                    '& td': {
                      position: 'relative',
                      padding: '6px 0px',
                      color: '#000',
                      fontSize: '12px !important',
                      cursor: 'pointer',
                      border: '1px solid #c7c7c7',
                      borderBottom: '1px solid #c7c7c7',
                    },
                    '& td p': {
                      color: '#000',
                    },
                    background: '#f5f5f5',
                  }}
                  hover
                >
                  <TableCell>{rtp.parentName}</TableCell>
                  <TableCell>{rtp.parentRole || 'subdistributor'}</TableCell>
                  <TableCell>{rtp.poolAmount}</TableCell>
                  <TableCell>{rtp.betAmount}</TableCell>
                  <TableCell>{rtp.winAmount}</TableCell>
                  <TableCell>{rtp.maxWin}</TableCell>
                  <TableCell>{rtp.negativePool || '0.0'}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          )}
        </Table>
      </ScaleContainer>

      <TablePagination
        paginationInfo={paginationInfo}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        handlePageChange={handlePageChange}
      />
    </Card>
  )
}

export default Result
