// @ts-nocheck
import React from 'react'
import { Box, Card, Typography, CardContent, TextField, Button } from '@mui/material'
import { RootState } from 'stores'
import { useSelector } from 'react-redux'
import { LoadingButton } from '@mui/lab'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { DemoContainer } from '@mui/x-date-pickers/internals/demo'
import SearchGameIdField from './SearchGameIdField'
import moment from 'moment'

const FilterComponent: React.FC<any> = ({ handlers, values }) => {
  const { isLoading } = useSelector((state: RootState) => state.ui)

  const {
    setAccountName,
    setManager,
    setStartDate,
    setEndDate,
    setTotalProfit,
    setTotalTimeInSeconds,
    setGameIdOption,
    handleReset,
  } = handlers

  const {
    accountName,
    manager,
    startDate,
    endDate,
    totalProfit,
    totalTimeInSeconds,
    gameIdOption,
  } = values

  const handleSearch = () => {
    handlers.handleSearch({
      accountName,
      manager,
      startDate,
      endDate,
      totalProfit,
      totalTimeInSeconds,
      gameIdOption,
    })
  }

  return (
    <React.Fragment>
      <DemoContainer components={['DatePicker']}>
        <Box
          component="form"
          onSubmit={(e) => {
            handleSearch()
            e.preventDefault()
          }}
        >
          <Card
            sx={{
              borderRadius: '0px',
              backgroundColor: 'background.default',
              boxShadow: 'none',
              padding: '0px',
            }}
            id="search-bar-content"
          >
            <CardContent
              sx={{
                padding: '10px 0px',
                paddingBottom: '0px !important',
                '& input': {
                  padding: '7px 14px',
                },
              }}
            >
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: '6px' }}>
                <Box
                  sx={{
                    display: 'flex',
                    columnGap: '10px',
                    rowGap: '8px',
                    mb: 1,
                    width: '100%',
                    flexWrap: 'wrap',
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      gap: '10px',
                      alignItems: 'center',
                      width: '32%',
                    }}
                  >
                    <Typography
                      sx={{
                        color: '#4a494a',
                        fontWeight: '600',
                        fontSize: '13px',
                        flex: 1,
                      }}
                    >
                      Account Name:
                    </Typography>
                    <TextField
                      value={accountName || ''}
                      fullWidth
                      onChange={(e) => setAccountName(e.target.value)}
                      size="small"
                      sx={{
                        backgroundColor: '#fff',
                        '&  .MuiInputBase-root': {
                          borderRadius: '3px',
                        },
                        '& input': {
                          fontSize: '13px',
                        },
                        flex: 4,
                      }}
                      focused
                    />
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      gap: '10px',
                      alignItems: 'center',
                      width: '32%',
                    }}
                  >
                    <Typography
                      sx={{ color: '#4a494a', fontWeight: '600', fontSize: '13px', flex: 1 }}
                    >
                      Store:
                    </Typography>
                    <TextField
                      value={manager || ''}
                      onChange={(e) => setManager(e.target.value)}
                      size="small"
                      sx={{
                        flex: 4,
                        backgroundColor: '#fff',
                        '&  .MuiInputBase-root': {
                          borderRadius: '3px',
                        },
                        '& input': {
                          fontSize: '13px',
                        },
                      }}
                      fullWidth
                      focused
                    />
                  </Box>

                  <Box
                    sx={{
                      '& .MuiFormControl-root': {
                        width: 'unset',
                        flex: 1,
                      },
                      width: '32%',
                    }}
                  >
                    <SearchGameIdField onChange={setGameIdOption} value={gameIdOption} />
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      gap: '10px',
                      alignItems: 'center',
                      width: '32%',
                    }}
                  >
                    <Typography
                      sx={{ color: '#4a494a', flex: 1, fontWeight: '600', fontSize: '13px' }}
                    >
                      Minimum Play Time:
                    </Typography>

                    <TextField
                      value={totalTimeInSeconds || ''}
                      onChange={(e) => setTotalTimeInSeconds(e.target.value)}
                      size="small"
                      sx={{
                        backgroundColor: '#fff',
                        flex: 4,
                        '&  .MuiInputBase-root': {
                          borderRadius: '3px',
                        },
                        '& input': {
                          fontSize: '13px',
                        },
                      }}
                      focused
                      fullWidth
                    />
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      gap: '10px',
                      alignItems: 'center',
                      width: '32%',
                    }}
                  >
                    <Typography
                      sx={{ color: '#4a494a', flex: 1, fontWeight: '600', fontSize: '13px' }}
                    >
                      Minimum Profit:
                    </Typography>

                    <TextField
                      value={totalProfit || ''}
                      onChange={(e) => setTotalProfit(e.target.value)}
                      size="small"
                      sx={{
                        flex: 4,
                        backgroundColor: '#fff',
                        '&  .MuiInputBase-root': {
                          borderRadius: '3px',
                        },
                        '& input': {
                          fontSize: '13px',
                        },
                      }}
                      fullWidth
                      focused
                    />
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      gap: '10px',
                      alignItems: 'center',
                      width: '32%',
                    }}
                  >
                    <Typography
                      sx={{
                        color: '#4a494a',
                        fontWeight: '600',
                        fontSize: '13px',
                        flex: 1,
                      }}
                    >
                      Period:
                    </Typography>
                    <DatePicker
                      format="YYYY-MM-DD"
                      value={moment(startDate) || ''}
                      onChange={(value) => setStartDate(moment(value).format('YYYY-MM-DD'))}
                      sx={{
                        '& input': {
                          padding: '8.5px 14px',
                          fontSize: '14px',
                        },

                        backgroundColor: '#fff',
                        '& .MuiInputBase-root': {
                          borderRadius: '3px',
                        },
                        '& svg': {
                          width: '16px',
                          height: '16px',
                        },
                      }}
                    />

                    <Typography
                      variant="body1"
                      sx={{ fontSize: '1.15rem', textAlign: 'center', fontWeight: 'bold' }}
                    >
                      ~
                    </Typography>

                    <DatePicker
                      format="YYYY-MM-DD"
                      value={moment(endDate) || ''}
                      onChange={(value) => setEndDate(moment(value).format('YYYY-MM-DD'))}
                      sx={{
                        '& input': {
                          padding: '8.5px 14px',
                          fontSize: '14px',
                        },

                        backgroundColor: '#fff',
                        '& .MuiInputBase-root': {
                          borderRadius: '3px',
                        },
                        '& svg': {
                          width: '16px',
                          height: '16px',
                        },
                      }}
                    />
                  </Box>

                  <Box
                    sx={{
                      display: 'flex',
                      gap: '10px',
                      alignItems: 'center',
                      marginLeft: '80px',
                    }}
                  >
                    <LoadingButton
                      variant="contained"
                      type="submit"
                      loading={isLoading?.status && isLoading?.usedFor === 'global'}
                      color="secondary"
                      sx={{
                        fontWeight: '500',
                        color: 'white',
                        fontSize: '14px',
                        borderRadius: '4px',
                        backgroundColor: 'secondary.main',
                        textTransform: 'none',
                        padding: '6px 12px',
                        height: 34,
                      }}
                    >
                      Search
                    </LoadingButton>
                    <Button
                      variant="contained"
                      onClick={(e) => {
                        e.preventDefault()
                        handleReset()
                      }}
                      sx={{
                        fontWeight: '500',
                        color: 'white',
                        fontSize: '14px',
                        borderRadius: '4px',
                        textTransform: 'none',
                        padding: '6px 12px',
                        height: 34,
                        backgroundColor: '#722fee',
                      }}
                    >
                      Clear
                    </Button>
                  </Box>
                </Box>
              </Box>
            </CardContent>
          </Card>
        </Box>
      </DemoContainer>
    </React.Fragment>
  )
}

export default FilterComponent
