import React, { Fragment } from 'react'
import { Helmet, HelmetProvider } from 'react-helmet-async'
import { Box, Container, Typography, Button } from '@mui/material'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { useSelector } from 'react-redux'
import { RootState } from 'stores'
import { useNavigate } from 'react-router-dom'

const NotFoundView: React.FC = () => {
  const { role } = useSelector((state: RootState) => state.auth)
  const navigate = useNavigate()

  return (
    <Fragment>
      <HelmetProvider>
        <Helmet>
          <title>404 | Not Found</title>
        </Helmet>
        <Box
          component="main"
          sx={{
            alignItems: 'center',
            display: 'flex',
            flexGrow: 1,
            minHeight: '100%',
          }}
        >
          <Container maxWidth="md">
            <Box
              sx={{
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <Typography align="center" color="textPrimary" variant="h1">
                404: The page you are looking for isn’t here
              </Typography>
              <Typography align="center" color="textPrimary" variant="subtitle2">
                You either tried some shady route or you came here by mistake. Whichever it is, try
                using the navigation
              </Typography>
              <Box sx={{ textAlign: 'center' }}>
                <img
                  alt="Under development"
                  src="/static/images/undraw_page_not_found_su7k.svg"
                  style={{
                    marginTop: 50,
                    display: 'inline-block',
                    maxWidth: '100%',
                    width: 560,
                  }}
                />
              </Box>

              <Button
                component="a"
                startIcon={<ArrowBackIcon fontSize="small" />}
                sx={{ mt: 3 }}
                variant="contained"
                onClick={
                  role && role !== 'customer'
                    ? () => navigate(-1)
                    : () => navigate('/', { replace: true })
                }
              >
                {role && role !== 'customer' ? 'Go back to dashboard' : 'Go back to Home'}
              </Button>
            </Box>
          </Container>
        </Box>
      </HelmetProvider>
    </Fragment>
  )
}

export default NotFoundView
