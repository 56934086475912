import React from 'react'
import { useSelector } from 'react-redux'
import { useLocation, Navigate, Outlet } from 'react-router-dom'
import { RootState } from 'stores/index'
import { UserRoleEnum } from 'stores/auth/auth-slice'

const ProtectedAccess: React.FC = () => {
  const { role } = useSelector((state: RootState) => state.auth)
  const location = useLocation()

  const isSuperAdmin = () => {
    return role === UserRoleEnum.SUPERADMIN
  }

  if (!isSuperAdmin()) {
    return <Navigate to="/app" state={{ from: location }} />
  }

  return <Outlet />
}

export default ProtectedAccess
