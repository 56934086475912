import React, { Fragment, useState } from 'react'
import {
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Skeleton,
  Button,
  Chip,
  IconButton,
  Collapse,
  Typography,
} from '@mui/material'
import { styled } from '@mui/material/styles'
import { tableCellClasses } from '@mui/material/TableCell'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'stores'
import TablePagination from 'app/web/components/TablePagination'
import { IIssueDetails, IIssueState } from 'stores/issues/issues-slice'
import { resolveIssue } from 'stores/issues/issues-action'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import ModalContainer from 'app/dashboard/components/ModalContainer'
import ReplyIssue from './ReplyIssue'
import moment from 'moment'
import { LoadingButton } from '@mui/lab'
import { UserRoleEnum } from 'stores/auth/auth-slice'

const storeTableCellOptions: string[] = ['', 'Title', 'Description', 'Status', '']
const adminTableCellOptions: string[] = ['', 'Title', 'Description', 'Status', 'Raised By', '']

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.primary.contrastText,
    fontWeight: 'bold',
    fontSize: 16,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    fontWeight: 600,
  },
}))

interface ICommonTable {
  data: any
  tableHeads: string[]
  isLoading: any
}

const Row = ({ issue, index, setReplyOpen }) => {
  const [open, setOpen] = useState(false)
  const dispatch = useDispatch()
  const [isResolving, setResolving] = useState(false)
  const { userInfo } = useSelector((state: RootState) => state.user)
  const isSuperAdmin = [UserRoleEnum.SUPERADMIN].includes(userInfo?.role as UserRoleEnum)

  const handleResolve = async (id: number) => {
    setResolving(true)
    await dispatch(resolveIssue(id))
    setResolving(false)
  }

  return (
    <Fragment key={index}>
      <TableRow
        sx={{
          height: 'fit-content',
          '& td': {
            position: 'relative',
            padding: '6px 0px',
            color: '#000',
            fontSize: '13px !important',
            cursor: 'pointer',
            border: '1px solid #c7c7c7',
            borderBottom: '1px solid #c7c7c7',
          },
          '& td p': {
            color: '#000',
          },
          background: '#f5f5f5',
        }}
        hover
      >
        <TableCell>
          {issue.comments.length > 0 && (
            <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          )}
        </TableCell>
        <TableCell>{issue.title}</TableCell>

        <TableCell>{issue.description}</TableCell>
        <TableCell>
          <Chip
            size="small"
            label={issue.resolved ? 'Resolved' : 'Pending'}
            color={issue.resolved ? 'success' : 'secondary'}
          />
        </TableCell>
        {isSuperAdmin && <TableCell>{issue.raisedByName}</TableCell>}
        <TableCell sx={{ width: '170px', padding: '2px 0px !important' }}>
          {!issue.resolved && (
            <Button
              variant="contained"
              onClick={() => setReplyOpen(issue.id)}
              sx={{
                backgroundColor: '#007dce',
                height: '26px',
                textTransform: 'none',
                borderRadius: '8px',
                width: '60px',
                '&:hover': {
                  backgroundColor: '#007dce',
                },
              }}
            >
              Reply
            </Button>
          )}
          {!issue.resolved && isSuperAdmin && (
            <LoadingButton
              loading={isResolving}
              variant="contained"
              onClick={() => handleResolve(issue.id)}
              sx={{
                backgroundColor: '#007dce',
                height: '26px',
                textTransform: 'none',
                borderRadius: '8px',

                width: '60px',
                marginLeft: '10px',
                '&:hover': {
                  backgroundColor: '#007dce',
                },
              }}
            >
              Resolve
            </LoadingButton>
          )}
        </TableCell>
      </TableRow>

      <TableRow
        sx={{
          height: 'unset',
          '& td': {
            position: 'relative',
            padding: '6px 0px',
            color: '#000',
            fontSize: '13px !important',
            cursor: 'pointer',
            border: '1px solid #c7c7c7',
            borderBottom: '1px solid #c7c7c7',
          },
          '& td p': {
            color: '#000',
          },
          background: '#f5f5f5',
        }}
      >
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: '20px 10px' }}>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow
                    sx={{
                      height: '31px',
                      borderBottom: '1px solid #c7c7c7 !important',
                    }}
                  >
                    <StyledTableCell
                      key={index}
                      sx={{
                        padding: 0,
                        backgroundColor: '#3398cc !important',
                        border: '1px solid #c7c7c7',
                      }}
                    >
                      Message
                    </StyledTableCell>
                    <StyledTableCell
                      key={index}
                      sx={{
                        padding: 0,
                        backgroundColor: '#3398cc !important',
                        border: '1px solid #c7c7c7',
                      }}
                    >
                      Commented By
                    </StyledTableCell>
                    <StyledTableCell
                      key={index}
                      sx={{
                        padding: 0,
                        backgroundColor: '#3398cc !important',
                        border: '1px solid #c7c7c7',
                      }}
                    >
                      Replied At
                    </StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {issue.comments.map((comment, index) => (
                    <TableRow
                      key={index}
                      sx={{
                        height: '31px',
                        borderBottom: '1px solid #c7c7c7 !important',
                      }}
                    >
                      <TableCell>{comment.message}</TableCell>
                      <TableCell>{comment.commentedByName}</TableCell>
                      <TableCell>
                        {moment.utc(comment.commentedAt).format('YYYY-MM-DD HH:mm')}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </Fragment>
  )
}

const CommonTable = ({ data, tableHeads, isLoading }: ICommonTable) => {
  const [isReplyOpen, setReplyOpen] = useState(null)

  return (
    <>
      <Table sx={{ borderRadius: 0, border: '1px solid #ccc' }}>
        <TableHead sx={{ backgroundColor: '#fff', color: 'primary.dark' }}>
          <TableRow
            sx={{
              height: '31px',
              borderBottom: '1px solid #c7c7c7 !important',
            }}
          >
            {tableHeads.map((item, index) => (
              <StyledTableCell
                key={index}
                sx={{
                  padding: 0,
                  backgroundColor: '#3398cc !important',
                  border: '1px solid #c7c7c7',
                }}
              >
                {item}
              </StyledTableCell>
            ))}
          </TableRow>
        </TableHead>

        {isLoading?.status && isLoading.usedFor === 'issues' ? (
          <TableBody>
            <TableRow>
              {tableHeads.map((item) => (
                <TableCell key={item} sx={{ p: 0 }}>
                  {Array.from(new Array(5)).map((_, index) => (
                    <Skeleton key={index} height={72} animation="wave" />
                  ))}
                </TableCell>
              ))}
            </TableRow>
          </TableBody>
        ) : (
          <TableBody>
            {data.length > 0 &&
              data.map((issue: IIssueState, index: number) => {
                return <Row issue={issue} index={index} setReplyOpen={setReplyOpen} />
              })}
          </TableBody>
        )}
      </Table>
      <ModalContainer modalOpen={!!isReplyOpen} handleModalClose={() => setReplyOpen(null)}>
        <ReplyIssue issueId={isReplyOpen} onClose={() => setReplyOpen(null)} />
      </ModalContainer>
    </>
  )
}

const IssueTable: React.FC<IIssueDetails> = ({
  results,
  paginationInfo,
  handlePageChange,
  handleChangeRowsPerPage,
  ...rest
}) => {
  const { issuesList = {} as any } = useSelector((state: RootState) => state.issue)
  const { isLoading } = useSelector((state: RootState) => state.ui)
  const { userInfo } = useSelector((state: RootState) => state.user)
  const isSuperAdmin = [UserRoleEnum.SUPERADMIN].includes(userInfo?.role as UserRoleEnum)

  return (
    <Box
      key={`${isLoading?.status}-${isLoading?.usedFor}-${issuesList.length}`}
      sx={{
        border: '2px solid #ccc',
        backgroundColor: '#f5f5f5',
        borderRadius: '4px',
        padding: '10px',
        marginTop: '10px',
        '& table': {
          '& th, td': {
            fontSize: { sm: 14 },
          },
        },
      }}
    >
      <Card {...rest} sx={{ borderRadius: '2px', boxShadow: 'none' }}>
        <CommonTable
          data={results}
          tableHeads={isSuperAdmin ? adminTableCellOptions : storeTableCellOptions}
          isLoading={isLoading}
        />

        <TablePagination
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          handlePageChange={handlePageChange}
          paginationInfo={paginationInfo}
        />
      </Card>
    </Box>
  )
}

export default IssueTable
