interface JpRecordsInfo {
  id: string
  account: string
  type: string
  score: number
  manager: string
  date: string
}
const JpRecords: JpRecordsInfo[] = [
  {
    id: '241941',
    account: 'nepal123',
    type: 'Type 1',
    score: 100,
    manager: 'Suman21',
    date: '2021-11-22 04:07:06',
  },
  {
    id: '2419442',
    account: 'nepal123',
    type: 'Type 1',
    score: 100,
    manager: 'Suman21',
    date: '2021-11-22 04:07:06',
  },
  {
    id: '2419434',
    account: 'nepal123',
    type: 'Type 1',
    score: 100,
    manager: 'Suman21',
    date: '2021-11-22 04:07:06',
  },

  {
    id: '2414944',
    account: 'nepal123',
    type: 'Type 1',
    score: 100,
    manager: 'Suman21',
    date: '2021-11-22 04:07:06',
  },

  {
    id: '2419444',
    account: 'nepal123',
    type: 'Type 1',
    score: 100,
    manager: 'Suman21',
    date: '2021-11-22 04:07:06',
  },

  {
    id: '24194114',
    account: 'nepal123',
    type: 'Type 1',
    score: 100,
    manager: 'Suman21',
    date: '2021-11-22 04:07:06',
  },
]

export default JpRecords
