import React from 'react'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { Link, useNavigate } from 'react-router-dom'

const BackButton: React.FC<{ href?: string; iconOnly?: boolean }> = ({ href, iconOnly }) => {
  const navigate = useNavigate()
  return (
    <Box mt={iconOnly ? 0 : 4}>
      {href ? (
        <Link to={href}>
          {iconOnly ? (
            <ArrowBackIcon sx={{ color: '#0582b7', marginRight: '2px' }} />
          ) : (
            <Button
              variant="contained"
              startIcon={<ArrowBackIcon />}
              color="warning"
              sx={{
                color: 'white',
                fontWeight: 'bold',
              }}
            >
              Back
            </Button>
          )}
        </Link>
      ) : (
        <Button
          variant="contained"
          startIcon={<ArrowBackIcon />}
          onClick={() => navigate(-1)}
          color="warning"
          sx={{
            color: 'white',
            fontWeight: 'bold',
          }}
        >
          Back
        </Button>
      )}
    </Box>
  )
}

export default BackButton
