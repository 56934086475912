import { useState, useEffect } from 'react'
import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Typography,
  FormControl,
  Select,
  MenuItem,
  Grid,
} from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'stores'
import { getGameList } from 'stores/dashboard/dashboard-action'
import GameImg from '../../images/cards.jpg'
import moment from 'moment'

enum EFilters {
  PLAYEDGAMES = 'played_games',
  BETGAMES = 'bet_amt_games',
  PROFITGAMES = 'profit_game',
}

const TopGames = () => {
  const [defaultSelect, setDefaultSelect] = useState<EFilters | string>(EFilters.PLAYEDGAMES)

  const dispatch = useDispatch()

  const startOfWeek = moment().startOf('week').format('YYYY-MM-DD')
  const endOfWeek = moment().endOf('week').format('YYYY-MM-DD')

  const { betAmountWiseGameStat } = useSelector((state: RootState) => state.dashboard)
  const { activeWiseGameStat } = useSelector((state: RootState) => state.dashboard)
  const { profitWiseGameStat } = useSelector((state: RootState) => state.dashboard)

  useEffect(() => {
    dispatch(getGameList(startOfWeek, endOfWeek))
  }, [startOfWeek, endOfWeek, dispatch])

  return (
    <Box
      sx={{
        backgroundColor: 'background.default',
        border: '2px solid #ccc',
        borderRadius: '4px',
        pt: '10px !important',
        pr: 1.5,
        pb: 2,
        pl: 2,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'center',
          borderBottom: '1px solid #ded9d9',
          pb: 1,
        }}
      >
        <Typography
          sx={{
            width: '100%',
          }}
          variant="h4"
          color="secondary.main"
          fontSize={'16px'}
          fontWeight={'600'}
        >
          Top Games
        </Typography>
        <FormControl sx={{ minWidth: '200px', ml: 7, mt: 2 }}>
          <Select
            displayEmpty
            value={defaultSelect}
            onChange={(e) => setDefaultSelect(e.target.value)}
            sx={{
              borderRadius: '4px',
              backgroundColor: '#fff',
              '& .MuiSelect-select': {
                padding: '8px 12px',
              },
            }}
          >
            <MenuItem value="played_games">Most Played Games</MenuItem>
            <MenuItem value="bet_amt_games">Most Bet Amount Games</MenuItem>
            <MenuItem value="profit_game">Most Profit Game</MenuItem>
          </Select>
        </FormControl>
      </Box>
      <Grid container spacing={1} justifyContent="space-between" alignItems="center" sx={{ mb: 4 }}>
        {defaultSelect === EFilters.PLAYEDGAMES &&
          activeWiseGameStat?.map((item, index) => (
            <Grid item xs={6} sm={6} md={3} lg={2} key={index}>
              <Card>
                <CardActionArea>
                  <CardMedia component="img" height="140" image={GameImg} alt="game" />
                  <CardContent>
                    <Typography gutterBottom variant="h6" component="div">
                      {item.title}
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
          ))}
        {defaultSelect === EFilters.BETGAMES &&
          betAmountWiseGameStat?.map((item, index) => (
            <Grid item xs={6} sm={6} md={3} lg={2} key={index}>
              <Card>
                <CardActionArea>
                  <CardMedia component="img" height="140" image={GameImg} alt="game" />
                  <CardContent>
                    <Typography gutterBottom variant="h6" component="div">
                      {item.title}
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
          ))}
        {defaultSelect === EFilters.PROFITGAMES &&
          profitWiseGameStat?.map((item, index) => (
            <Grid item xs={6} sm={6} md={3} lg={2} key={index}>
              <Card>
                <CardActionArea>
                  <CardMedia component="img" height="140" image={GameImg} alt="game" />
                  <CardContent>
                    <Typography gutterBottom variant="h6" component="div">
                      {item.title}
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
          ))}
      </Grid>
    </Box>
  )
}

export default TopGames
