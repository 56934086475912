import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface UdidInterface {
  _id?: string
  udid?: string
  macId?: string 
  deviceType?: string
  added?: 'added' | 'notadded' | null
  createdAt?: Date
  addedAt?: Date
  securityCode?: string
}

interface IState {
  udid: UdidInterface[]
}

const initialState: IState = { udid: [] }

const udidSlice = createSlice({
  name: 'udid',
  initialState,
  reducers: {
    setUdid(state, action: PayloadAction<UdidInterface[]>) {
      state.udid = action.payload
    },
    pushUdid(state, action: PayloadAction<UdidInterface>) {
      state.udid.push(action.payload)
    },
  },
})

export const udidActions = udidSlice.actions

export default udidSlice.reducer
