import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface VersionInterface {
  _id?: string
  version?: string
  testing?: string
  description?: string
  gameVersion?: string;
  createdAt?: string
  testVersion?: string
}

interface IState {
  version: VersionInterface[]
}

const initialState: IState = {
  version: [],
}

const versionSlice = createSlice({
  name: 'version',
  initialState,
  reducers: {
    pushVersion(state, action: PayloadAction<VersionInterface>) {
      state.version.push(action.payload)
    },
    getGameVersion(state, action: PayloadAction<VersionInterface[]>) {
      state.version = action.payload
    },
  },
})

export const versionActions = versionSlice.actions

export default versionSlice.reducer
