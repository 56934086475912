import { Grid } from '@mui/material'
import { useSelector } from 'react-redux'
import { RootState } from 'stores'
import {
  MonetizationOn,
  EvStation,
  Redeem,
  AccountBalance,
  TransferWithinAStation,
} from '@mui/icons-material'
import CardStats from 'app/dashboard/components/card-statistics'

const ReportOverview = () => {
  const { burntReportOverview } = useSelector((state: RootState) => state.burntReport)
  return (
    <Grid container spacing={1.5}>
      <Grid item md={4} lg={3}>
        <CardStats
          title="Burnt Amount"
          stats={burntReportOverview?.totalBurntAmount}
          icon={<TransferWithinAStation />}
          color="error"
        />
      </Grid>
      <Grid item md={4} lg={2.25}>
        <CardStats
          title="Total Redeem"
          stats={burntReportOverview?.totalRedeem}
          icon={<Redeem />}
          color="info"
        />
      </Grid>
      <Grid item md={4} lg={2.25}>
        <CardStats
          title="Current Amount"
          stats={burntReportOverview?.currentAmount}
          icon={<AccountBalance />}
          color="secondary"
        />
      </Grid>
      <Grid item md={4} lg={2.25}>
        <CardStats
          title="Total Revenue"
          stats={burntReportOverview?.totalRevenue}
          icon={<MonetizationOn />}
          color="success"
        />
      </Grid>
      <Grid item md={4} lg={2.25}>
        <CardStats
          title="Total Recharge"
          stats={burntReportOverview?.totalRecharge}
          icon={<EvStation />}
          color="warning"
        />
      </Grid>
    </Grid>
  )
}

export default ReportOverview
