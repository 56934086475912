import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { Status } from 'commons/types'

export interface GameInterface {
  _id?: string
  title: string
  code: string
  category: string
  description: string
  images: Array<string>
  featuredImage: string
  videoEmbedId: string
  createdAt?: Date
  status: Status
}

export interface GameLink {
  apkLink: string
  iosLink: string
  webLink: string
}

interface IGame {
  game: GameInterface[]
  gameByCategory: GameInterface[]
  latestGames: GameInterface[]
  similarGames: GameInterface[]
  gameLink: GameLink | null
}

export interface IGameStatus {
  targetId: string
  status: Status
}

const initialState: IGame = {
  game: [],
  latestGames: [],
  similarGames: [],
  gameByCategory: [],
  gameLink: null,
}

const gameSlice = createSlice({
  name: 'game',
  initialState: initialState,
  reducers: {
    setGame(state, action: PayloadAction<GameInterface[]>) {
      state.game = action.payload
    },
    latestGames(state, action: PayloadAction<GameInterface[]>) {
      state.latestGames = action.payload
    },
    gameByCategory(state, action: PayloadAction<GameInterface[]>) {
      state.gameByCategory = action.payload
    },
    similarGames(state, action: PayloadAction<GameInterface[]>) {
      state.similarGames = action.payload
    },
    updateGameList(state, action: PayloadAction<GameInterface>) {
      state.game.push(action.payload)
    },
    removeGame(state, action: PayloadAction<string>) {
      state.game = state.game.filter((g) => g._id !== action.payload)
    },
    updateGame(state, action: PayloadAction<GameInterface>) {
      state.game = state.game.map((g) => {
        if (g._id === action.payload._id) {
          return action.payload
        }
        return g
      })
    },
    updateUserStatus(state, action: PayloadAction<IGameStatus>) {
      state.game = state.game.map((game) => {
        if (game._id === action.payload.targetId) {
          game.status = action.payload.status
        }
        return game
      })
    },
    gameLink(state, action: PayloadAction<GameLink>) {
      state.gameLink = action.payload
    },
  },
})

export const gameAction = gameSlice.actions
export default gameSlice.reducer
