import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface AnnouncementInterface {
  _id?: string
  mainTitle: string
  subTitle: string
  mainContent: string
  footer?: string
  openState: 'open' | 'close'
}

interface IState {
  gameAnnouncement: AnnouncementInterface[]
}

const initialState: IState = { gameAnnouncement: [] }

const AnnouncementSlice = createSlice({
  name: 'announcement',
  initialState: initialState,
  reducers: {
    setAnnouncement(state, action: PayloadAction<AnnouncementInterface[]>) {
      state.gameAnnouncement = action.payload
    },
    pushAnnouncement(state, action: PayloadAction<AnnouncementInterface>) {
      state.gameAnnouncement.push(action.payload)
    },
    removeAnnouncement(state, action: PayloadAction<string>) {
      state.gameAnnouncement = state.gameAnnouncement.filter(
        (announcement) => announcement._id !== action.payload
      )
    },
    updateAnnouncement(state, action: PayloadAction<AnnouncementInterface>) {
      state.gameAnnouncement = state.gameAnnouncement.map((announcement) => {
        if (announcement._id === action.payload._id) {
          return action.payload
        }
        return announcement
      })
    },
  },
})

export const gameAnnouncementActions = AnnouncementSlice.actions
export default AnnouncementSlice.reducer
