import PageTitle from 'app/dashboard/components/PageTitle'
import { Container, Grid } from '@mui/material'
import ManagerTable from './Table/Managers/index'
import PlayerTable from './Table/Players/index'
import TopGames from './TopGames'
import RechargeRedeemChart from './RechargeRedeemChart'
import TotalTimeBarChart from './TotalTimeBarChart'
import Overview from './Overview'

const Index: React.FC = () => {
  return (
    <Container maxWidth={false} id="abc">
      <PageTitle pageTitle="Dashboard | Galaxy GamesGroup" />
      {/* <ScaleContainer parentId=""></ScaleContainer> */}
      {/* <ScaleContainer key={`dashboard`} parentId="dashboard-parent" childId="dashboard-content"> */}
      <Grid container spacing={3} sx={{ mb: 3 }}>
        <Grid item xs={12} sm={12} md={12} lg={12} sx={{ paddingLeft: '0px !important' }}>
          <Overview />
        </Grid>

        <Grid item xs={12} sm={12} md={7} lg={7} sx={{ paddingLeft: '0px !important' }}>
          <TotalTimeBarChart />
        </Grid>
        <Grid item xs={12} sm={12} md={5} lg={5}>
          <RechargeRedeemChart />
        </Grid>

        <ManagerTable />
        <PlayerTable />
        <Grid item xs={12} sm={12} md={12} lg={12} sx={{ paddingLeft: '0px !important' }}>
          <TopGames />
        </Grid>
      </Grid>
      {/* </ScaleContainer> */}
    </Container>
  )
}

export default Index
