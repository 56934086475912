import React, { FormEvent, useState } from 'react'
import { Typography, Box, Stack, TextField, SelectChangeEvent, Container } from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton'
import { useDispatch } from 'react-redux'
import { replyToIssue } from 'stores/issues/issues-action'

type formKey = 'message'

const ReplyIssue = ({ issueId, onClose }: any) => {
  const [formData, setFormData] = useState({
    message: '',
  })
  const [isLoading, setIsLoading] = useState(false)

  const dispatch = useDispatch()

  const handleChange = (
    event: SelectChangeEvent | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    formKey: formKey
  ) => {
    setFormData({ ...formData, [formKey]: event.target.value })
  }

  const handleSubmit = async (event: FormEvent) => {
    event.preventDefault()
    setIsLoading(true)
    await dispatch(replyToIssue(issueId, formData))
    setIsLoading(false)
    onClose()
  }

  return (
    <Container
      maxWidth={false}
      sx={{ paddingLeft: '10px !important', paddingTop: '16px !important' }}
    >
      <Typography
        sx={{
          borderBottom: '1px solid #ded9d9',
          width: '100%',
          paddingBottom: '10px',
          paddingTop: '6px',
          marginBottom: '20px',
          display: 'flex',
          alignItems: 'center',
        }}
        variant="h4"
        color="secondary.main"
        fontSize={'18px'}
        fontWeight={'600'}
      >
        Write a Reply
      </Typography>
      <Box component={'form'}>
        <Stack
          spacing={2}
          sx={{
            border: '2px solid #ccc',
            borderRadius: '4px',
            padding: '28px 8px',
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: '#7F9DB9 !important',
              borderRadius: '4px',
            },

            '& input': {
              width: '192px',
              height: '22px',
              background: '#fff',
              fontSize: '12px',
              padding: '0px 5px',
            },
            '&  .MuiInputBase-multiline': {
              background: '#fff',
              fontSize: '13px',
              padding: '6px',
              width: '350px',
            },
          }}
        >
          <Box
            sx={{
              display: 'flex',
              gap: '12px',
            }}
          >
            <Typography
              sx={{
                color: '#4a494a',
                fontWeight: '600',
                fontSize: '12px',
                width: '14%',
                textAlign: 'right',
              }}
            >
              Reply:
            </Typography>
            <TextField
              type="text"
              multiline
              rows={4}
              value={formData.message}
              variant="outlined"
              size="small"
              onChange={(e) => handleChange(e, 'message')}
              required
            />
          </Box>

          <LoadingButton
            loading={isLoading}
            onClick={handleSubmit}
            variant="contained"
            sx={{
              borderRadius: '4px',
              width: 'fit-content',
              marginLeft: 'calc(14% + 12px) !important',
              fontSize: 14,
              height: 30,
              textTransform: 'capitalize',
              padding: ' 0px 6px',
              backgroundColor: '#0382b7',
            }}
            color="secondary"
          >
            Send
          </LoadingButton>
        </Stack>
      </Box>
    </Container>
  )
}

export default ReplyIssue
