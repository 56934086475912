import api from 'config/apiConfig'
import { ServerError } from 'stores/auth/auth-actions'
import { Action, Dispatch } from 'redux'
import { AxiosError } from 'axios'
import { handleErrorResponse } from 'app/dashboard/utils/errorHandler'
import { uiActions } from 'stores/ui/ui-slice'
import { jpActions, JpScore } from './jp-slice'

interface IResponse {
  jpScore: JpScore
}

export const adjustJpScore =
  (jpInfo: JpScore) =>
  async (dispatch: Dispatch<Action>): Promise<void> => {
    try {
      dispatch(uiActions.setLoading({ status: true, usedFor: 'jp' }))
      const { data } = await api.post<IResponse>('/my/jp-score', jpInfo)
      dispatch(jpActions.setJp(data.jpScore))
      dispatch(
        uiActions.showNotification({
          status: 200,
          title: 'success',
          msg: 'Successful modification of parameters!',
        })
      )
    } catch (error) {
      if (error) {
        const serverError = error as AxiosError<ServerError>
        handleErrorResponse(serverError, dispatch)
      }
    } finally {
      dispatch(uiActions.setLoading({ status: false, usedFor: 'jp' }))
    }
  }

export const getJpScore =
  () =>
  async (dispatch: Dispatch<Action>): Promise<void> => {
    try {
      const { data } = await api.get<IResponse>('/my/jp-score')
      dispatch(jpActions.setJp(data.jpScore))
    } catch (error) {
      if (error) {
        const serverError = error as AxiosError<ServerError>
        handleErrorResponse(serverError, dispatch)
      }
    } finally {
      dispatch(uiActions.setLoading({ status: false, usedFor: 'jp' }))
    }
  }
