import api from 'config/apiConfig'
import { gameAction, GameInterface } from './game-slice'
import { ServerError } from 'stores/auth/auth-actions'
import { Action, Dispatch } from 'redux'
import { AxiosError } from 'axios'
import { handleErrorResponse } from 'app/dashboard/utils/errorHandler'
import { uiActions } from '../ui/ui-slice'

import { Status } from 'commons/types'

export const getAllGames =
  (pageNumber: number, rowsPerPage: number = 10) =>
  async (dispatch: Dispatch<Action>): Promise<void> => {
    try {
      dispatch(uiActions.setLoading({ status: true, usedFor: 'game' }))
      const { data } = await api.get<{ data: GameInterface[]; paginationInfo: any }>('/games', {
        params: {
          pageNumber,
          size: rowsPerPage,
        },
      })
      dispatch(gameAction.setGame(data.data))
      return data.paginationInfo
    } catch (error) {
      if (error) {
        const serverError = error as AxiosError<ServerError>
        handleErrorResponse(serverError, dispatch)
      }
    } finally {
      dispatch(uiActions.setLoading({ status: false, usedFor: 'game' }))
    }
  }

export const getGameByTitle =
  (pageNumber: number, rowsPerPage: number = 10, searchKey?: string) =>
  async (dispatch: Dispatch<Action>): Promise<void> => {
    try {
      dispatch(uiActions.setLoading({ status: true, usedFor: 'game' }))
      const { data } = await api.get<{ data: GameInterface[]; paginationInfo: any }>('/games', {
        params: {
          pageNumber,
          size: rowsPerPage,
          searchKey,
        },
      })
      dispatch(gameAction.setGame(data.data))
      return data.paginationInfo
    } catch (error) {
      if (error) {
        const serverError = error as AxiosError<ServerError>
        handleErrorResponse(serverError, dispatch)
      }
    } finally {
      dispatch(uiActions.setLoading({ status: false, usedFor: 'game' }))
    }
  }

export const getLatestGames =
  () =>
  async (dispatch: Dispatch<Action>): Promise<void> => {
    try {
      dispatch(uiActions.setLoading({ status: true, usedFor: 'game' }))
      const { data } = await api.get<GameInterface[]>('/latest/games')
      dispatch(gameAction.latestGames(data))
    } catch (error) {
      if (error) {
        const serverError = error as AxiosError<ServerError>
        handleErrorResponse(serverError, dispatch)
      }
    } finally {
      dispatch(uiActions.setLoading({ status: false, usedFor: 'game' }))
    }
  }

export const getGameByCategory =
  (id: string) =>
  async (dispatch: Dispatch<Action>): Promise<void> => {
    try {
      dispatch(uiActions.setLoading({ status: true, usedFor: 'gameByCategory' }))
      const { data } = await api.get<GameInterface[]>(`/games/${id}`)
      dispatch(gameAction.gameByCategory(data))
    } catch (error) {
      if (error) {
        const serverError = error as AxiosError<ServerError>
        handleErrorResponse(serverError, dispatch)
      }
    } finally {
      dispatch(uiActions.setLoading({ status: false, usedFor: 'game' }))
    }
  }

export const getSimilarGames =
  (categoryId: string, gameId: string) =>
  async (dispatch: Dispatch<Action>): Promise<void> => {
    try {
      dispatch(uiActions.setLoading({ status: true, usedFor: 'game' }))
      const { data } = await api.get<GameInterface[]>(`/games/${categoryId}`)
      const filteredData = data.filter((game) => game._id !== gameId)
      dispatch(gameAction.similarGames(filteredData))
    } catch (error) {
      if (error) {
        const serverError = error as AxiosError<ServerError>
        handleErrorResponse(serverError, dispatch)
      }
    } finally {
      dispatch(uiActions.setLoading({ status: false, usedFor: 'game' }))
    }
  }

export const createGame =
  (gameData: GameInterface) =>
  async (dispatch: Dispatch<Action>): Promise<void> => {
    try {
      dispatch(uiActions.setLoading({ status: true, usedFor: 'game' }))
      const { data } = await api.post<GameInterface>('/create/game', gameData)
      dispatch(
        uiActions.showNotification({
          status: 200,
          title: 'success',
          msg: 'New game Added!',
        })
      )
      dispatch(gameAction.updateGameList(data))
    } catch (error) {
      if (error) {
        const serverError = error as AxiosError<ServerError>
        handleErrorResponse(serverError, dispatch)
      }
    } finally {
      dispatch(uiActions.setLoading({ status: false, usedFor: 'game' }))
      setTimeout(() => {
        dispatch(uiActions.hideNotification())
      }, 3000)
    }
  }

export const removeGame =
  (gameId: string) =>
  async (dispatch: Dispatch<Action>): Promise<void> => {
    try {
      await api.delete(`/delete/game/${gameId}`)
      dispatch(gameAction.removeGame(gameId))
    } catch (error) {
      if (error) {
        const serverError = error as AxiosError<ServerError>
        handleErrorResponse(serverError, dispatch)
      }
    }
  }

export const updateGame =
  (gameInfo: GameInterface, gameId: string) =>
  async (dispatch: Dispatch<Action>): Promise<void> => {
    try {
      dispatch(uiActions.setLoading({ status: true, usedFor: 'game' }))
      const { data } = await api.put<GameInterface>(`/update/game/${gameId}`, gameInfo)
      dispatch(gameAction.updateGame(data))
      dispatch(
        uiActions.showNotification({
          status: 200,
          title: 'success',
          msg: 'Game successfuly updated!',
        })
      )
    } catch (error) {
      if (error) {
        const serverError = error as AxiosError<ServerError>
        handleErrorResponse(serverError, dispatch)
      }
    } finally {
      dispatch(uiActions.setLoading({ status: false, usedFor: 'game' }))
      setTimeout(() => {
        dispatch(uiActions.hideNotification())
      }, 3000)
    }
  }

export const updateGameStatus =
  (gameId: string) =>
  async (dispatch: Dispatch<Action>): Promise<void> => {
    try {
      const { data } = await api.post<{ newStatus: Status }>(`/game/change-status/${gameId}`)

      dispatch(
        uiActions.showNotification({
          status: 200,
          title: 'success',
          msg: 'New status updated successfully!',
        })
      )
      dispatch(gameAction.updateUserStatus({ targetId: gameId, status: data.newStatus }))
    } catch (error) {
      if (error) {
        const serverError = error as AxiosError<ServerError>
        handleErrorResponse(serverError, dispatch)
      }
    } finally {
      dispatch(uiActions.setLoading({ status: false, usedFor: 'game' }))
      setTimeout(() => {
        dispatch(uiActions.hideNotification())
      }, 4000)
    }
  }

export const getGameLink =
  (testing: boolean) =>
  async (dispatch: Dispatch<Action>): Promise<void> => {
    try {
      const { data } = await api.get<any>(`/file/link?testing=${testing}`)
      dispatch(gameAction.gameLink(data))
    } catch (error) {
      if (error) {
        const serverError = error as AxiosError<ServerError>
        handleErrorResponse(serverError, dispatch)
      }
    } finally {
      setTimeout(() => {
        dispatch(uiActions.hideNotification())
      }, 4000)
    }
  }

// export const downloadGame =
//   () =>
//   async (dispatch: Dispatch<Action>): Promise<void> => {
//     try {
//       window.location.replace(process.env.REACT_APP_ANDROID_URL as string
//       )
//     } catch (error) {
//       const serverError = error as AxiosError<ServerError>
//       handleErrorResponse(serverError, dispatch)
//     } finally {
//       setTimeout(() => {
//         dispatch(uiActions.hideNotification())
//       }, 4000)
//     }
//   }

// export const downloadIos =
//   () =>
//   async (dispatch: Dispatch<Action>): Promise<void> => {
//     try {
//       window.location.replace(process.env.REACT_APP_IOS_URL as string)
//     } catch (error) {
//       const serverError = error as AxiosError<ServerError>
//       handleErrorResponse(serverError, dispatch)
//     } finally {
//       setTimeout(() => {
//         dispatch(uiActions.hideNotification())
//       }, 4000)
//     }
//   }
