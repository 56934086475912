import * as React from 'react'
import { Helmet, HelmetProvider } from 'react-helmet-async'

interface PageTitleProps {
  pageTitle: string
}

const PageTitle: React.FC<PageTitleProps> = ({ pageTitle }) => {
  const [title] = pageTitle.split('|')
  
  const companyTitle = `${title} | The Galaxy GamesGroup`
  return (
    <HelmetProvider>
      <Helmet>
        <title>{companyTitle}</title>
      </Helmet>
    </HelmetProvider>
  )
}

export default PageTitle
