//@ts-nocheck
import React, { Fragment, useEffect } from 'react'
import { Box, Container, Typography } from '@mui/material'
import PageTitle from 'app/dashboard/components/PageTitle'
import DetailReport from './DetailReport'
import { getReportsByDate } from 'stores/transaction/transaction-actions'
import FilterBar from 'app/dashboard/components/FilterBar'
import { RootState } from 'stores'
import { thisWeekSunday, thisWeekSaturday } from 'app/dashboard/utils/dateHandler'
import { useSelector, useDispatch } from 'react-redux'
import ExportBtn from 'app/dashboard/components/ExportBtn'
import { IReport } from 'stores/transaction/transaction-slice'
import ScaleContainer from 'app/dashboard/components/ScaleContainer'

const Index: React.FC = () => {
  const { reportData } = useSelector((state: RootState) => state.transaction)
  const { isLoading } = useSelector((state: RootState) => state.ui)
  const { userList = {} as any } = useSelector((state: RootState) => state.user)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getReportsByDate(thisWeekSunday, thisWeekSaturday))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Fragment>
      <PageTitle pageTitle="Reports | Galaxy GamesGroup" />
      <Container maxWidth={false} sx={{ padding: '0px !important', mb: 1 }}>
        <Box
          sx={{
            border: '2px solid #ccc',
            borderRadius: '4px',
            backgroundColor: 'background.default',
          }}
        >
          <FilterBar barHeading="Reports" />
        </Box>
        <ScaleContainer
          key={`${isLoading?.status}--${isLoading?.usedFor}-${userList.length}`}
          parentId="reports-page-container"
          childId="reports-page-content"
        >
          <Box
            sx={{
              transform: 'none !important',
            }}
            id="reports-page-content"
          >
            <Box
              sx={{
                border: '2px solid #ccc',
                marginTop: '10px',
                borderRadius: '4px',
                backgroundColor: 'background.default',
                padding: '12px',
              }}
            >
              <Typography
                sx={{
                  ml: '10px',
                  mb: '20px',
                  borderBottom: '1px solid #ded9d9',
                  paddingBottom: '5px',
                  paddingTop: '6px',
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
                variant="h4"
                color="secondary.main"
                fontSize={'16px'}
                fontWeight={'600'}
              >
                Reports Summary
                <ExportBtn
                  icon
                  data={reportData ? ([reportData] as any) : []}
                  title="transaction-summary"
                />
              </Typography>

              <DetailReport
                report={reportData as IReport}
                isSummary={true}
                parent="summary-report-table-container"
                child="summary-report-table"
              />
            </Box>

            <Box
              sx={{
                border: '2px solid #ccc',
                marginTop: '10px',
                borderRadius: '4px',
                backgroundColor: 'background.default',
                padding: '12px',
              }}
            >
              <Typography
                sx={{
                  ml: '10px',
                  mb: '20px',
                  borderBottom: '1px solid #ded9d9',
                  paddingBottom: '5px',
                  paddingTop: '6px',
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
                variant="h4"
                color="secondary.main"
                fontSize={'16px'}
                fontWeight={'600'}
              >
                Detail Reports
                <ExportBtn
                  icon
                  data={reportData ? ([reportData] as any) : []}
                  title="transaction-report"
                />
              </Typography>

              <DetailReport
                report={reportData as IReport}
                parent="detail-report-table-container"
                child="detail-report-table"
              />
            </Box>
          </Box>
        </ScaleContainer>
      </Container>
    </Fragment>
  )
}

export default Index
