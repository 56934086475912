import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface IIssueState {
  id: string
  title: string
  description: string
  raisedByName: string
  resolved: boolean
}

interface IState {
  issuesList: IIssueState[]
}

export interface IIssueDetails {
  results: IIssueState[]
  paginationInfo?: any
  handleChangeRowsPerPage: (e: any) => void
  handlePageChange: (e: any, n: number) => void
}

const initialState: IState = { issuesList: [] }

const issueSlice = createSlice({
  name: 'issue',
  initialState: initialState,
  reducers: {
    setIssuesList(state, action: PayloadAction<IIssueState[]>) {
      state.issuesList = action.payload
    },
    updateIssuesList(state, action: PayloadAction<IIssueState>) {
      state.issuesList?.push(action.payload)
    },
    updateIssue(state, action: PayloadAction<IIssueState>) {
      state.issuesList = state.issuesList.map((issue) => {
        if (issue.id === action.payload.id) {
          issue = action.payload
        }
        return issue
      })
    },
  },
})

export const issueActions = issueSlice.actions
export default issueSlice.reducer
