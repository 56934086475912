// @ts-nocheck
import * as React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
  Stack,
  DialogTitle,
  Box,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
} from '@mui/material'
import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import ModalComponent from 'app/web/components/Modal'
import { baseURL } from 'config/apiConfig'
import { RootState } from 'stores'
import { VersionInterface } from 'stores/version/version-slice'
import { createVersion, getGameVersion } from 'stores/version/version-actions'
import { updateSuperAdminBlc } from 'stores/user/user-actions'
import { Link } from 'react-router-dom'

const quickAmt: number[] = [500, 1000, 1500, 2000, 2500, 3000, 3500]

export default function FormDialog() {
  const dispatch = useDispatch()
  const { userInfo } = useSelector((state: RootState) => state.user)
  const [amount, setAmount] = React.useState<number>()
  const [open, setOpen] = React.useState(false)
  const [currentVersion, setCurrentVersion] = React.useState<VersionInterface | undefined>()
  const { version } = useSelector((state: RootState) => state.version)
  const handleOpenVersionModal = () => {
    setCurrentVersion({
      version: '',
      testing: '',
      description: '',
    })
    dispatch(getGameVersion())
  }
  const handleCloseVersionModal = () => setCurrentVersion(undefined)

  const handleAddVersion = (version: VersionInterface) => () => {
    if (version.version && version.description && version.testing) {
      dispatch(createVersion(version?.version, version?.description, version?.testing === 'true'))
      handleCloseVersionModal()
    }
  }
  const handleClickOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)
  const handleCredit = () => {
    if (userInfo && amount) {
      dispatch(updateSuperAdminBlc(userInfo._id, amount, userInfo.role))
    }
  }

  return (
    <div>
      {userInfo?.role === 'superadmin' && (
        <Stack
          direction="row"
          spacing={2}
          sx={{
            display: 'flex',
            gap: '16px',
            padding: '0 20px',
          }}
        >
          <Button
            variant="contained"
            color="warning"
            onClick={handleOpenVersionModal}
            sx={{
              backgroundColor: 'secondary.light',
              textTransform: 'none',
              padding: '6px 12px',
              borderRadius: '16px',
              width: '110px',
              fontWeight: 'bold',
              '&:hover': {
                backgroundColor: 'secondary.light',
              },
            }}
            size="small"
          >
            Add Version
          </Button>

          <Box sx={{ margin: '0 !important' }}>
            <Button
              variant="contained"
              color="secondary"
              onClick={handleClickOpen}
              sx={{
                backgroundColor: 'secondary.light',
                textTransform: 'none',
                width: '100px',
                padding: '6px 12px',
                borderRadius: '16px',
                fontWeight: 'bold',
                '&:hover': {
                  backgroundColor: 'secondary.light',
                },
              }}
              size="small"
            >
              Add Funds
            </Button>
          </Box>

          {/* <Box sx={{ width: { xs: '100%', md: 'inherit' }, margin: '0 !important' }}>
            <Link to="add/game">
              <Button
                variant="contained"
                color="secondary"
                sx={{
                  color: 'primary.contrastText',
                  fontWeight: 'bold',
                  fontSize: { xs: '12px', lg: '13px' },
                  width: { xs: '100%' },
                }}
                size="small"
              >
                Create Game
              </Button>
            </Link>
          </Box> */}
        </Stack>
      )}

      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth
        sx={{
          '& .MuiPaper-root': {
            borderRadius: '16px',
          },
        }}
      >
        <DialogTitle color="primary.main">
          <Typography
            color="textPrimary"
            sx={{ mt: 2, mb: 1, fontSize: '18px', fontWeight: 'bold', color: '#0382b7' }}
          >
            Credit Wallet
          </Typography>
        </DialogTitle>

        <DialogContent>
          <Box
            border="1px solid rgba(0, 0, 0, 0.25)"
            p={1}
            sx={{
              background: `url(${baseURL}/images/geometric-bg.svg) no-repeat top /cover `,
              color: 'success.main',
            }}
          >
            <Typography
              variant="subtitle2"
              sx={{ display: 'flex', alignItems: 'center', fontWeight: '600' }}
            >
              Available Balance: {userInfo?.balance.toFixed(2)}
            </Typography>

            <Typography variant="subtitle2" sx={{ display: 'flex', alignItems: 'center' }}>
              <AccountCircleIcon sx={{ mr: 1 }} />
              {userInfo?.account.toLocaleUpperCase()}
            </Typography>
          </Box>

          <Stack spacing={2} sx={{ my: 2, display: 'flex', flexWrap: 'wrap' }}>
            {quickAmt.map((item) => (
              <Button
                key={item}
                variant="outlined"
                sx={{
                  color: amount === item ? '#fff' : '#000',
                  borderRadius: '3px',
                  borderColor: 'text.primary',
                  backgroundColor: amount === item ? '#0382b7' : '#fff',
                  '&:hover': {
                    backgroundColor: amount === item ? '#0382b7' : '#fff',
                    color: amount === item ? '#fff' : '#000',
                    opacity: '0.8',
                  },
                }}
                onClick={() => setAmount(item)}
              >
                {item}
              </Button>
            ))}
          </Stack>

          <Box
            sx={{
              display: 'flex',
              gap: '8px',
              flexDirection: 'column',
            }}
          >
            <Typography sx={{ color: '#4a494a', fontWeight: '600', fontSize: '15px' }}>
              Amount:
            </Typography>
            <TextField
              autoFocus
              id="name"
              type="number"
              fullWidth
              size="small"
              value={amount}
              onChange={(e) => setAmount(+e.currentTarget.value)}
              color="info"
              focused
            />
          </Box>
        </DialogContent>

        <DialogActions sx={{ mb: 2, mx: 2 }}>
          <Button
            onClick={handleClose}
            sx={{
              textTransform: 'none',
              backgroundColor: '#722fee',
              borderRadius: '6px',
              fontWeight: 600,
            }}
            variant="contained"
          >
            Cancel
          </Button>

          <Button
            onClick={handleCredit}
            sx={{
              backgroundColor: '#0382b7',
              borderRadius: '6px',
              textTransform: 'none',
              fontWeight: 600,
            }}
            variant="contained"
          >
            Credit
          </Button>
        </DialogActions>
      </Dialog>

      <ModalComponent
        isOpen={!!currentVersion}
        handleClose={handleCloseVersionModal}
        title="Add Version"
        saveButtonTitle="Create"
        handleSave={handleAddVersion(currentVersion!)}
        subContent={`Game Version: ${version?.gameVersion}
        | Test Version: ${version?.testVersion}
        | Testing:  ${version?.testing}`}
      >
        <Stack spacing={2}>
          <Box
            sx={{
              display: 'flex',
              gap: '10px',
              flexDirection: 'column',
            }}
          >
            <Typography sx={{ color: '#4a494a', fontWeight: '600', fontSize: '15px' }}>
              Version
            </Typography>
            <TextField
              size="small"
              autoFocus
              fullWidth
              value={currentVersion?.version || ''}
              onChange={(e) =>
                setCurrentVersion({ ...currentVersion!, version: e.currentTarget.value })
              }
              sx={{
                backgroundColor: '#fff',
                '&  .MuiInputBase-root': {
                  borderRadius: '3px',
                },
              }}
            />
          </Box>

          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: '10px',
            }}
          >
            <Typography sx={{ color: '#4a494a', fontWeight: '600', fontSize: '15px' }}>
              Description
            </Typography>
            <TextField
              fullWidth
              multiline
              rows={3}
              value={currentVersion?.description || ''}
              onChange={(e) =>
                setCurrentVersion({ ...currentVersion!, description: e.currentTarget.value })
              }
            />
          </Box>

          <FormLabel sx={{ color: '#4a494a', fontWeight: '600', fontSize: '15px' }}>
            Testing
          </FormLabel>
          <RadioGroup
            row
            aria-label="testing"
            name="row-radio-buttons-group"
            value={currentVersion?.testing || ''}
            onChange={(e) =>
              setCurrentVersion({ ...currentVersion!, testing: e.currentTarget.value })
            }
          >
            <FormControlLabel value={true} control={<Radio />} label="True" />
            <FormControlLabel value={false} control={<Radio />} label="False" />
          </RadioGroup>
        </Stack>
      </ModalComponent>
    </div>
  )
}
