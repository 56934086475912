import React, { useState } from 'react'
import { Card, Skeleton, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material'
import { styled } from '@mui/material/styles'
import { tableCellClasses } from '@mui/material/TableCell'
import { IReport } from 'stores/transaction/transaction-slice'
import moment from 'moment'
import { useSelector } from 'react-redux'
import { RootState } from 'stores'
import ScaleContainer from 'app/dashboard/components/ScaleContainer'

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    fontWeight: 'bold',
    fontSize: 16,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 13,
  },
}))

const options = [
  'Start Time',
  'End Time',
  'Account',
  'Level',
  'Total Recharged',
  'Total Redeemed',
  'Total Income',
  'Holding',
]

const DetailReport: React.FC<{
  report: IReport
  isSummary?: boolean
  parent: any
  child: any
}> = ({ report, isSummary = false, parent, child, ...rest }) => {
  const { userList = {} as any } = useSelector((state: RootState) => state.user)
  const { isLoading } = useSelector((state: RootState) => state.ui)
  const [tableCellOptions] = useState<string[]>(
    !isSummary ? options : [...options.slice(0, 3), ...options.slice(-4)]
  )

  return (
    <Card {...rest} sx={{ borderRadius: '0px' }}>
      <ScaleContainer
        key={`${isLoading?.status}-${isLoading?.usedFor}-${userList.length}`}
        parentId={parent}
        childId={child}
        sx={{
          '& table': {
            transform: 'none !important',
            '& th, td': {
              fontSize: 13,
            },
          },
        }}
      >
        <Table id={child} sx={{ borderRadius: 0, border: '1px solid #ccc' }}>
          <TableHead sx={{ backgroundColor: '#fff', color: 'primary.dark' }}>
            <TableRow
              sx={{
                height: '31px',
                borderBottom: '1px solid #c7c7c7 !important',
              }}
            >
              {tableCellOptions.map((item, index) => (
                <StyledTableCell
                  key={index}
                  sx={{
                    padding: 0,
                    backgroundColor: '#3398cc !important',
                    border: '1px solid #c7c7c7',
                  }}
                >
                  {item}
                </StyledTableCell>
              ))}
            </TableRow>
          </TableHead>

          {isLoading && isLoading.status && isLoading.usedFor === 'transaction' ? (
            <TableBody>
              <TableRow>
                {tableCellOptions.map((item) => (
                  <TableCell key={item}>
                    <Skeleton height={72} animation="wave" />
                  </TableCell>
                ))}
              </TableRow>
            </TableBody>
          ) : (
            <TableBody>
              <TableRow
                hover
                sx={{
                  height: 'fit-content',
                  '& td': {
                    position: 'relative',
                    padding: '6px 0px',
                    color: '#000',
                    cursor: 'pointer',
                    border: '1px solid #c7c7c7',
                  },
                  '& td p': {
                    color: '#000',
                  },
                  '&:nth-of-type(even)': {
                    bgcolor: 'background.default',
                  },
                  '&:nth-of-type(odd)': {
                    bgcolor: 'background.paper',
                  },
                }}
              >
                <TableCell>{moment(report?.startDate).format('DD/MM/YYYY')}</TableCell>

                <TableCell>{moment(report?.endDate).format('DD/MM/YYYY')}</TableCell>

                <TableCell>{report?.account}</TableCell>

                {!isSummary && <TableCell>{report?.role}</TableCell>}

                <TableCell>{report?.totalRecharged}</TableCell>

                <TableCell>{report?.totalRedeemed}</TableCell>

                <TableCell>{report?.totalIncome}</TableCell>

                <TableCell>{report?.holding} %</TableCell>
              </TableRow>
            </TableBody>
          )}
        </Table>
      </ScaleContainer>
    </Card>
  )
}

export default DetailReport
