import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface RTPInterface {
  _id?: string
  parentName: string
  parentRole: string
  poolAmount: number
  betAmount: number
  winAmount: number
  maxWin: number
  negativePool: number
}

interface IRTP {
  rtp: RTPInterface[]
}

const initialState: IRTP = { rtp: [] }

const rtpSlice = createSlice({
  name: 'rtp',
  initialState: initialState,
  reducers: {
    updateRTPList(state, action: PayloadAction<RTPInterface>) {
      state.rtp.push(action.payload)
    },
    setRTP(state, action: PayloadAction<RTPInterface[]>) {
      state.rtp = action.payload
    },
    removeRTP(state, action: PayloadAction<string>) {
      state.rtp = state.rtp.filter((d) => d._id !== action.payload)
    },
  },
})

export const rtpAction = rtpSlice.actions
export default rtpSlice.reducer
