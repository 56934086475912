import React, { Fragment, useEffect } from 'react'
import { Box, Container, Typography } from '@mui/material'
import PageTitle from 'app/dashboard/components/PageTitle'
import { useSelector, useDispatch } from 'react-redux'
import DisplayCategory from './DisplayCategory'
import { RootState } from 'stores'
import { getCategory } from 'stores/game/game-category-actions'
import ExportBtn from 'app/dashboard/components/ExportBtn'
import ScaleContainer from 'app/dashboard/components/ScaleContainer'

const Index: React.FC = () => {
  const { gameCategory } = useSelector((state: RootState) => state.category)
  const dispatch = useDispatch()
  const [paginationInfo, setPaginationInfo] = React.useState({
    currentPage: 1,
    lastPage: false,
    pageSize: 10,
    totalPage: 0,
    totalRecord: 0,
  })
  const { isLoading } = useSelector((state: RootState) => state.ui)
  const { userList = {} as any } = useSelector((state: RootState) => state.user)

  const handlePageChange = (_event: any, newPage: number) => {
    setPaginationInfo((value) => ({
      ...value,
      currentPage: newPage,
    }))
  }

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPaginationInfo((value) => ({
      ...value,
      pageSize: +event.target.value,
    }))
  }

  useEffect(() => {
    const fetch = async () => {
      const data = (await dispatch(
        getCategory(paginationInfo?.currentPage, paginationInfo?.pageSize)
      )) as any
      data && data && setPaginationInfo(data)
    }

    fetch()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paginationInfo?.currentPage, paginationInfo?.pageSize])

  return (
    <Fragment>
      <PageTitle pageTitle="Manage Category | Galaxy GamesGroup" />

      <Container maxWidth={false} sx={{ padding: '0px !important' }}>
        <ScaleContainer
          key={`${isLoading?.status}--${isLoading?.usedFor}-${userList.length}`}
          parentId="manage-category-page-container"
          childId="manage-category-page-content"
        >
          <Box
            sx={{
              backgroundColor: 'background.default',
              border: '2px solid #ccc',
              borderRadius: '4px',
              padding: '12px',
            }}
            id="manage-category-page-content"
          >
            <Box>
              <Typography
                sx={{
                  ml: '10px',
                  paddingBottom: '5px',
                  paddingTop: '6px',
                  borderBottom: '1px solid #ded9d9',
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  mb: 2,
                }}
                variant="h4"
                color="secondary.main"
                fontSize={'16px'}
                fontWeight={'600'}
              >
                Category List
                <ExportBtn icon data={gameCategory} title="Categories" />
              </Typography>

              <DisplayCategory
                categories={gameCategory}
                handlePageChange={handlePageChange}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
                paginationInfo={paginationInfo}
              />
            </Box>
          </Box>
        </ScaleContainer>
      </Container>
    </Fragment>
  )
}

export default Index
