import React, { useEffect } from 'react'
import { Box, Container } from '@mui/material'
import { getUsers } from 'stores/user/user-actions'
import UserTable from './UserTable'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'stores'
import Toolbar from 'app/dashboard/pages/users/Toolbar'
import PageTitle from 'app/dashboard/components/PageTitle'
import { IUserState } from 'stores/user/user-slice'

const CustomerListView: React.FC = () => {
  const dispatch = useDispatch()
  const [searchText, setSearchText] = React.useState('')
  const [paginationInfo, setPaginationInfo] = React.useState({
    currentPage: 1,
    lastPage: false,
    pageSize: 10,
    totalPage: 0,
    totalRecord: 0,
  })
  // const { isLoading } = useSelector((state: RootState) => state.ui)
  const { userList = {} as any } = useSelector((state: RootState) => state.user)

  const handlePageChange = (_event: any, newPage: number) => {
    setPaginationInfo((value) => ({
      ...value,
      currentPage: newPage,
    }))
  }

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPaginationInfo((value) => ({
      ...value,
      pageSize: +event.target.value,
    }))
  }

  const handleSearch = (searchKey: string) => {
    setSearchText(searchKey)
    setPaginationInfo((value) => ({
      ...value,
      currentPage: 1,
    }))
  }

  const handleReset = () => {
    setSearchText('')
    setPaginationInfo((value) => ({
      ...value,
      currentPage: 1,
    }))
  }

  useEffect(() => {
    const fetch = async () => {
      const data = (await dispatch(
        getUsers(paginationInfo?.currentPage, paginationInfo?.pageSize, searchText)
      )) as any
      data && setPaginationInfo(data)
    }

    fetch()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paginationInfo?.currentPage, paginationInfo?.pageSize, searchText])

  return (
    <>
      <PageTitle pageTitle="Users Page | Galaxy Games Group" />
      <Container maxWidth={false} sx={{ padding: '0px !important' }}>
        {/* <ScaleContainer
          key={`${isLoading?.status}-${isLoading?.usedFor}-${userList.length}`}
          parentId="users-page-container"
          childId="users-page-content"
        > */}
        <Box id="users-page-content">
          <Box
            id="users-toolbar"
            sx={{
              backgroundColor: 'background.default',
              border: '2px solid #ccc',
              borderRadius: '4px',
              // minWidth: 1200,
            }}
          >
            <Toolbar handleSubmit={handleSearch} handleReset={handleReset} />
          </Box>

          <Box>
            {/* <Typography variant="h4" sx={{ mb: 3, mt: 3 }} color="textPrimary">
              User List
            
            </Typography> */}
            <UserTable
              results={userList as IUserState[]}
              paginationInfo={paginationInfo}
              handlePageChange={handlePageChange}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
            />
            <br />
          </Box>
        </Box>
        {/* </ScaleContainer> */}
      </Container>
    </>
  )
}

export default CustomerListView
