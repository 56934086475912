import api from 'config/apiConfig'
import { gameRecordActions } from './game-record-slice'
import { ServerError } from 'stores/auth/auth-actions'
import { Action, Dispatch } from 'redux'
import { AxiosError } from 'axios'
import { handleErrorResponse } from 'app/dashboard/utils/errorHandler'
import { uiActions } from '../ui/ui-slice'

export const getGameRecord =
  (
    pageNumber: Number = 1,
    rowsPerPage: Number = 10,
    accountName?: string,
    manager?: string,
    totalProfit?: string,
    totalTimeInSeconds?: string,
    startDate?: string,
    endDate?: string,
    gameId?: string
  ) =>
  async (dispatch: Dispatch<Action>): Promise<void> => {
    try {
      dispatch(uiActions.setLoading({ status: true, usedFor: 'game' }))
      const { data } = await api.get<any>(`/reports/game-records/summary`, {
        params: {
          pageNumber,
          rowsPerPage,
          accountName,
          manager,
          totalProfit,
          totalTimeInSeconds,
          startDate,
          endDate,
          gameId,
        },
      })
      dispatch(gameRecordActions.setGameRecord(data.data))
      return data.paginationInfo
    } catch (error) {
      if (error) {
        const serverError = error as AxiosError<ServerError>
        handleErrorResponse(serverError, dispatch)
      }
    } finally {
      dispatch(uiActions.setLoading({ status: false, usedFor: 'game' }))
    }
  }

export const getGameRecordBySearch =
  (
    pageNumber: Number = 1,
    rowsPerPage: Number = 10,
    accountName?: string,
    manager?: string,
    totalProfit?: string,
    totalTimeInSeconds?: string,
    startDate?: string,
    endDate?: string,
    gameId?: string
  ) =>
  async (dispatch: Dispatch<Action>): Promise<void> => {
    try {
      const { data } = await api.get<any>(`/game-records/session`, {
        params: {
          pageNumber,
          rowsPerPage,
          accountName,
          manager,
          totalProfit,
          totalTimeInSeconds,
          startDate,
          endDate,
          gameId,
        },
      })

      dispatch(gameRecordActions.setGameRecord(data.data))
      return data.paginationInfo
    } catch (error) {
      if (error) {
        const serverError = error as AxiosError<ServerError>
        handleErrorResponse(serverError, dispatch)
      }
    }
  }

export const getRecordByKey =
  (
    searchKey: string,
    pageNumber: Number = 1,
    rowsPerPage: Number = 10,
    accountName?: string,
    manager?: string,
    totalProfit?: string,
    totalTimeInSeconds?: string,
    startDate?: string,
    endDate?: string,
    gameId?: string
  ) =>
  async (dispatch: Dispatch<Action>): Promise<void> => {
    try {
      dispatch(uiActions.setLoading({ status: true, usedFor: 'global' }))
      const { data } = await api.get<any>(`/game-records/search`, {
        params: {
          searchKey,
          pageNumber,
          rowsPerPage,
          accountName,
          manager,
          totalProfit,
          totalTimeInSeconds,
          startDate,
          endDate,
          gameId,
        },
      })
      dispatch(gameRecordActions.setGameRecord(data.data))
      return data.paginationInfo
    } catch (error) {
      if (error) {
        const serverError = error as AxiosError<ServerError>
        handleErrorResponse(serverError, dispatch)
      }
    } finally {
      dispatch(uiActions.setLoading({ status: false, usedFor: 'global' }))
    }
  }
