//@ts-nocheck
import React, { Fragment, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Box, Container } from '@mui/material'
import PageTitle from 'app/dashboard/components/PageTitle'
import AdminTopBar from './AdminTopBar'
import AdminTable from './AdminTable'
import { getAccountManager } from 'stores/user/user-actions'
import { RootState } from 'stores'
import { IUserState } from 'stores/user/user-slice'
import ScaleContainer from 'app/dashboard/components/ScaleContainer'

const Index: React.FC = () => {
  const { adminList } = useSelector((state: RootState) => state.user)
  const { userList = {} as any } = useSelector((state: RootState) => state.user)
  const { isLoading } = useSelector((state: RootState) => state.ui)
  const [searchText, setSearchText] = React.useState('')
  const [paginationInfo, setPaginationInfo] = React.useState({
    currentPage: 1,
    lastPage: false,
    pageSize: 10,
    totalPage: 0,
    totalRecord: 0,
  })
  const dispatch = useDispatch()

  const handlePageChange = (_event: any, newPage: number) => {
    setPaginationInfo((value) => ({
      ...value,
      currentPage: newPage,
    }))
  }

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPaginationInfo((value) => ({
      ...value,
      pageSize: +event.target.value,
    }))
  }

  const handleSearch = (searchKey: string) => {
    setSearchText(searchKey)
    setPaginationInfo((value) => ({
      ...value,
      currentPage: 1,
    }))
  }

  const handleReset = () => {
    setSearchText('')
    setPaginationInfo((value) => ({
      ...value,
      currentPage: 1,
    }))
  }

  useEffect(() => {
    const fetch = async () => {
      const data = (await dispatch(
        getAccountManager(paginationInfo?.currentPage, paginationInfo?.pageSize, searchText)
      )) as any
      data && setPaginationInfo(data)
    }

    fetch()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paginationInfo?.currentPage, paginationInfo?.pageSize, searchText])

  return (
    <Fragment>
      <PageTitle pageTitle="Admin Page || Galaxy Games Group" />

      <Container maxWidth={false} sx={{ padding: '0px !important' }}>
        <Box
          sx={{
            border: '2px solid #ccc',
            borderRadius: '4px',
            backgroundColor: 'background.default',
          }}
        >
          <AdminTopBar
            adminList={adminList}
            handleSubmit={handleSearch}
            handleReset={handleReset}
          />
        </Box>

        <ScaleContainer
          key={`${isLoading?.status}-${isLoading?.usedFor}-${userList.length}`}
          parentId="admin-tablebar-container"
          childId="admin-tablebar-content"
          sx={{ height: 'fit-content !important' }}
        >
          <Box
            id="admin-tablebar-content"
            sx={{
              border: '2px solid #ccc',
              borderRadius: '4px',
              marginTop: '10px',
              transform: 'none !important',
              padding: '10px 12px',
              backgroundColor: 'background.default',
            }}
          >
            {/* <Typography
              variant="h4"
              sx={{
                ml: '20px',
                mb: '20px',
                borderBottom: '1px solid #ded9d9',
                width: '100%',
                paddingBottom: '5px',
                paddingTop: '6px',
                display: 'flex',
                justifyContent: 'space-between',
              }}
              color="secondary.main"
              fontSize={'16px'}
              fontWeight={'600'}
            >
              Account Manager
            </Typography> */}
            <AdminTable
              results={adminList as IUserState[]}
              handlePageChange={handlePageChange}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
              paginationInfo={paginationInfo}
            />
          </Box>
        </ScaleContainer>
      </Container>
    </Fragment>
  )
}

export default Index
