import { useState, useEffect } from 'react'
import { Box, Container, Typography } from '@mui/material'
import { useSelector, useDispatch } from 'react-redux'
import Result from './Results'
import RTPToolBox from './RTPToolBox'
import { RootState } from 'stores'
import PageTitle from 'app/dashboard/components/PageTitle'
import ExportBtn from 'app/dashboard/components/ExportBtn'
import { getRTP } from 'stores/rtp/rtp-actions'

const Index: React.FC = () => {
  const { rtp } = useSelector((state: RootState) => state.rtp)
  const dispatch = useDispatch()
  const [paginationInfo, setPaginationInfo] = useState({
    currentPage: 1,
    lastPage: false,
    pageSize: 10,
    totalPage: 0,
    totalRecord: 0,
  })

  const handlePageChange = (_event: any, newPage: number) => {
    setPaginationInfo((value) => ({
      ...value,
      currentPage: newPage,
    }))
  }

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPaginationInfo((value) => ({
      ...value,
      pageSize: +event.target.value,
    }))
  }

  useEffect(() => {
    const fetch = async () => {
      const data = (await dispatch(
        getRTP('', paginationInfo?.currentPage, paginationInfo?.pageSize)
      )) as any
      data && setPaginationInfo({ ...data, currentPage: paginationInfo?.currentPage })
    }

    fetch()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paginationInfo?.currentPage, paginationInfo?.pageSize])

  return (
    <>
      <PageTitle pageTitle="Manage RTP | Galaxy GamesGroup" />
      <Container maxWidth={false} sx={{ padding: '0px !important' }}>
        <Box
          sx={{
            backgroundColor: 'background.default',
          }}
          id="manage-category-page-content"
        >
          <Box sx={{ border: '2px solid #ccc', borderRadius: '4px', padding: '10px' }}>
            <RTPToolBox paginationInfo={paginationInfo} setPaginationInfo={setPaginationInfo} />
          </Box>
          <Box
            sx={{
              border: '2px solid #ccc',
              marginTop: '10px',
              borderRadius: '4px',
              padding: '10px',
            }}
          >
            <Typography
              sx={{
                ml: '20px',
                mb: '6px',
                borderBottom: '1px solid #ded9d9',
                paddingBottom: '5px',
                paddingTop: '6px',
                justifyContent: 'space-between',
                display: 'flex',
                paddingRight: '10px',
              }}
              variant="h4"
              color="secondary.main"
              fontSize={'16px'}
              fontWeight={'600'}
            >
              RTP List
              <ExportBtn icon data={rtp} title="Manage RTP" />
            </Typography>

            <Result
              rtps={rtp}
              paginationInfo={paginationInfo}
              handlePageChange={handlePageChange}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
            />

            <br />
          </Box>
        </Box>
      </Container>
    </>
  )
}

export default Index
